

<div class="uk-flex uk-flex-between">
  <div data-intro='Здесь вы можете фильтровать таблицу по группам' data-step='2'>
    <Groups
        propTable="mainTable"
        withAll={groupsFilter.withAll}
        on:filter="filter(event)"/>
  </div>
  {#if showRefreshBtn}
  <div class="uk-flex uk-flex-right uk-flex-bottom">
    <!--      <Button class="g__btn&#45;&#45;simple" on:click="resetFilter()">Сбросить фильтр</Button>-->
    <button on:click="resetFilter()" class="uk-button uk-button-text button__link btn__reset">
      <span uk-icon="refresh" class="uk-position-left uk-icon icon-refresh"></span>
      Сбросить
    </button>
  </div>
  {/if}

  <!-- {#if withLimits && limits} Вопрос к андрею
  {#await limits}
  <p>loading...</p>
  {:then answer}
  {#if answer.limits}
  {#each limits as point}
  {#if point.system_tables.key === tableKey}
  <div class="uk-text-right" data-intro='Здесь вы можете видеть ограничение по тарифу в системе' data-step='3'>
    {point.system_tables.name}: {items.length} из {answer.limits[0].value}
    <br><a href="#" on:click|preventDefault="options.root.navigate('my-tariff')" class="uk-text-meta">Мой тариф</a>
  </div>
  {/if}
  {/each}
  {/if}
  {/await}
  {/if} -->

  {#if withLimits && limits}
    {#each limits as point}
      {#if point.system_tables.key === tableKey}
        <div class="uk-text-right" data-intro='Здесь вы можете видеть ограничение по тарифу в системе' data-step='4'>
          {point.system_tables.name}: {items.length} из {point.value}
          <!-- <br><a href="#" on:click|preventDefault="options.root.navigate('my-tariff')" class="uk-text-meta">Мой тариф</a> -->
        </div>
      {/if}
    {/each}
  {/if}
</div>

  {#if checked.length}
  <div ref:actions>
    <a on:click="actionType(`send`)" class="uk-link-text"><span><input class="uk-checkbox" type="checkbox"></span>Отправить письмо</a>
    <a on:click="actionType(`unchain`)" class="uk-link-text"><span><input class="uk-checkbox" type="checkbox"></span>Отвязать авто</a>
    <a on:click="actionType(`delete`)" class="uk-link-text"><span><input class="uk-checkbox" type="checkbox"></span>Удалить пользователей</a>
    <a on:click="actionType(`disable`)" class="uk-link-text"><span><input class="uk-checkbox" type="checkbox"></span>Отключить</a>
  </div>
  {/if}



<div class="uk-overflow-auto uk-margin-small-top">
  <table class="g__table uk-table uk-table-hover uk-table-middle uk-table-divider uk-position-relative">
    <thead>
    <tr>

<!--      {#if checkbox}-->
<!--      <th class="td-checkbox">-->
<!--        <Input on:click="checkedAll()"-->
<!--               class="uk-checkbox"-->
<!--               type="checkbox"-->
<!--               checked="{checked.length === data.length ? 'checked' : ''}">-->
<!--      </th>-->
<!--      {/if}-->

      {#each columns as column, i}
        <th on:click="someEvent(this, event, column)" class="uk-table-small filterAnchor">

<!--          {#if search.prop === column.prop}-->
<!--            <Input autofocus on:Input|stopPropagation="search(event,column.prop)" class=" uk-Input input__search" type="text" placeholder="Input">-->
<!--          {:else}-->
<!--            <a class="uk-text-meta" >{column.header}</a>-->
<!--          {/if}-->
          {#if columnSearch[column.prop] !== null && typeof(columnSearch[column.prop]) !== 'undefined' }
            <input autofocus onclick="event.stopPropagation()" bind:value="columnSearch[column.prop]" on:focusout="refreshSearch(column.prop)" class="uk-input input__search" type="text" placeholder="Что ищем?">
          {:else}
            <a class="uk-text-meta" >{column.header}</a>
          {/if}
<!--          <span on:click|stopPropagation="searchStart(column)" class="icon-search" uk-icon="icon: search"></span>-->
          <span on:click="testSearch(column.prop, event)" class="icon-search" uk-icon="icon: search"></span>
          {#if column.searchField}
            <span class="uk-text-small">{column.searchField}</span>
          {/if}

          {#if sort.prop == column.prop}
            {#if sort.type == 'asc'}
              <span class="chevron icon-chevron" uk-icon="icon: chevron-down"></span>
            {:elseif sort.type == 'desc'}
              <span class="chevron icon-chevron" uk-icon="icon: chevron-up"></span>
            {/if}
          {/if}

<!--          {#if columnSearch[column.prop] && columnSearch[column.prop] !== ""}-->
<!--            Ищем: {columnSearch[column.prop]}...-->
<!--          {/if}-->

        </th>
      {/each}
      {#if actions}
        <th><div class="uk-width uk-heihgt-1-1 border-bottom">Действия</div></th>
      {/if}
    </tr>
    </thead>

    <tbody>
    {#each filteredData as item, y}
    <tr class="uk-position-relative" on:click="fire('rowClick', item)">

<!--      {#if checkbox}-->
<!--      <td class="td-checkbox">-->
<!--        <Input on:click="employeeAction(item.id)"-->
<!--               class="uk-checkbox"-->
<!--      type="checkbox"-->
<!--      checked="{checked.indexOf(item.id) != -1 ? 'checked' : ''}">-->
<!--      </td>-->
<!--      {/if}-->

      {#each columns as column, x}
      <td class:block-at="item.block_at">
        {#if item[column.prop]}
          {#if column.img}
            <img src="img/avatar.png" alt="avatar">
          {/if}
          {#if column.link}

          {#if column.prop === "phone"}
            {templatePhone(column, item)}
          {:else}
            <a on:click="fire('selectItem', { prop: column.prop, obj: item })">{item[column.prop]}</a>
          {/if}
            <!-- {#if column.prop === "phone"}
              <a on:click="fire('selectItem', { prop: column.prop, obj: item })">{templatePhone(column, item)}</a>
            {:else}
              <a on:click="fire('selectItem', { prop: column.prop, obj: item })">{item[column.prop]}</a>
            {/if} -->
<!--            <a on:click="fire('selectItem', { prop: column.prop, obj: item })">{item[column.prop]}</a>-->
          {:elseif column.subprop}
            {item[column.prop][column.subprop]}
          {:else}
            {item[column.prop]}
          {/if}
        {:else}
          Нет данных
        {/if}
      </td>
      {/each}
      {#if actions}
      <td class="column__fixed">
        {#if itemLoadings.includes(item.id)}
          <div uk-spinner></div>
        {:else}
          {#if actions.includes('delete')}
            <a on:click="fire('deleteItem', item)" uk-icon="icon: trash"></a>
          {/if}

          {#if actions.includes('ban')}
            {#if item.block_at}
              <a class="uk-margin-small-right" on:click="fire('restore', item)" uk-icon="icon: refresh"></a>
            {:else}
              <a class="uk-margin-small-right" on:click="fire('ban', item)" uk-icon="icon: ban"></a>
            {/if}
          {/if}

          {#if actions.includes('on_duty_toggle')}
            <label><input class="uk-checkbox" type="checkbox" on:click="onDutyToggle(item)" checked="{item.on_duty ? 'checked' : ''}"> На смене</label>
          {/if}
        {/if}
      </td>
      {/if}
    </tr>
    {/each}
    </tbody>
  </table>
</div>

<div ref:point></div>

<script>
  import Phalcon from 'modules/phalcon.js';
  import Validator from 'modules/validator.js';
  import Groups from 'components/groups/GroupsPills.html';

  export default {
    helpers:{
      templatePhone(data, phone){
        if (data.prop === "phone"){
          return Validator.modifier.phoneToMask(phone[data.prop]);
        }
      }
    },
    components: { Groups },

    data(){
      return {
        withLimits: false,
        tableKey: '',
        limits: null, //Phalcon.system.tariff() Вопрос к Андрею
        search:"",
        checkbox: true,
        filterIsActive: false,
        checked: [],
        data:[],
        columns: [],
        itemLoadings: [],
        groupsFilter: {
          withAll: true,
          items: [{
            name: 'Курьеры',
            key: 'delivery'
          }, {
            name: 'Логисты',
            key: 'logistik'
          }]
        },
        columnSearch: {},
        sort: {
          prop: 'id',
          type: 'asc'
        }
      }
    },
    onstate({changed, current}){
        if(changed.methods){
        }

        if(changed.columnSearch){
        }

        if(changed.sort){
          this.sort();
        }
    },
    onupdate({changed, current}){
      if(changed.items){
        this.set({ data: current.items, defaultData: current.items });
      }
    },
    oncreate() {
      const selfState = this.get();
      let { withLimits } = this.get();
      const { items, methods, columns } = this.get();
      this.set({defaultData: items});
      this.sort();

      if (withLimits){
        Phalcon.system.tariff()
          .then( answer => {
            if (answer.status === 200) {
              delete answer.status;
              this.set({limits: answer.limits}); //Вопрос к Андрею
            } else {
              throw new Error('something went wrong')
            }
          })
          .catch( err => console.error);
      }
    },
    methods: {
      showTooltip(item, el){
        if(item.block_at) UIkit.tooltip(el, {
          title: 'Можно удалить по клику',
          pos: 'bottom-center'
        }).show();
      },
      onDutyToggle(item){
        console.log({item})
        let { items, itemLoadings } = this.get();

        if (!itemLoadings.includes(item.id)) itemLoadings.push(item.id);
        this.set({ itemLoadings });
        
        Phalcon.api.users.dutyToggle(item.id, {
          value: !item.on_duty
        }).then(answer => {
          if (answer.status == 200) {
            this.set({ 
              items: this.get().items.map(u => {
                if (u.id == item.id) {
                  u.on_duty = !item.on_duty
                }

                return u;
              }), 
              itemLoadings: this.get().itemLoadings.filter(id => id != item.id) 
            });
          }
        })
      },
      searchStart(item){
        let search = {};
        search.prop = item.prop;
        this.set({search});
      },
      refreshSearch(prop){
        let { columnSearch } = this.get();
        if(columnSearch[prop] == ""){
          columnSearch[prop] = null;
          this.set({ columnSearch });
        }
      },
      testSearch(prop, event){
        let { columnSearch } = this.get();
        if(!columnSearch[prop]) {
          columnSearch[prop] = "";
          this.set({columnSearch});
        }
      },
      resetFilter(){
        let { columnSearch } = this.get();
        columnSearch = {};
        this.set({columnSearch, search: ""});
      },
      setActive(item){
        this.set({current: item})
      },
      filter(currentCategory){
        this.set({ currentCategory });
      },
      search(event, prop){
        let { columnSearch, data, defaultData } = this.get();
        let searchEmpty = true;
        Object.keys(columnSearch).map(key => {
          if(columnSearch[key] != null) searchEmpty = false;
        });

        if (!searchEmpty){
          data = defaultData.filter(item => {
            for(var key in item){
              if(columnSearch[key]){
                if(item[key] === null || typeof item[key] === 'undefined' || item[key].length === 0) return false;
                if(item[key].toString().toLowerCase().search(columnSearch[key].toLowerCase()) === -1) return false;
              }
            }
            return true;
          });
          this.set({ data });
        }else{
          this.set({ data:defaultData });
        }
        this.set({filterIsActive: true});

      },
      sort(){
        let { data, sort } = this.get();

        if(data) {
          data.sort((a,b) => {
            if (a[sort.prop] > b[sort.prop]) {
              if(sort.type === 'asc')
                return 1;
              if(sort.type === 'desc')
                return -1;
            }
            if (a[sort.prop] < b[sort.prop]) {
              if(sort.type === 'asc')
                return -1;
              if(sort.type === 'desc')
                return 1;
            }
            return 0;
          });

          this.set({ data });
        }
      },
      checkedAll(){
        let { data, checked } = this.get();

        if(checked.length === data.length) {
          this.set({ checked: [] });
          return;
        }
        data.map(function (item) {
          if(checked.indexOf(item.id) === -1) checked.push(item.id);
        });
        this.set({ checked });
      },
      employeeAction(id){
        let {checked} = this.get();
        let index = checked.indexOf(id);
        if (index === -1){
          checked.push(id);
        }else {
          checked.splice(index, 1);
        }
        this.set({checked});
      },
      someEvent(el, event, type){
        const self = this;
        const selfstate = this.get();
        let {filterTable, defaultData, sort} = this.get();

        if (sort.prop !== type.prop){
          sort.prop = type.prop;
          sort.type = "asc";
          this.set({sort});
        } else {
          sort.type = sort.type === "asc" ? "desc" : "asc";
          this.set({sort});
        }

        const {columnSearch} = this.get();
        let searching;

        if (columnSearch !== undefined || columnSearch !== null) {
            searching = columnSearch[type.prop];
        }
        this.fire("tableRefresh");
      },
      actionType(type){
      }
    },
    computed: {
      showRefreshBtn ({ columnSearch }){
        let searchEmpty = true;
        Object.keys(columnSearch).map(key => {
          if(columnSearch[key] != null) searchEmpty = false;
        });
        return !searchEmpty;
      },
      filteredData ({ data, defaultData, columnSearch, currentCategory }){
        if(defaultData) data = defaultData.filter(item => {
          if(!currentCategory || currentCategory === "" || currentCategory.group === "" || typeof currentCategory === 'undefined') return  true;
          if(!item.groups) return false;
          if(item.groups.key === currentCategory.group.key) return true;
          return false;
        });

        let searchEmpty = true;
        Object.keys(columnSearch).map(key => {
          if(columnSearch[key] != null) searchEmpty = false;
        });

        if (!searchEmpty){
          data = data.filter(item => {
            for(var key in item){
              if(columnSearch[key]){
                if(item[key] === null || typeof item[key] === 'undefined' || item[key].length === 0) return false;
                if(item[key].toString().toLowerCase().search(columnSearch[key].toLowerCase()) === -1) return false;
              }
            }
            return true;
          });
        }
        return data;
      }
    }
  }
</script>
<style>.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

ref:actions {
  width: 100%;
  background: white; }

.input__search {
  width: 100px;
  height: 20px;
  font-size: 12px; }

.icon-chevron {
  color: #c4c4c4;
  position: absolute;
  right: 25px; }

.icon-refresh {
  position: absolute;
  left: -20px; }

.icon-search {
  position: absolute;
  right: 5px;
  text-transform: unset;
  color: cadetblue; }
  .icon-search:hover {
    cursor: pointer; }

table {
  box-sizing: border-box;
  background: #ffffff; }

.button__link {
  text-transform: unset;
  color: cadetblue; }

.uk-button-text::before {
  content: "";
  border-bottom: 1px solid cadetblue;
  transition: right .3s ease-out; }

td > span {
  border: 1px solid #E2E2E2;
  border-radius: 4px; }

td, th {
  border-right: 1px solid #E2E2E2;
  border-left: 1px solid #E2E2E2; }

th {
  min-width: 155px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  box-sizing: border-box;
  color: #7f7f7f; }
  th:nth-child(1) {
    min-width: 70px; }

th > a {
  color: #515151;
  font-weight: 600; }
  th > a:hover {
    color: #515151; }

th > a:nth-child(1) {
  text-decoration: none; }

td > img {
  width: 28px;
  height: 28px;
  border-radius: 50%; }

.filterAnchor {
  position: relative;
  text-transform: unset; }

.td-checkbox {
  padding: 15px;
  width: 15px; }

.g__btn--simple {
  text-transform: unset;
  border-radius: 0;
  margin-top: 20px;
  width: 200px;
  padding: 5px; }
  .g__btn--simple:hover {
    background: #faa05a;
    color: #ffffff; }

.uk-table th {
  text-transform: unset;
  color: #515151;
  padding: 9px; }

.uk-table td {
  padding: 7px; }

.btn__reset {
  position: relative;
  bottom: 0; }

td.block-at,
td.block-at:hover {
  position: relative;
  opacity: 0.4;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  pointer-events: none; }
</style>