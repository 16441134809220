<div class="uk-position-small uk-position-top-right" ref:tools>
    <div class="tools-group">
        <div ref:settings>
            <div class="uk-inline">
                <a class="uk-icon-button" type="button">
                    <img src="/svg/settings.svg" width="20" height="20" uk-svg>
                </a>
                <div class="uk-padding-small" uk-dropdown="mode: click">
                    <p class="uk-text-small">Информация на маркере</p>
                    <div class="uk-flex uk-margin-small-top">
                        <input bind:checked=markerInfoKeys.time type="checkbox"
                            class="uk-checkbox uk-margin-small-right" style="margin-top: 3px">
                        <span>Время</span>
                    </div>
                    <!-- <div class="uk-flex uk-margin-small-top">
                        <input bind:checked=markerInfoKeys.legals type="checkbox"
                            class="uk-checkbox uk-margin-small-right" style="margin-top: 3px">
                        <span>Контрагент</span>
                    </div> -->
                    <div class="uk-flex uk-margin-small-top">
                        <input bind:checked=markerInfoKeys.weight type="checkbox"
                            class="uk-checkbox uk-margin-small-right" style="margin-top: 3px">
                        <span>Вес</span>
                    </div>
                    <!-- <div class="uk-flex uk-margin-small-top">
                        <input bind:checked=markerInfoKeys.WHD type="checkbox" class="uk-checkbox uk-margin-small-right"
                            style="margin-top: 3px">
                        <span>Габариты</span>
                    </div> -->
                </div>
            </div>

        </div>
    </div>
</div>

<style>
    .uk-icon-button {
        background: white;
    }

    .uk-icon-button:hover {
        background: rgb(250, 250, 250);
    }
</style>

<script>
    export default {
        data() {
            return {
                markerInfoKeys: localStorage.getItem('markerInfoKeys') ? JSON.parse(localStorage.getItem('markerInfoKeys')) || {} : {}
            }
        },
        onstate({ changed, current }) {
            if (changed.markerInfoKeys) {
                window.markerInfoKeys = current.markerInfoKeys;
                if (window.map && window.map.helper) window.map.helper.refreshAllIcons();
                localStorage.setItem('markerInfoKeys', JSON.stringify(current.markerInfoKeys));
            }
        },
    }
</script>