<div class="column">
    <div class="content">
        <div class="header">
            <div class="uk-margin-auto uk-padding-small">
                <h4 class="uk-margin-small-bottom">{data.title}</h4>
                {#if data.stats}
                <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove">
                    {#each data.stats as item}
                    <li>
                        <a>{item.value} {item.label}</a>
                    </li>
                    {/each}
                </ul>
                {/if}
            </div>
        </div>
        <div class="list" uk-sortable="group: sortable-group">
            {#if data.applics}
                {#each data.applics as data}
                    <ApplicsCard data="{data}" store="{store}" />
                {/each}
            {/if}
        </div>
    </div>
</div>

<style>
    .column {
        position: relative;
        height: 100%;
        width: 300px;
        display: inline-block;
        border-right: 1px solid rgba(0, 0, 0, 0.1); 
    }

    .column .content {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.03);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .column .content .header {
        position: relative;
        background-color: white;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .column .content .list {
        padding: 15px;
        height: calc(100% - 90px);
        overflow-y: auto;
    }
</style>

<script>
    import ApplicsCard from 'components/applics/ApplicsCard.html';

    export default {
        components: { ApplicsCard },

        data() {
            return {
                data: {
                    title: 'Название статуса',
                    stats: [],
                    applics: []
                }
            }
        },
    }
</script>