<div class="uk-flex " id="personalInfo-component">
  <div class="uk-width-1-3">
      <div ref:avatar on:click="inputEvent()" class="uk-margin-auto-vertical" style="background-color:#cecece;">
        {#if !isAvatar}
          <div class="uk-flex uk-flex-column ">
            <div class="uk-width-1-1 uk-text-center">
              <span uk-icon="camera"></span>
            </div>
            <div class="uk-width-1-1 uk-text-center">Добавить фото</div>
          </div>
        {/if}
      </div>
    <input type="file" id="inputAvatarUser" accept=".png, .jpg, .jpeg" style="display: none">
  </div>

  <div class="uk-width-2-3 uk-margin-remove uk-padding-remove" uk-grid>
    <!-- <div class="uk-width-auto">
      <p class="uk-margin-remove uk-text-middle">
        <input class="uk-checkbox" type="checkbox" bind:checked="user.is_active"> Активен</p>
  </div> -->
<!--    <div class="uk-width-expand">-->
<!--      <select class="uk-Input">-->
<!--        <option  class="select" >Выберите группу</option>-->
<!--        <option class="select" value="1">1</option>-->
<!--        <option  class="select" value="2">2</option>-->
<!--      </select>-->
<!--    </div>-->

    <div class="uk-width uk-margin-small-top uk-flex uk-flex-between">
      <div class="uk-width-1-2 uk-margin-small-right">
        <input ref:text class="uk-input" type="text" bind:value="user.first_name" placeholder="Имя" required>
      </div>
      <div class="uk-width-1-2 ">
        <input ref:text class="uk-input" type="text" bind:value="user.last_name" placeholder="Фамилия" >
      </div>
    </div>

    <div class="uk-width uk-margin-small-top">
      <input ref:mail class="uk-input" type="email" bind:value="user.email" placeholder="Email" required>
    </div>
    <div class="uk-width uk-margin-small-top uk-margin-small-bottom">
<!--      <Input ref:phone class="uk-Input" type="text" bind:value="user.phone" placeholder="Телефон" required>-->
<!--      {#if user && user.phone.length !== 0}-->
      <input ref:phone class="uk-input g__input--simple" on:change="fixMask(event)" value="{phone}" type="text" placeholder="Телефон" >
<!--      {:else}-->
<!--      <Input ref:phone class="uk-Input g__input&#45;&#45;simple" type="text" placeholder="Телефон">-->
<!--      {/if}-->
    </div>
  </div>
</div>

<script>
  import Validator from 'modules/validator.js';
  import mask from 'modules/mask.js';

  export default {
    oncreate(){
      const self = this;
      const {user} = this.get();
      const selfState = this.get();
      setTimeout(()=> {
        let phone = user.phone;
        this.set({phone});
        mask.phone(self.refs.phone);
      }, 100)
    },
    methods:{
      inputEvent(){
        let self = this;
        let fileInput = $('#inputAvatarUser');
        fileInput.trigger('click');
        fileInput.on('change', event => {
          this.set({isAvatar: true});
          if(document.getElementById('inputAvatarUser').files && document.getElementById('inputAvatarUser').files[0]){
            let fileReaderC = new FileReader();
            fileReaderC.onload = function () {
              $(self.refs.avatar).css('background-image', 'url(' + fileReaderC.result + ')');
            };
            fileReaderC.readAsDataURL(document.getElementById('inputAvatarUser').files[0]);
          }
        });
      },
      fixMask(event){
        let self = this;
        const {user} = self.get();
        let {phone} = self.get();
        phone = event.target.value;
        user.phone = mask.cleanVal(self.refs.phone);
        self.set({user, phone});
      }
    },
    data(){
      return {
        isAvatar: false,
        input: null,
      }
    },
  }
</script>

<style>/*span[uk-icon]{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  color: #FFFFFF;*/
/*  font-size: 84px;*/
/*}*/
.uk-input {
  height: 31px;
  line-height: 30px;
  border-radius: 4px; }

ref:avatar {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-position: 50%, 50%;
  background-size: cover;
  width: 155px;
  height: 155px; }
  ref:avatar:hover {
    opacity: 0.8; }

.uk-accordion-content {
  color: #4f4f4f; }

.uk-checkbox {
  border: 1px solid #000000; }
  .uk-checkbox:checked {
    background-color: #E30613; }

.uk-select,
input {
  border-radius: 4px; }
</style>