<p class="uk-text">Личная информация</p>

<div uk-grid>
  <div class="uk-width-2-5">
    <div ref:avatar on:click="inputEvent()" class="uk-border-rounded uk-position-relative uk-overflow-hidden" style="width:150px; height:150px;">
      <img src="/img/avatar.png" uk-cover />
    </div>
    <input type="file" id="inputAvatarInfo" accept=".png, .jpg, .jpeg" style="display: none">
    {#if session && session.is_admin}
    <div class="uk-flex uk-flex-wrap uk-margin-top">
      <!-- <a class="uk-width-1-1" on:click="options.root.navigate('/my-tariff')" >Мой тариф</a> -->
      <a class="uk-width-1-1" on:click="options.root.navigate('/my-balance')">Мой баланс: {balance}</a>
    </div>
    {/if}
  </div>

  <div class="uk-width-3-5 info">
    <div class="uk-margin info__item uk-text-small">
      <label class="uk-form-label " >Имя</label>
      <div class="uk-form-controls ">
        <input class="uk-input g__input--simple" type="text" bind:value="session.first_name" placeholder="Имя">
      </div>
    </div>

    <div class="uk-margin info__item uk-text-small">
      <label class="uk-form-label " >Фамилия</label>
      <div class="uk-form-controls ">
        <input class="uk-input g__input--simple" type="text" bind:value="session.last_name" placeholder="Фамилия">
      </div>
    </div>

    <div class="uk-margin info__item uk-text-small">
      <label class="uk-form-label" >Email</label>
      <div class="uk-form-controls ">
        <input class="uk-input g__input--simple g__input--simple" type="text" bind:value="session.email" placeholder="Email">
      </div>
    </div>

    <div class="uk-margin info__item uk-text-small">
      <label class="uk-form-label" >Телефон</label>
      <div class="uk-form-controls ">

        {#if session.phone && session.phone.length !== 0}
          <input ref:mask class="uk-input g__input--simple" on:change="fixMask(event)" value="{localPhone}" type="text" placeholder="Телефон">
        {:else}
          <input ref:mask class="uk-input g__input--simple" type="text" placeholder="Телефон">
        {/if}
      </div>
    </div>

    {#if isPswChange}
    <div class="uk-margin info__item uk-text-small">
      <label class="uk-form-label" >Старый Пароль</label>
      <div class="uk-form-controls ">
        <input bind:value="formData.oldPassword" class="uk-input g__input--simple" type="password" placeholder="Старый Пароль">
      </div>
    </div>

      <div class="uk-margin info__item uk-text-small">
        <label class="uk-form-label" >Пароль</label>
        <div class="uk-form-controls ">
          <input bind:value="formData.password" class="uk-input g__input--simple" type="password" placeholder="Пароль">
        </div>
      </div>

      <div class="uk-margin info__item uk-text-small">
        <label class="uk-form-label" >Повторите пароль</label>
        <div class="uk-form-controls ">
          {#if formData.password.length > 0}
            <input bind:value="formData.password2" class="uk-input g__input--simple" type="password" placeholder="Повторите пароль" >
          {:else}
            <input class="uk-input g__input--simple" type="password" placeholder="Повторите пароль" disabled>
          {/if}
        </div>
      </div>
    {/if}
  </div>

  <div class="uk-width-1-1 uk-margin info__item uk-text-small uk-flex uk-flex-between">
    {#if !isPswChange}
    <div class="uk-flex uk-flex-right uk-margin-right">
      <button on:click="pswChange()" class=" g__btn--simple">Сменить пароль</button>
    </div>
    {/if}
    <div class="uk-flex uk-flex-right">
      <button on:click="confirmChange()" class=" g__btn--yes">Сохранить изменения</button>
    </div>
  </div>
</div>

<script>
  import Phalcon from 'modules/phalcon.js';
  import Validator from 'modules/validator.js';
  import mask from 'modules/mask.js';

  export default {
    onupdate({ changed, current, previous }){
      let {formData, checked, isDisabled} = this.get();
      if(changed.formData === true) {
        if (formData.password.length === 0) {
          formData.password2 = "";
          checked = false;
          isDisabled = true;
        }
        this.set({formData, checked, isDisabled});
      }
    },
    onstate({changed, current, previous}){
      const { dataChange } = this.get();

      if (!changed.dataChange && changed){
        this.set({dataChange: true});
      }
    },
    oncreate(){
      const self = this;
      let {session, localPhone} = this.get();
      const selfState = this.get();

      localPhone = session.phone;
      this.set({localPhone});

      this.set({session});
      setTimeout(()=> {mask.phone(this.refs.mask);},100);
      if(session.is_admin) Phalcon.system.balance()
        .then( answer => {
          this.set({balance: answer});
        })
        .catch( err => {});
    },
    methods:{
      fixMask(event){
        let self = this;
        let temp = mask.cleanVal(this.refs.mask);
        const { session } = this.get();
        session.phone = temp;
        self.set({session});
      },
      inputEvent(){
        let self = this;
        let fileInput = $('#inputAvatarInfo');
        fileInput.trigger('click');
        fileInput.on('change', event => {
          if(document.getElementById('inputAvatarInfo').files && document.getElementById('inputAvatarInfo').files[0]){
            let fileReader = new FileReader();

            fileReader.onload = function () {
              $(self.refs.avatar).find('img').attr('src', fileReader.result);
            };
            fileReader.readAsDataURL(document.getElementById('inputAvatarInfo').files[0]);
          }
        });
      },
      confirmChange(){
        let isCorrect = true;
        const {session, formData, isPswChange, dataChange} = this.get();
        session.email = session.email.toLowerCase();
        const selfState = this.get();
        if (dataChange === true ) {
          let phone = session.phone ? Validator.isValid.phone(session.phone) : session.email ? 0 : false;
          let mail = session.email ? Validator.isValid.mail(session.email) : session.phone ? 0 : false;
          if (!session.first_name || session.first_name == ''){
            isCorrect = false;
            UIkit.notification(`Поле 'Имя' обязательно для заполнения`, {status: "danger", pos: "bottom-right"});
          }

          if (mail !== 0){
            isCorrect = false;
            UIkit.notification(`Невалидная почта`, {status: "danger", pos: "bottom-right"});
          }
          if (phone !== 0) {
            isCorrect = false;
            UIkit.notification(`Невалидный телефон`, {status: "danger", pos: "bottom-right"});
          }

          if (isPswChange && formData.password.length > 0){
            if (formData.password && formData.password2 && formData.password === formData.password2){
              session.password = formData.password2;
              this.set({session});
              UIkit.notification(`Пароль изменен`, {status: "primary", pos: "bottom-right"});
            } else {
              isCorrect = false;
              UIkit.notification(`Пароли не совпадают`, {status: "danger", pos: "bottom-right"});
            }
          }

          if(isCorrect) {
            Phalcon.users.edit(session).then( answer => {
              this.set({isPswChange: false});
              UIkit.notification(`Личная информация изменена`, {status: "primary", pos: "bottom-right"});
              if(!window.session){
                Phalcon.session.get().then(session => {
                  if (session.id) this.options.root.set({ session });
                })
              } else {
                this.options.root.set({ session: window.session });
              }
            });
          }
          this.set({dataChange: false});
        } else {
          this.set({dataChange: false});
          return;
        }
      },
      pswChange(){
        let {password, isPswChange} = this.get();
        this.set({isPswChange: true});
      },
      parseName(el){
        const { session } = this.get();
        let user = {};
        user.first_name = el.value;
        let userName = Validator.modifier.divideName(user);
        session.first_name = userName.first_name;
        session.last_name = userName.last_name;
        this.set({session});
      }
    },
    data(){
      return {
        input: null,
        dataChange: false,
        isPswChange: false,
        formData: {
          password: "",
          password2: "",
          oldPassword: "",
        },
        localPhone: ""
      }
    }
  }
</script>

<style>.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

/*Button{*/
/*  border-radius: 5px;*/
/*  width: 99%;*/
/*  text-transform: unset;*/
/*}*/
.g__btn--yes {
  text-transform: unset;
  border-radius: 0;
  background: #FFFFFF;
  color: #e30613;
  border: 1px solid #e30613;
  font-size: 12px;
  width: content-box;
  height: 30px;
  padding: 6px;
  text-transform: unset; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }

.g__btn--simple {
  text-transform: unset;
  border-radius: 0;
  font-size: 12px;
  width: content-box;
  height: 30px;
  padding: 6px;
  text-transform: unset; }
  .g__btn--simple:hover {
    background: #faa05a;
    color: #ffffff; }

.item__confirm {
  color: #000;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  /*Button{*/
  /*  border-radius: 5px;*/
  /*  width: 99%;*/
  /*  text-transform: unset;*/
  /*}*/ }

.info {
  padding-left: 10px; }
  .info__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .info__item label {
      vertical-align: baseline;
      width: 40%; }

.circle-image {
  width: 123px;
  height: 123px;
  transition: 0.3s; }
  .circle-image:hover {
    opacity: 0.8; }

input {
  border-radius: 5px;
  height: 30px; }

a {
  color: grey; }
</style>


<!--
      {#if isPswChange}
      <div class="uk-margin info__item uk-text-small">
        <label class="uk-form-label" >Пароль</label>
        <div class="uk-form-controls ">
          <Input bind:value="password" class="uk-Input g__input--simple" type="text" placeholder="Пароль">
        </div>
      </div>

      <div class="uk-margin info__item uk-text-small">
        <label class="uk-form-label" >Повторите пароль</label>
        <div class="uk-form-controls ">

          {#if password.length > 0}
            <Input bind:value="password2" class="uk-Input g__input--simple" type="password" placeholder="Повторите пароль" >
          {:else}
            <Input bind:value="password2" class="uk-Input g__input--simple" type="password" placeholder="Повторите пароль" disabled>
          {/if}
        </div>
      </div>
    {/if}
-->