<nav class="uk-navbar-container uk-navbar uk-sticky uk-sticky-fixed" uk-navbar="">
    <div class="uk-navbar-left">

        <ul class="uk-navbar-nav">
            <li>
                <a class="uk-padding">
                    <ul class="uk-breadcrumb uk-margin-remove">
                        {#each breadcrumb as item, i}
                            {#if i == breadcrumb.length - 1}
                                <li>
                                    <span class="active">{item.name}</span>
                                </li>
                            {:else}
                                <li>
                                    <a on:click="options.root.navigate(item.link)">{item.name}</a>
                                </li>
                            {/if}
                        {/each}
                    </ul>
                </a>
            </li>
            {#if title}
            <div class="uk-navbar-item">
                <div class="uk-text-large">{title}</div>
            </div>
            {/if}
            {#if actions && actions.includes('datepicker')}
                <li class="uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding-small">
                    <Datepicker bind:date_start bind:date_end/>
                </li>
            {/if}

            {#if actions && actions.includes('search')}
                <li class="uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding-small">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: search"></span>
                        <input class="uk-input uk-background-muted" type="text">
                    </div>
                </li>
            {/if}

            {#if actions && actions.includes('add')}
                <li class="uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding-small" id="navbar-add-btn">
                    <div>
                        <button class="uk-button n__btn--primary" on:click="fire('add')">
                            <img src="/svg/icons/plus.svg" uk-svg>
                            {actionAddText || 'Добавить'}
                        </button>
                    </div>
                </li>
            {/if}
            
            {#if actions && actions.includes('help')}
                <li>
                    <a on:click="fire('help')">
                        <span uk-icon="question" uk-tooltip="Помощь по блоку"></span>
                    </a>
                </li>
            {/if}
        </ul>

    </div>

    <div class="uk-navbar-right">
        <ul class="uk-navbar-nav">
            <!-- {#if actions && actions.includes('datepicker')}
                <li class="uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding-small">
                    <Datepicker bind:date_start bind:date_end/>
                </li>
            {/if}

            {#if actions && actions.includes('search')}
                <li class="uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding-small">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: search"></span>
                        <input class="uk-input uk-background-muted" type="text">
                    </div>
                </li>
            {/if}

            {#if actions && actions.includes('add')}
                <li class="uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding-small">
                    <div>
                        <button class="uk-button n__btn--primary" on:click="fire('add')">
                            <img src="/svg/icons/plus.svg" uk-svg>
                            {actionAddText || 'Добавить'}
                        </button>
                    </div>
                </li>
            {/if}

            {#if actions && actions.includes('help')}
                <li>
                    <a on:click="fire('help')">
                        <span uk-icon="question" uk-tooltip="Помощь по блоку"></span>
                    </a>
                </li>
            {/if} -->
        </ul>
    </div>

    <!-- <div class="uk-navbar-right">
    
            <ul class="uk-navbar-nav">
                <li class="uk-active"><a href="#">Active</a></li>
                <li>
                    <a href="#" aria-expanded="false" class="">Parent</a>
                    <div class="uk-navbar-dropdown uk-navbar-dropdown-bottom-right  " style="left: 389.391px; top: 80px;">
                        <ul class="uk-nav uk-navbar-dropdown-nav">
                            <li class="uk-active"><a href="#">Active</a></li>
                            <li><a href="#">Item</a></li>
                            <li><a href="#">Item</a></li>
                        </ul>
                    </div>
                </li>
                <li><a href="#">Item</a></li>
            </ul>
    
        </div> -->

</nav>

<script>
    import Datepicker from 'UI/Datepicker.html';

    export default {
        components: {
            Datepicker
        },
        onstate({ changed, current, previous }) {
            const self = this;
            if (changed.new && current.new) setTimeout(() => { 
                self.set({ new: false }) 
            }, 1000);
        },
        data() {
            return {
                search: '',
                title: '',
                date_start: moment().format('YYYY-MM-DD'),
                date_end: moment().format('YYYY-MM-DD'),
                breadcrumb: [{
                    name: 'Главная',
                    link: '/'
                }],
                period: moment().format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY')
            }
        },
        oncreate() {
            window.nav = this;
        },
        methods: {
            clear() {
                this.set({
                    search: '',
                    title: '',
                    breadcrumb: [{
                        name: 'Главная',
                        link: '/'
                    }],
                    actions: [],
                    actionAddText: null
                })
            }
        }
    }
</script>

<style>.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

.g__btn--yes {
  border-radius: 0;
  width: 150px; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }

nav {
  height: 60px;
  padding-left: 60px;
  background-color: #ffffff !important;
  border-bottom: 1px solid #dfdfdf; }

.uk-padding {
  padding: 0 20px; }

.uk-breadcrumb .active {
  font-weight: bold; }

input {
  border: none;
  border-radius: 2px; }
</style>