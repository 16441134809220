<div id="modalSelect" uk-modal>
  <div class="uk-modal-dialog">

    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">Выберите пользователей</h2>
    </div>

    <div class="uk-modal-body" >
      <select class="js-select-multiple uk-width-1-1" name="someName" multiple="multiple">
        {#await users}
          <div uk-spinner></div>
        {:then answer}
          {#each answer as user}
            <option value="{user.id}"><div class="uk-flex uk-flex-between">{user.first_name} {user.last_name} ({user.groups ? user.groups.name : "без группы"})</div></option>
          {/each}
        {:catch error}
          <p>well that's odd</p>
        {/await}
      </select>
    </div>

    <div class="uk-modal-footer uk-text-right">
      <button on:click="confirmSelect(this)" class=" g__btn--yes uk-button uk-button-primary" type="button">Сохранить</button>
      <button class=" g__btn--no uk-button uk-button-default uk-modal-close" type="button">Отменить</button>
    </div>

  </div>
</div>

<style>.g__btn--yes {
  border-radius: 0; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }

.g__btn--no {
  border-radius: 0; }
  .g__btn--no:hover {
    background: #c4c4c4;
    color: #ffffff; }
</style>

<script>
  import Validator from 'modules/validator.js';

  export default {
    methods:{
      getName({groups_id}){
        const {groups} = this.get();
      },
      confirmSelect(){
        const {group_id} = this.get();
        this.fire('success', {users: $('.js-select-multiple').val(), id_groups: group_id});
      }
    },
    data(){
      return {
        usersList: null
      }
    },
    oncreate(){
      let self = this;
      const selfState = this.get();
      const {users, group_id, groups} = this.get();

      users.then(answer => {
        let outsideGroup = answer.filter( item =>{
            return item.groups.id !== group_id;
          }
        );
        self.set({users: outsideGroup});
      });

      $('.js-select-multiple').select2({
        width: '100%',
        placeholder: {
          id: '-1', // the value of the option
          text: 'Выберите пользователя'
        },
      });

      UIkit.modal('#modalSelect', {
        escClose: true,
        bgClose: false
      });

      UIkit.modal('#modalSelect').show();

      $('#modalSelect').on("hidden", function () {
        self.destroy();
      });
    },
  }
</script>