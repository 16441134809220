<div class="uk-position-relative">
  <BlockUI>
    Скоро, настройка ролей
  </BlockUI>
  <table ref:rights class="uk-table uk-table-small uk-table-divider uk-border">
    <thead>
      <tr>
        <th></th>
        {#each columns as column }
          <th on:click="markField(column.header)">
            <div class="uk-text-capitalize uk-text-emphasis">{column.prop}</div>
          </th>
        {/each}
      </tr>
    </thead>

    {#if rights}
    <tbody>
      {#each rows as row }
        <tr>
          <td on:click="markField(row.key)">
            <div class="uk-text-capitalize uk-text-emphasis uk-text-small">{row.name}</div>
          </td>
          {#each columns as column }
            <td class:active="rights[row.key][column.header] !== defaultRights[row.key][column.header]">
              <input class="toggle" type="checkbox" bind:checked="rights[row.key][column.header]"/>
            </td>
          {/each}
        </tr>
      {/each}
    </tbody>
    {/if} 
  </table>
  {#if withButton}
    <div class="uk-flex uk-flex-right">
      <button class="g__btn--yes uk-button-small" on:click="confirmRights()">Сохранить</button>
    </div>
  {/if}

</div>

<style>tbody > tr > td:first-child,
th {
  user-select: none; }

.g__btn--yes {
  border-radius: 0;
  width: 200px; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }

.g__btn {
  border-radius: 0;
  color: #E30613;
  background: #ffffff;
  width: 200px;
  text-decoration: none;
  border: 1px solid #E30613; }
  .g__btn:hover {
    color: #ffffff;
    background: #e30613; }

/* Table */
td > span {
  border: 1px solid #E2E2E2;
  border-radius: 4px; }

table {
  margin-top: 10px;
  border: 1px solid #E2E2E2;
  border-radius: 4px;
  background-color: white; }

td, th {
  border-right: 1px solid #E2E2E2;
  border-left: 1px solid #E2E2E2;
  text-align: center; }
  td:hover, th:hover {
    cursor: pointer; }

th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: #C4C4C4; }

/* /Table */
/* /Toggle switcher */
.toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 32px;
  height: 14px;
  display: inline-block;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #edc4c4;
  transition: background-color ease 0.3s; }

.toggle:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  width: 15px;
  height: 14px;
  background: #ff0000;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  font: 10px/28px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -22px;
  word-spacing: 37px;
  color: #fff;
  text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s; }

.toggle:checked {
  background-color: #d0ddb7; }

.toggle:checked:before {
  left: 17px;
  background: #629400; }

.active {
  background: #E0FFFF; }

/* /Toggle switcher */
</style>

<script>
  import Phalcon from 'modules/phalcon.js';
  import Validator from 'modules/validator.js';
  import BlockUI from 'UI/BlockUI.html';
  
  export default {
    components: { BlockUI },
    onstate({ changed, current }) {
      if(changed.reShuffle){
        this.update();
        this.set({reShuffle: false});
      }
    },
    oncreate() {
      const self = this;
      const selfState = this.get();
      let { defaultRights, uniqueId} = this.get();
      let rows;
      let groupKey;
      Phalcon.settings.rightsModels()
        .then(answer => {
          rows = answer;
          answer.forEach((item, index) => {
            groupKey = item.key;
            rows[index].key = groupKey.toLowerCase();
            if (!defaultRights) defaultRights = {};
            defaultRights[item.key] = {
              index: false,
              create: false,
              edit: false,
              delete: false
            };
          });
          this.set({ rows, defaultRights });
          this.update();
          return true;
        }).then(
        () => {
          if(uniqueId !== null && uniqueId !== undefined) {
            this.setUniqRights(uniqueId);
          }
        }
      )
    },
    onupdate({ changed, current }) {
      const {rights, defaultRights} = this.get();

      if (changed.rights) {
        let isChanged = false;
        if (JSON.stringify(current.rights) !== JSON.stringify(current.defaultRights)) {
          isChanged = true;
        }
        this.fire('rightsEdit', { rights: current.rights, isChanged, defaultRights });
      }
      if (changed.rightsId) {
        this.update();
      }
    },
    methods: {
      markField(item){
        let {rights,rowFlag, columnFlag} = this.get();
        let container;
        if (rights[item]){
        container = this.__containerRow(rights[item],rowFlag[item]);
          for (let key in rights[item]){
            rights[item][key] = container;
            rowFlag[item] = !container;
          }
          this.set({rights,rowFlag});
        }else{
        container = this.__containerColumn(item,columnFlag[item]);
          for (let key in rights){
            rights[key][item] = container;
            columnFlag[item] = !container;
          }
          this.set({rights,columnFlag});
        }
      },
      __containerRow(rights,defFlag){
        let currentFlag;
        for (let key in rights){
          currentFlag = rights[key];
          if(defFlag !== currentFlag) return defFlag;
        }
        return !defFlag;
      },
      __containerColumn(item,defFlag) {
        const { rights } = this.get();
        let currentFlag;
        for (let key in rights){
          currentFlag = rights[key][item];
          if(defFlag !== currentFlag) return defFlag;
        }
        return !defFlag;
      },
      setUniqRights(id){
        const self = this;
        const {defaultRights} = this.get();
        let uniq;
        if (id !== null){
          Phalcon.rights.get(id)
            .then( answer => {
            if (answer.status === 200) {
             uniq = Validator.modifier.stringToRights(answer, defaultRights);
              setTimeout(function () {
                self.set({rights: uniq});
              },500);
            }
          });
        }
      },
      confirmRights() {
        return
        
        const { defaultRights, groupId,rights, rightsId } = this.get();
        let data = Validator.modifier.rightsToString(rights);
        Phalcon.rights.edit({ id: rightsId, value: data }).then(answer => {
          if (answer.status === 200) {
            this.fire("refresh", { answer, rightsId });
            UIkit.notification(`Права группы изменены`, { status: "success", pos: "bottom-right" });
            let container = JSON.parse(answer.value);
            if (container !== null) {
              this.set({ rights: this.toClientModifier(container), defaultRights: this.toClientModifier(container) });
            }
          } else {
            UIkit.notification(`Что-то пошло не так, повторите попытку позже`, { status: "danger", pos: "bottom-right" });
          }
        });
      },
      toClientModifier(rights) {
        const { defaultRights } = this.get();
        let modifier = {},
            upperName;

        for (var item in defaultRights) {
          upperName = item.charAt(0).toUpperCase() + item.slice(1);
          for (var key in defaultRights[item]) {
            if (!modifier[item]) modifier[item] = {};
            if (rights[upperName]) {
              modifier[item][key] = rights[upperName].indexOf(key) !== -1;
            } else {
              modifier[item][key] = true;
            }
          }
        }
        return modifier;
      },
      allRights() {
        const { defaultRights } = this.get();
        var temp = {};
        Object.keys(defaultRights).map(key => {
          temp[key] = {
            index: true,
            create: true,
            edit: true,
            delete: true
          };
        });

        return temp;
      },
      update() {
        let { rights, rightsId, defaultRights } = this.get();
        if (defaultRights) {
          let container = null;
          let upperName;
          if (!rightsId) {
            this.set({ rights: Object.assign({}, defaultRights) });
          } else {
            Phalcon.rights.get(rightsId)
              .then(answer => {
                if (answer.status === 200) {
                  if (answer.value == "") {
                    this.set({ rights: this.allRights(), defaultRights: this.allRights() });
                  } else {
                    container = JSON.parse(answer.value);
                    if (container !== null) {
                      this.set({ rights: this.toClientModifier(container), defaultRights: this.toClientModifier(container) });
                    }
                  }
                }
              })
              .catch(
                e => {
                }
              )
          }
        }
      },
    },
    data() {
      return {
        columns: [
          {
            header: "index",
            prop: "Видеть"
          },
          {
            header: "create",
            prop: "Создавать"
          },
          {
            header: "edit",
            prop: "Редактировать"
          },
          {
            header: "delete",
            prop: "Удалять"
          },
        ],
        rows: [],
        rightsId: null,
        rights: null,
        triggerChange: {},
        defaultRights: null,
        reShuffle: false,
        columnFlag: {
          index: false,
          create: false,
          edit: false,
          delete: false,
        },
        rowFlag: {
          points: false,
          route: false,
          groups: false,
          users: false,
        },
      }
    }
  }
</script>