<div 
    on:mouseover="searchApplics(data.order_id)" class="uk-card uk-card-default {data.type == 'dl' ? 'delivery-card' : 'takeout-card'}" order-id="{data.order_id}">
    <div class="uk-card-header">
        <div class="uk-grid-small uk-flex-middle" uk-grid>
            <div class="uk-width-auto">
                <span uk-icon="{data.type == 'dl' ? 'sign-in' : 'sign-out'}"></span>
            </div>
            <div class="uk-width-expand">
                <h3 class="uk-card-title uk-margin-remove-bottom">{data.type == 'dl' ? 'Доставка' : 'Забор'} №{data.id}</h3>
                <p class="uk-text-meta uk-margin-remove-top">
                    <time datetime="2016-04-01T19:00">23 марта, 2019</time>
                </p>
            </div>
            <div class="uk-width-auto">
                <span uk-icon="table"></span>
            </div>
        </div>
    </div>
    <div class="uk-card-body">
        <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand" uk-leader>Заказчик</div>
            <div>
                <a class="uk-link-muted" href="#">Спортмастер</a>
            </div>
        </div>
        <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand" uk-leader>Номер заказчика</div>
            <div>1368</div>
        </div>
        <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand" uk-leader>Курьер</div>
            <div>
                <a class="uk-link-muted" href="#">Тимофеев Вячеслав</a>
            </div>
        </div>
    </div>
    <div class="uk-card-footer">
        <div class="uk-align-right">
            <ul class="uk-iconnav">
                <li><a href="#" uk-icon="icon: plus"></a></li>
                <li><a href="#" uk-icon="icon: file-edit"></a></li>
                <li><a href="#" uk-icon="icon: copy"></a></li>
                <li><a href="#" uk-icon="icon: trash"></a></li>
            </ul>
        </div>
    </div>
</div>

<style>
    .uk-card {
        margin-bottom: 15px;
    }

    .uk-card-title {
        font-size: 1rem;
        line-height: 1;
    }

    .uk-card-header {
        padding: 10px;
    }

    .uk-card-body {
        font-size: 13px;
    }

    .uk-card-body,
    .uk-card-footer {
        padding: 10px 20px;
    }

    .uk-iconnav a.active {
        color: #df1c29;
    }

    .delivery-card {
        border-left: 2px solid darkorange;
    }

    .takeout-card {
        border-left: 2px solid darkgreen;
    }

    .backlight {
        background-color: red;
    }
</style>

<script>
    export default {
        data() {
            return {
                data: {
                    id: 'Нет данных',
                    backlight: false
                },
                searchOrderId: null
            }
        },
        methods: {
            searchApplics(orderId) {
                const { store } = this.get();
                store.set({ searchOrderId: orderId });
            }
        }
    }
</script>