  <div>
    <p class="uk-text">Настройка страниц</p>
    <div class="uk-child-width-1-1">
      <div class="uk-flex uk-flex-left">
        <div class="uk-text-small uk-margin-small-right">Смена фона</div>
        <a href="#" class="uk-link-muted uk-text-small">Сбросить</a>
      </div>
      <div class="uk-flex uk-flex-left">
        <div class="uk-text-small uk-margin-small-right">Темная тема</div>
        <div><input class="toggle" type="checkbox"></div>
      </div>
      <div>
        <p class="uk-text-small">Цвета</p>
        <ul class="uk-thumbnav" uk-margin>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
        </ul>
      </div>
      <div>
        <p class="uk-text-small">Фотографии</p>
        <ul class="uk-thumbnav" uk-margin>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
          <li><a href="#"><img src="../../../public/img/avatar.png" width="45" alt=""></a></li>
        </ul>
      </div>
    </div>
  </div>

  <style>

    p{
      margin: 10px 0;
    }

    /* /Toggle switcher */
    .toggle {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 32px;
      height: 14px;
      display: inline-block;
      position: relative;
      border-radius: 50px;
      overflow: hidden;
      outline: none;
      border: none;
      cursor: pointer;
      background-color: #cecece ;
      transition: background-color ease 0.3s;
    }

    .toggle:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      width: 15px;
      height: 14px;
      background: #2e2e2e;
      left: 17px;
      top: 0px;
      border-radius: 16px;
      font: 10px/28px Helvetica;
      text-transform: uppercase;
      font-weight: bold;
      text-indent: -22px;
      word-spacing: 37px;
      color: #fff;
      text-shadow: -1px -1px rgba(0,0,0,0.15);
      white-space: nowrap;
      box-shadow: 0 1px 2px rgba(0,0,0,0.2);
      transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
    }

    .toggle:checked {
      background-color: #cecece;
    }

    .toggle:checked:before {
      left: 0px;
      background: orange;
    }
    /* /Toggle switcher */

  </style>
