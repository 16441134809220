{#await items}
	<div uk-spinner></div>
{:then groups}

  <ul class:propTable class="{propTable} uk-subnav uk-padding-remove uk-margin-remove uk-flex uk-flex-wrap" id="groups-component">
    {#if withAll}
    <li class:uk-active="!current"><a on:click="setActive()">Все</a></li>
    {/if}
    {#each groups as item}
    <li class:uk-active="current && current.key === item.key"><a on:click="setActive(item)">{item.name}</a></li>
    {/each}
    <!-- {#if withAll}
      <li data-intro='Можно добавить новую группу при необходимости' data-step='3'><a on:click="set({isNew: true})"><span uk-icon="plus"></span></a></li>
    {/if} -->
  </ul>
{:catch error}
	<p>well that's odd</p>
{/await}

<div ref:newGroup></div>

<style>.uk-active {
  background: #c4c4c4; }

.modalTable ul {
  color: #515151; }

.modalTable .uk-active {
  background: #c4c4c4; }

.modalTable li {
  background: #ffffff;
  margin: 0;
  padding: 0;
  border: 1px solid #c4c4c4;
  vertical-align: baseline;
  border-radius: 1px; }
  .modalTable li a {
    color: #4f4f4f;
    text-transform: unset;
    padding: 0 5px; }

.mainTable ul {
  color: #515151; }

.mainTable .uk-active a {
  background: #1579D2;
  color: #ffffff; }

.mainTable li {
  color: #515151;
  background: #ffffff;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }
  .mainTable li a {
    color: #515151;
    text-transform: unset;
    padding: 10px 15px; }
</style>

<script>
  import Phalcon from 'modules/phalcon.js';

  import NewGroup from 'components/groups/GroupsEditModal.html';
  
  export default {
    components:{
      NewGroup,
    },

    oncreate(){
      const {initCurrent} = this.get();
      if(initCurrent) this.set({ current: initCurrent });
    },

    onstate({changed, current, previous}){
      if(previous === current){
      }
      let self = this;
      let {isNew} = this.get();
      const {items} = this.get();

      if (changed.isNew === true) {
        let {newGroup} = this.get();
        if (!newGroup || !newGroup._fragment) {
          newGroup = new NewGroup({
            target: this.refs.newGroup,
          });
          newGroup.on('success', group => {
            self.set({ items: Phalcon.groups.all() });
          });
          this.set({newGroup, isNew: false});
        }
      }
    },
    data(){
      return {
        newGroup: null,
        isNew: false,
        items: Phalcon.groups.all(),
        rights: Phalcon.rights.all(),
      }
    },
    methods: {
      setActive(current) {
        if(current) {
          this.set({ current });
          this.fire('filter', { group: current });
        } else {
          this.set({ current: null });
          this.fire('filter', { group: '' });
        }
      },
    }
  }
</script>