<div class="uk-flex uk-flex-between uk-margin-remove">
  <p class="uk-text-small uk-text-baseline uk-margin-remove">Пользователи группы: <span>{users.length}</span></p>
  <p class="uk-text-small uk-text-baseline uk-margin-remove">Заблокированные: <span>{blockCount}</span></p>
  <p class="uk-text-small uk-text-baseline uk-margin-remove">Особые права: <span>{uniqRightsCount}</span></p>
</div>

  {#if checked.length}
    <table class="uk-table uk-table-small uk-table-divider subTable">
      <thead class="uk-widtt-1-1">

<!--      <th><Input on:click="checkedAll()" checked="{checked.length === users.length && checked.length > 0 ? checked : `` }" type="checkbox" class="uk-checkbox"></th>-->

{#each actionsPoint as action}
      <th><a class="uk-text-capitalize uk-text-emphasis"><span uk-icon="icon: {action.icon}"></span>{action.name}</a></th>
      {/each}
      </thead>
    </table>
  {/if}

<table class="uk-table uk-table-small uk-table-divider uk-border">
  {#if !checked.length}
    <thead>
    <tr>

<!--      <th><Input on:click="checkedAll()" checked="{checked.length === users.length && checked.length > 0 ? checked : `` }" type="checkbox" class="uk-checkbox"></th>-->

      {#each table.columns as column, i }
      <th><div class="uk-text-capitalize uk-text-emphasis">{headers[i]}</div></th>
      {/each}
    </tr>
    </thead>
  {/if}

  <tbody>
    {#each users as item }
      <tr class="uk-position-relative">

<!--        <td><Input on:click="employeeAction(item.id)" type="checkbox" class="uk-checkbox" checked="{checked.indexOf(item.id) != -1 ? 'checked' : ''}"></td>-->

        {#each table.columns as column, x}
        <td class:block-at="item.block_at">
          <div class="uk-text-emphasis uk-text-small">
            {#if item[column.prop]}
              {#if column.link}
  <!--              <a on:click="fire('selectItem', { prop: column.prop, obj: item })">{item[column.prop]}</a>-->
              {#if column.prop === "phone"}
                <a>{templatePhone(column, item)}</a>
              {:else}
                <a on:click="fire('selectItem', { prop: column.prop, obj: item })">{item[column.prop]}</a>
<!--                <a onclick="console.log('Hello')">{item[column.prop]}</a>-->
              {/if}



              {:elseif column.subprop}
                {item[column.prop][column.subprop]}
              {:else}
                <div class="uk-flex uk-flex-between">
                  <div class="uk-overflow-hidden uk-width-5-6">
                    {#if column.prop === "phone"}
                      {templatePhone(column, item)}
                    {:else}
                      {item[column.prop]}
                    {/if}
                  </div>
                  {#if item.rights_id !== null}
                    <a on:click="userStar(item)" >
                      <img src="/svg/starUniq.svg" uk-tooltip="title: Редактировать права, отличные от прав группы; pos: top-right" alt="icon-star">
                    </a>
                  {:else}
                    <a class="defRights" on:click="userStar(item)">
                      <img src="/svg/starDefault.svg" uk-tooltip="title: Добавить права, отличные от прав группы; pos: top-right" alt="icon-star">
                    </a>
                  {/if}
                </div>
              {/if}
            {:else}
              Нет данных
            {/if}
          </div>
        </td>
        {/each}
      </tr>
    {/each}
  </tbody>
</table>

<div ref:rights></div>

<script>
  import Validator from 'modules/validator.js';
  import Rights from 'components/rights/RightsEdit.html';
  import UniqueRights from 'components/rights/RightsEditModal.html';
  import ModalView from 'components/groups/GroupsEditModal.html';
  
  import ActionsBlock from 'UI/ActionPills.html';
  import BlockUI from 'UI/BlockUI.html'

  export default {
    oncreate(){
      const selfState = this.get();
      const {uniqRights} = this.get();
    },
    components: {
      UniqueRights,
      ModalView,
      Rights,
      ActionsBlock,
      BlockUI,
    },
    helpers: {
      templatePhone(data, phone){
        if (data.prop === "phone"){
          return Validator.modifier.phoneToMask(phone[data.prop]);
        }
      }
    },
    methods: {
      actionType(elEv){
      },
      checkedAll(){
        let { users, checked } = this.get();

        if(checked.length === users.length && checked.length > 0) {
          this.set({ checked: [] });
          return;
        }
        users.map(function (item) {
          if(checked.indexOf(item.id) === -1) checked.push(item.id);
        });
        this.set({ checked });
      },
      employeeAction(id){
        let {checked} = this.get();
        let index = checked.indexOf(id);
        if (index === -1){
          checked.push(id);
        }else {
          checked.splice(index, 1);
        }
        this.set({checked});
      },
      userStar(item){
        let self = this;
        const {groupRights, users, uniqRights} = this.get();
        let goal = function(item) {
          if (item.rights_id === null) {
            return groupRights;
          }
          return item.rights_id;
        };

        const modalView = new UniqueRights({
          target: this.refs.rights,
          data:{
            user: item,
            rightsId: goal(item),
            userId: item.id,
          },
        });

        modalView.on("success", function (event) {
          let common = users.map(user => {
            if (user.id === event.id) {
              return event;
            } else {
              return user;
            }
          });
          self.set({users: common});
          self.fire("success");
        });
      }
    },
    computed: {
      uniqRightsCount({ users }){
        return users.filter(user => user.rights_id).length
      },
      blockCount({ users }){
        return users.filter(user => user.block_at).length
      }
    },
    data(){
      return {
        actionsPoint:[
          {name:"Права",key:"rights",icon:"settings"},
          {name:"Группа",key:"group",icon:"users"},
          {name:"Удалить",key:"delete",icon:"trash"},
          {name:"Отключить",key:"off",icon:"ban"},
        ],
        newForm: null,
        custom: 'safasf',
        headers: [
          "Имя",
          "Телефон",
          "E-mail",
        ],
        greyStar: false,
        checked:[],
        isActive: false,
        users: null,
        currentCategory: '',
        defaultData: [],
        table: {
          columns: [{
            header: "name",
            prop: "first_name",
          },{
            header: "phone",
            prop: "phone",
          },{
            header: "email",
            prop: "email",
          }],
        }
      }
    }
  }
</script>

<style>td > span {
  border: 1px solid #E2E2E2;
  border-radius: 4px; }

table {
  margin-top: 0px;
  border: 1px solid #E2E2E2;
  border-radius: 4px;
  background-color: white; }

td, th {
  border-right: 1px solid #E2E2E2;
  border-left: 1px solid #E2E2E2; }

th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: #C4C4C4; }

a.defRights {
  color: #c4c4c4;
  display: none;
  position: absolute;
  right: 15px; }

tr:hover a.defRights {
  display: block; }

span[uk-icon] {
  vertical-align: center;
  margin-right: 5px; }

.subTable {
  margin-bottom: 0;
  border-bottom: 0px; }

td.block-at,
td.block-at:hover {
  position: relative;
  opacity: 0.4;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  pointer-events: none; }
</style>