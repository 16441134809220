<div ref:modal uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <h2 class="uk-modal-title">Создание новой группы</h2>
    <input class="uk-input" type="text" bind:value="group.name" placeholder="Введите имя новой группы">

      <Rights on:rightsEdit="set({ rights: event.rights })"/>

    <p class="uk-text-right">
      <button on:click="confirmGroup()" class="g__btn--yes uk-button-small" type="button">Сохранить</button>
      <button class="g__btn--no uk-button-small uk-modal-close" type="button">Отменить</button>

    </p>
  </div>
</div>

<style>.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

.g__btn--yes {
  text-transform: unset;
  border-radius: 0;
  background: #FFFFFF;
  color: #e30613;
  border: 1px solid #e30613; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }

.g__btn--no {
  text-transform: unset;
  border-radius: 0; }
  .g__btn--no:hover {
    background: #c4c4c4;
    color: #ffffff; }
</style>

<script>
  import Validator from 'modules/validator.js';
  import Phalcon from 'modules/phalcon.js';

  import Rights from 'components/rights/RightsEdit.html';
  
  export default {
    components:{
      Rights
    },
    methods:{
      dataSend(){
        let { rights } = this.get();

        let myObj = Object.assign({}, rights);
        let value = {};
        let capitalize;

        for (var key in myObj) {
          capitalize = key.charAt(0).toUpperCase() + key.slice(1);
          value[capitalize] = [];
          for (var action in myObj[key]){
            if(myObj[key][action]) value[capitalize].push(action);
          }
        }
        value = JSON.stringify(value);
        return { value };
      },
      confirmGroup(){
        const { group } = this.get();
        const sendGroup = Object.assign({}, group);
        let { isCorrect } = this.get();
        let keyName = group.name;
        keyName = keyName.trim();
        let valuePoints;

        if (keyName) {
          keyName = Validator.translate.rus_to_latin(group.name);
          group.name = group.name.trim();
          keyName = keyName.trim();
          keyName = Validator.modifier.spaceToUnderscore(keyName);
          keyName = keyName.toLowerCase();
          group.key = keyName;
          isCorrect = true;

          group.relationships.Rights = this.dataSend();
          this.set({ group });
          ////console.log('GroupsRights',group);
          Phalcon.groups.create(group).then(answer => {
            if(answer.status === 200){
              this.fire('success', answer);
              UIkit.notification(`Создана группа ${answer.name}`, {status: 'success', pos: "bottom-right"});
            }
          });
          this.set({isCorrect});
        } else {
          UIkit.notification.closeAll();
          UIkit.notification("Введите название новой группы", {status: 'danger', pos: "bottom-right"});
        }

        if (isCorrect){
          this.set({isCorrect: false});
          UIkit.modal(this.refs.modal).hide();
        }
      }
    },
    oncreate(){
      const self = this;
      UIkit.modal(this.refs.modal, {
        escClose: true,
        bgClose: false
      });
      UIkit.modal(this.refs.modal).show();

      $(this.refs.modal).on('hidden', function(event) {
        self.destroy();
      });
    },
    data(){
      return {
        value: [],
        isCorrect: false,
        rights: {},
        group: {
          key:``,
          name:``,
          relationships: {},
        }
      }
    }
  }
</script>