<script>
    import Phalcon from 'modules/phalcon.js'

    export default {
        computed: {
            dateString({ date_start, date_end }) {
                date_start = moment(date_start).format('DD.MM.YYYY');
                date_end = moment(date_end).format('DD.MM.YYYY');

                if(date_start == date_end) return date_start;
                else return date_start + ' - ' + date_end;
            }
        },
        onstate({ changed, current, previous }) {
            const self = this;
            if (changed.new && current.new) setTimeout(() => { self.set({ new: false }) }, 1000);
        },
        data() {
            return {
                isRanged: false,
                text: moment().format('DD.MM.YYYY'),
                date_start: Phalcon.getUrlVars()['date_start'] || moment().format('YYYY-MM-DD'),
                date_end: Phalcon.getUrlVars()['date_end'] || moment().format('YYYY-MM-DD'),
                periods: ['Сегодня', 'Завтра', 'Вчера', 'Тек.неделя', 'Прошл.неделя', 'Тек.месяц', 'Прошл.месяц'],
                clicks: 0,
                datepicker: null
            }
        },
        oncreate() {
            let self = this;
            Phalcon.request('applications', 'all', 'GET').then(answer => {
                let datepicker = new Datepicker(this.refs.datepicker, {
                    inline: false,
                    ranged: true,
                    weekStart: 1,
                    separator: '-',
                    i18n: {
                        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                        weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
                    },
                    templates: {
                        container: [
                            '<div class="datepicker__container" style="width: 600px;" uk-grid>',
                            '<% for (var i = 0; i <= 1; i++) { %>',
                                '<div class="datepicker__pane uk-width-expand">',
                                '<%= renderHeader(i) %>',
                                '<%= renderCalendar(i) %>',
                                '</div>',
                            '<% } %>',
                            '<div><button onclick="window.datepicker.hide()" class="uk-button uk-button-primary uk-button-small uk-position-bottom-right">Применить</button><div>',
                            '</div>'
                        ].join(''),
                        day: [
                            '<% classNames.push("datepicker__day"); var temp = moment(timestamp).format(\'DD.MM.YYYY\'); %>',
                            '<td class="<%= classNames.join(" ") %>" data-day="<%= timestamp %>"><div class="day-item" day="<%= temp %>">',
                            '<span class="datepicker__daynum"><%= daynum %></span>',
                            "</div></td>"
                        ].join("")
                    },
                    onChange(date){
                        if(date.length > 1){
                            let date_start = moment(date[0]).format('YYYY-MM-DD');
                            let date_end = moment(date[date.length - 1]).format('YYYY-MM-DD');
                            self.set({ date_start, date_end });
                        } else {
                            let date_start = moment(date[0]).format('YYYY-MM-DD');
                            let date_end = moment(date[0]).format('YYYY-MM-DD');
                            self.set({ date_start, date_end });
                        }
                    },
                    onRender(){
                        $('.day-item').each((index, el) => {
                            let day = $(el).attr('day');
                            if(answer[day]){
                                $(el).append('<span class="uk-badge">'+answer[day]+'</span>')
                            }
                        })
                    }
                });
                window.datepicker = datepicker;
                this.set({datepicker});
            })
        },
    }
</script>

<div class="uk-inline">
    <span class="uk-form-icon" uk-icon="icon: calendar"></span>
    <input ref:datepicker class="uk-input uk-background-muted" type="text" onkeydown="return false" value={dateString}>
</div>

<style>
    :global(.datepicker .uk-badge){
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        height: 11px;
        min-width: 11px;
        padding: 0 2px;
    }

    span {
        z-index: 99;
    }

    input {
        border: none;
        border-radius: 2px;
        padding-left: 40px!important;
    }
</style>