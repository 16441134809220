<div ref:modal uk-modal>
  <div class="uk-modal-dialog uk-modal-body modal uk-padding-remove-top">
    <div class="btn__close uk-position-top-right uk-margin-top uk-margin-right" on:click="hide()"></div>
    <div class="uk-text-lead uk-margin-small-top">
      Профиль сотрудника: {user.first_name ? user.first_name : ""} {user.last_name ? user.last_name : ""}
    </div>
  <ul ref:accordion class="uk-margin-small-top" uk-accordion="multiple: true">

  <li class:uk-open="{isAccordionOpen}" class="uk-margin-small-top">
    <a  class="uk-accordion-title uk-text-small" >Личная информация</a>
    <div class="uk-accordion-content">
      <PersonalInfo { user } bind:imask />
    </div>
    <hr>
  </li>

  <li class:uk-open="{isAccordionOpen}" class="uk-margin-small-top">
    <a class="uk-accordion-title uk-text-small">Права и роль</a>
    <div class="uk-accordion-content">
      <div class="uk-width-1-1 uk-flex">
        <div class="switcher uk-width-3-4 uk-margin-remove">
          <Groups
              propTable="modalTable"
              on:filter="filter(event)"
              initCurrent="{user.groups}"
          />
        </div>

        {#if uniqueRights !== null || user.rights_id}
          <div class="uk-width-1-4 uk-padding-remove uk-margin-remove uk-flex uk-flex-right uk-flex-bottom">
            <a class="uk-text-muted uk-text-right uk-text-small">Особые права</a>
            <span uk-icon="warning" class="uk-icon uk-margin-small-left" uk-tooltip="title: Права, отличные от прав группы; pos: top-right"></span>
          </div>
        {/if}

      </div>
    <div>

    {#if rightsId}
      <Rights
        {rightsId}
        {reShuffle}
        {uniqueId}
        on:rightsEdit="rightsEdit(event)" />
    {:else}
      <Rights />
    {/if}

        </div>
      </div>
    <hr>
    </li>

  </ul>
<!--Submit block-->
    <div class="uk-flex uk-flex-right">
      <button on:click="confirmTemplate()" class="g__btn--yes uk-button  uk-button-small" id="confirm-btn">Сохранить</button>
      <button on:click="hide()" class="g__btn--no uk-button uk-button-small">Отменить</button>
    </div>
<!--/Submit block-->
  </div>
</div>

<style>.btn__close {
  width: 19px;
  height: 19px;
  box-sizing: border-box;
  background-image: url("/svg/close.svg");
  /*background-color: red;*/
  border-radius: 50%; }
  .btn__close:hover {
    cursor: pointer; }

.uk-accordion-content {
  margin-top: 5px; }

button {
  text-transform: unset; }

.g__btn--yes {
  border-radius: 0; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }

.g__btn--no {
  border-radius: 0;
  margin-left: 5px; }
  .g__btn--no:hover {
    background: #c4c4c4;
    color: #ffffff; }

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 350px; }

li {
  padding: 0 10px;
  margin: 0px !important; }

hr {
  margin: 5px 0px; }
</style>

<script>
  import Phalcon from 'modules/phalcon.js';
  import Validator from 'modules/validator.js';

  import Adds from 'components/users/UsersAdditional.html';
  import Rights from 'components/rights/RightsEdit.html';
  import PersonalInfo from 'components/users/UsersDetail.html';
  import Groups from 'components/groups/GroupsPills.html'

  export default {
    components:{
      Groups,
      Adds,
      Rights,
      PersonalInfo
    },
    data(){
      return {
        isEdit: false,
        user: {
          first_name:``,
          last_name:``,
          email:``,
          phone:``,
          is_active:true,
          is_admin:false
        },
        isAccordionClick: false,
        isAccordionOpen: true,
        group: {
          id:``,
          key:``,
          name:``,
          rights: {}
        },
        rightsId: null,
        uniqueId: null,
        uniqueRights: null,
        imask: null
      }
    },
    onstate({ changed, current, previous }){
      const {rightsId} = this.get();
      const self = this;
      if(changed.isAccordionClick && current.isAccordionClick){
        setTimeout(function () {
          self.set({ isAccordionClick: false })
        }, 350);
      }
    },
    oncreate(){
      var self = this;
      var { user, rightsId, uniqueId} = this.get();

      if(user.id) {
        Phalcon.users.get(user.id,{
          with: 'groups, rights'
        }).then(
          answer => {
            if (answer.status === 200) {
              delete answer.status;
              if(answer) {
                if(answer.groups && answer.groups.rights_id) rightsId = answer.groups.rights_id;
                if(answer.rights_id) uniqueId = answer.rights_id;
                user = answer;
                this.set({user, rightsId, uniqueId});
              }
              this.showModal().then(success => {
                let introStorage = localStorage.getItem('users_new');
                if(!introStorage) setTimeout(() => this.showIntro(), 1000);
              })
            }
          }
        );
      } else {
        this.showModal().then(success => {
          let introStorage = localStorage.getItem('users_new');
          if(!introStorage) setTimeout(() => this.showIntro(), 1000);
        })
      }
    },
    methods:{
      showIntro() {
        const self = this;

        let intro = introJs();
        intro.setOption("skipLabel", "Пропустить обучение");
        intro.setOption("nextLabel", "Дальше");
        intro.setOption("prevLabel", "Назад");
        intro.setOption("doneLabel", "Завершить");
        
        intro.setOptions({
          steps: [{
            element: $(this.refs.modal).find('#personalInfo-component')[0],
            intro: 'Заполните информацию о сотруднике через форму'
          }, {
            element: $(this.refs.modal).find('#groups-component')[0],
            intro: 'Выберите роль вашего сотрудника "Курьер"'
          }, {
            element: $(this.refs.modal).find('#confirm-btn')[0],
            intro: 'Когда все будет готово, нажмите "Сохранить"'
          }]
        });

        intro.start();

        this.set({intro});

        localStorage.setItem('users_new', 'true');
      },
      showModal() {
        const self = this;

        return new Promise(resolve => {
          UIkit.modal(this.refs.modal, {
            escClose: true,
            bgClose: false
          });
          UIkit.modal(this.refs.modal).show();
          UIkit.accordion(this.refs.accordion, {});
          $(this.refs.modal).on('show', function () {
            resolve('show success');
          });

          $(this.refs.accordion).on('hidden', function(event) {
            self.set({ isAccordionClick: true });
          });

          $(this.refs.modal).on('hidden', function(event) {
            let { isAccordionClick } = self.get();
            if(!isAccordionClick) self.destroy();
          });
        });
      },
      filter(item){
        const {user} = this.get();
        let {rightsId} = this.get();
        if(item.group.rights_id === rightsId){
          this.set({ reShuffle: true });
        }
        this.set({ reShuffle: false });
        this.set({ rightsId: item.group.rights_id });
        user.groups_id = item.group.rights_id;
        this.set({user});
      },
      hide() {
        this.set({ isAccordionClick: false });
        UIkit.modal(this.refs.modal).hide();
      },
      confirmTemplate() {
        const {user, isEdit, uniqueRights, imask} = this.get();
        let isCorrect = true;

        for (let key in this.refs) {
          if (this.refs[key].required) {
            if (this.valid(this.refs[key].getAttribute('type'), this.refs[key].value)) {
              this.refs[key].classList.remove('uk-form-danger');
              this.refs[key].classList.add('uk-form-success');
              $(this.refs[key]).parents('li').find('a').removeClass('uk-danger');
            } else {
              isCorrect = false;
              this.refs[key].classList.remove('uk-form-success');
              this.refs[key].classList.add('uk-form-danger');
              $(this.refs[key]).parents('li').find('a').addClass('uk-danger');
            }
          }
        }

        if (user.first_name.length === 0){
          UIkit.notification("заполните поле 'Имя'", {status: 'danger', pos: "bottom-right"});
          return;
        }else if(user.email == ""){
          UIkit.notification("заполните поле 'Email'", {status: 'danger', pos: "bottom-right"});
          return;
        }else if(Validator.isValid.mail(user.email) === -1){
          UIkit.notification("Некорректное поле 'Email'", {status: 'danger', pos: "bottom-right"});
          return;
        }else if (user.phone == "") {
          UIkit.notification("заполните поле 'Телефон'", {status: 'danger', pos: "bottom-right"});
          return;
        }else if (Validator.isValid.phone(user.phone) === -1){
          UIkit.notification("Невалидный телефон", {status: 'danger', pos: "bottom-right"});
          return;
        }

        if(!isCorrect) {
          UIkit.notification.closeAll();
          UIkit.notification("Заполните все обязательные поля", {status: 'danger', pos: "bottom-right"});
        } else {
          if(uniqueRights){ //Добавить права пользователю в relationships.rigths

            let testRights = Validator.modifier.rightsToString(uniqueRights);
            user.relationships = {rights:{
                                  value: testRights}};
          }

          if (isEdit) {
            if(user.phone == '') user.phone = null;
            if(user.email == '') user.email = null;
            Phalcon.users.edit(user, 'groups')
                .then(answer => {
                  if(answer.status !== 200  && !answer.errors){
                    UIkit.notification(`Обратитесь к администратору сервера`, {status: "danger", pos: "bottom-right"});
                  }else if( answer.status !== 200){
                    for (let i = 0; i < answer.errors.length; i++) {
                      UIkit.notification(`${answer.errors[i]}`, {status: "danger", pos: "bottom-right"});
                    }
                  }else {
                    this.fire('success', { user: answer });
                  }
                });
          } else {
            if(user.phone == '') user.phone = null;
            if(user.email == '') user.email = null;
            Phalcon.users.create(user, 'groups')
              .then(answer => {
              if(answer.status !== 200  && !answer.errors){
                UIkit.notification(`Обратитесь к администратору сервера`, {status: "danger", pos: "bottom-right"});
              }else if( answer.status !== 200){
                for (let i = 0; i < answer.errors.length; i++) {
                  UIkit.notification(`${answer.errors[i]}`, {status: "danger", pos: "bottom-right"});
                }
              }else {
                this.fire('success', { user: answer });
              }
            })
          }
        }
      },
      valid(type, data){
        if(!data) return false;
        let result;
        if(type === 'email'){
          result = Validator.isValid.mail(data);
        } else if(type === 'phone'){
          result = Validator.isValid.phone(data);
        } else if(type === 'text'){
          result = Validator.isValid.text(data);
        } else {
          if(data) return true;
        }
        result = result === 0;
        return result;
      },
      rightsEdit(e){
        const { uniqueRights } = this.get();
        if(!e.isChanged){
          this.set({ uniqueRights: null });     // v
        } else {                                // inversion
          this.set({ uniqueRights: e.rights }); // ^
        }
      }
    },
  }
</script>


