<div class="uk-card uk-card-default uk-card-body uk-width-1-1 wrapper" ref:main>
  <div class="uk-flex uk-flex-between">
    <h2 class="uk-text-lead uk-margin-small-bottom">
      <input on:click="fire('check')"
        type="checkbox"
        class="uk-checkbox uk-margin-small-right"
        checked="{checked.indexOf(route.id) != -1 ? 'checked' : ''}">
      {route.name}
    </h2>

    {#if !small}
      {#if !isOpen}
        <a on:click=set({isOpen:true}) class="uk-link uk-text-muted uk-text-small">Показать подробнее</a>
      {:else}
        <a on:click=set({isOpen:false}) class="uk-link uk-text-muted uk-text-small">Скрыть</a>
      {/if}
    {/if}
  </div>

  {#if !small}
    <div class="uk-flex uk-flex-between ">
      <div class="uk-text-small">Общая длина: <span class="text__lead"> {distanceText}</span></div>
      <div>
        <div class="uk-text-small">Время в пути: <span class="text__lead"> {durationText}</span></div>
        <div class="uk-text-small">Общее время: <span class="text__lead"> {allTimeText}</span></div>
      </div>
    </div>
  {:else}
    <div class="uk-text-small uk-width">Общая длина: <span class="text__lead"> {distanceText}</span></div>
    <div>
      <div class="uk-text-small uk-width">Время в пути: <span class="text__lead"> {durationText}</span></div>
      <div class="uk-text-small uk-width">Общее время: <span class="text__lead"> {allTimeText}</span></div>
    </div>
  {/if}

  <div style="{!isOpen ? 'display:none' : ''}">
    <h2 class="uk-text-lead uk-margin-small-top " >Точки маршрута:</h2>
    {#if route.points}
    <PointList showOnMap={false} points={route.points} isTypeSelect={false} />
    {/if}
  </div>
  
  {#if !small}
  <div class="uk-position-relative uk-margin-small-top">
    <div class="uk-position-left-bottom uk-text-small" ref:dlboyslt>
      Курьер: 
      <select bind:value="dlboy" class="uk-select uk-form-small uk-width-small" on:change="setDlboy(parseInt(route.id), parseInt(dlboy))">
        <option value="0">Не назначен</option>
        {#each dlboys as item}
          <option value="{item.id}" selected="{dlboy == item.id}">{item.last_name ? item.last_name : ''} {item.first_name}</option>
        {/each}
      </select>
    </div>
    <div class="uk-align-right uk-margin-small uk-margin-small-top">
      <ul class="uk-iconnav">
        <li class="uk-flex uk-flex-bottom"><a uk-icon="file-edit" on:click="options.root.navigate('map/routes?routes_id='+route.id+'&step=4&clear_order=0&date='+route.date)" uk-tooltip="title: Редактировать; pos: top-left"></a></li>
        <li class="uk-flex uk-flex-bottom"><a uk-icon="trash" on:click="remove()" uk-tooltip="title: Расформировать (Удалить маршрут сохранив точки); pos: top-left"></a></li>
      </ul>
    </div>
  </div>
  {:else}
        <button on:click="removeFromEditor()" class="uk-button uk-button-text button-icon uk-margin-small-left uk-margin-small-top">
          <span uk-icon="trash" class="uk-position-left"></span>
          Расформировать
        </button>
      {/if}
</div>

<style>.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

.g__btn {
  text-transform: unset;
  border-radius: 0;
  background: #FFFFFF;
  color: #e30613;
  border: 1px solid #e30613;
  width: 150px; }
  .g__btn:hover {
    background: #e30613;
    color: #ffffff; }

li {
  margin: 0 !important; }

.uk-input {
  background-color: #FFFFFF;
  border: none; }

.tk {
  background-color: #28B446; }

.dl {
  background-color: #e30613; }

.wrapper {
  color: #000;
  padding: 15px 25px 15px 10px; }

h2 {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: bold; }

.text__lead {
  font-size: 16px;
  font-weight: bold; }
</style>

<script>
import router from 'modules/router.js';
import Phalcon from 'modules/phalcon.js';

import PointList from 'components/points/PointsList.html';

export default {
  components:{
    PointList
  },
  computed: {
    distanceText({ distance }) {
      return (distance / 1000).toFixed(1).toString() + ' км'
    },
    durationText({ duration }) {
      let hours = Math.floor(duration / 60 / 60);
      if (hours) duration -= (hours * 60 * 60);
      let minutes = Math.ceil(duration / 60);
      return (hours ? hours + ' ч ' : '') + minutes + ' м'
    },
    allTimeText({ all_time }) {
      let hours = Math.floor(all_time / 60 / 60);
      if (hours) all_time -= (hours * 60 * 60);

      let minutes = Math.ceil(all_time / 60);
      return (hours ? hours + ' ч ' : '') + minutes + ' м'
    },
  },
  oncreate(){
    const { route, showIntro } = this.get();
    this.set({
        distance: route.distance,
        duration: route.time,
        all_time: route.all_time
    });

    if(showIntro){
      let introStorage = localStorage.getItem('route_intro');
      if(!introStorage) setTimeout(() => this.showIntro(), 500);
    }
  },
  onupdate({changed,current}){
    if(changed.dlboys && current.dlboys && current.dlboys.length > 0){
      if(!current.intro) {
        let introStorage = localStorage.getItem('route_dlboy_intro');
        if(!introStorage && !current.intro) setTimeout(() => this.showDlboyIntro(), 500);
      }
    }
  },
  methods:{
    showIntro() {
      const pointsTabActive = $('#points-tab').hasClass('uk-active');
      const self = this;

      if(pointsTabActive) UIkit.switcher($('#tabs')).show(1);

      let intro = introJs();
      intro.setOption("skipLabel", "Пропустить обучение");
      intro.setOption("nextLabel", "Дальше");
      intro.setOption("prevLabel", "Назад");
      intro.setOption("doneLabel", "Завершить");
      
      intro.setOptions({
        steps: [{
          element: this.refs.main,
          intro: 'Это созданный вами маршрут, здесь указана основная информация о нем'
        }, {
          element: this.refs.dlboyslt,
          intro: 'Вы можете назначить курьера на маршрут, чтобы он в дальнейшем видел свои точки с мобильной версии платформы'
        }, {
          element: $('#section-orders')[0],
          intro: 'Чтобы создать курьера в системе, нужно перейти в раздел "Справочники"->"Сотрудники"'
        }]
      });

      intro.oncomplete(function(){
        router.goTo('users');
      });

      intro.onexit(function() {
        if(pointsTabActive) UIkit.switcher($('#tabs')).show(0);
      });

      intro.start();

      this.set({intro});

      localStorage.setItem('route_intro', 'true');
    },

    showDlboyIntro() {
      const pointsTabActive = $('#points-tab').hasClass('uk-active');
      const self = this;

      if($('#points-tab').hasClass('uk-active')) UIkit.switcher($('#tabs')).show(1);

      let intro = introJs();
      intro.setOption("skipLabel", "Пропустить обучение");
      intro.setOption("nextLabel", "Дальше");
      intro.setOption("prevLabel", "Назад");
      intro.setOption("doneLabel", "Завершить");
      
      intro.setOptions({
        steps: [{
          element: this.refs.dlboyslt,
          intro: 'У вас появился новый курьер в системе, вы можете назначить его на муршрут выбрав из списка'
        }]
      });

      intro.oncomplete(function(){
        if(pointsTabActive) UIkit.switcher($('#tabs')).show(0);
      });

      intro.onexit(function() {
        if(pointsTabActive) UIkit.switcher($('#tabs')).show(0);
      });

      intro.start();

      this.set({intro});

      localStorage.setItem('route_dlboy_intro', 'true');
    },

    remove(){
      const { route } = this.get();
      if(route.id) Phalcon.routes.remove(route.id).then(answer => {
        if(answer.status == 200){
          UIkit.notification({
            message: 'Маршрут "' + route.name + '" успешно удален!',
            status: 'success',
            pos: 'bottom-right'
          });
          this.fire('remove');
        }
      })
    },

    removeFromEditor(){
      const { route, checked } = this.get();
      if(checked.includes(route.id)){
        this.fire('check');
      }

      this.fire('remove', route);
    },
    
    setDlboy(route_id, id){
      ////console.log({route_id, id});
      Phalcon.routes.setDlboy(route_id, id).then(answer => {
        if(answer.status == 200) UIkit.notification(`Курьер на Маршрут изменен`, { status: "success", pos: "bottom-right" });
      })
    }
  },
  data(){
    return {
      isOpen: false,
      dlboy: 0,
      showIntro: true
    }
  }
}
</script>