<!-- This is the modal -->
<div id="modal-example" uk-modal>
  <div class="uk-modal-dialog uk-modal-body auth">
    <div class="auth__container">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <h2 class="uk-modal-title auth__head"><span><img class="fondo-logo" src="/svg/redLogo.svg" alt=""></span>
        Fondo<span>CRM</span></h2>

      {#if isRegister === true}
        <div class="uk-width-1-1 uk-text-center uk-margin-small-top uk-margin-medium-bottom">
          Письмо с подтверждением было выслано на указанную вами почту <span>(<a>{signUp.email}</a>)</span>. Нажмите на ссылку в письме, чтобы подтвердить свой Email.
        </div>
        <div class="uk-width-1-1 form__button">
          <button on:click="toMainPage()" class="sub-button uk-button-small  uk-button uk-button-link">Закрыть окно</button>
          <button on:click="set({ isRegister: false, createAccount: false, createConfirm: false })" class="g__btn--yes uk-button-small">Авторизация</button>
        </div>
        {:else}
        {#if createAccount}
        <h4 class="uk-text-center uk-width-1-1">Регистрируйтесь, чтобы увидеть больше</h4>
        <ul class="uk-list auth__form">
          <li><input bind:value="signUp.first_name" class="uk-width-1-1 uk-search-input"  required placeholder="Имя" type="text"></li>
          <hr>
          <!---->
          <li><input bind:value="signUp.last_name" class="uk-width-1-1 uk-search-input"  placeholder="Фамилия" type="text"></li>
          <hr>
          <li><input bind:value="signUp.middle_name" class="uk-width-1-1 uk-search-input"  placeholder="Отчество" type="text"></li>
          <hr>
          <!---->
<!--          <li><input class="uk-width-1-1 uk-search-input" bind:value="signUp.loginField"  placeholder="Email или Телефон" type="text"></li>-->
<!--          <hr>-->

<!---->
          <li><input class="uk-width-1-1 uk-search-input" bind:value="signUp.email" required  placeholder="Email" type="email"></li>
          <hr>
          <li><input class="uk-width-1-1 uk-search-input" bind:value="signUp.phone" required  placeholder="Телефон" type="tel"></li>
          <hr>
<!---->
          <li><input bind:value="signUp.password1" class="uk-width-1-1 uk-search-input" required  placeholder="Пароль" type="password"></li>
          <hr>
          <li><input bind:value="signUp.password2" class="uk-width-1-1 uk-search-input" required  placeholder="Повторите пароль" type="password"></li>
          <hr>
          <li>
            <div class="uk-flex uk-flex-between">
              <input on:input|preventDefault="domainListener(event)" bind:value="signUp.domain" required class="uk-width-1-1 uk-search-input"  placeholder="Введите поддомен" type="text">
              <span uk-icon="question" class=" uk-icon" uk-tooltip="title: название Вашей компании в адресной строке; pos: top-right"></span>
            </div>
          </li>
          <hr>
        </ul>
        {:else}
          <ul class="uk-list auth__form">
            {#if !isRefresh}
            <li><input bind:value="signIn.login" class="uk-width-1-1 uk-search-input"  placeholder="Email или Телефон" type="text"></li>
            {:else}
            <li><input bind:value="refreshAccessField" class="uk-width-1-1 uk-search-input"  placeholder="Email или Телефон" type="text"></li>
            {/if}
            <hr>
          {#if !isRefresh}
            <li><input class="uk-width-1-1 uk-search-input" bind:value="signIn.password" placeholder="Пароль" type="password"></li>
            <hr>
          {/if}
          </ul>
        {/if}

      {#if createAccount}
        <div class="form__rights uk-padding-small-bottom uk-margin-bottom">
          <div class="uk-flex uk-flex-between auth__form form__button uk-padding-small-bottom ">
            <button on:click="switchLog()" class="sub-button uk-button-small  uk-button uk-button-link" type="button">Есть аккаунт</button>
            <button type="submit" on:click="registerConfirm()"  class="g__btn--yes uk-button  uk-button-small">Регистрация</button>
          </div>
          <p class="uk-text-center">
            Нажимая на кнопку, вы даете согласие на <br>
            <a class="uk-link-muted">обработку персональных данных</a>
          </p>
        </div>
      {:else}
        {#if !isRefresh}
          <div class="auth__form form__button">
            <button on:click="pswRefresh()" class="button button__repeat uk-button uk-button-link">Забыли пароль?</button>
          </div>
          <div class="uk-width-1-1 form__button">
            <button on:click="switchLog()" class="sub-button uk-button uk-button-link" type="button">Создать аккаунт</button>
            <button class="g__btn--yes uk-button uk-button-small" on:click="loginConfirm()">Войти</button>
          </div>
          <div  style="display: none" class="form__rights uk-margin-small-top uk-margin-bottom uk-padding-remove">
            <p class="uk-text-center">
              <a class="uk-link-muted uk-text-meta uk-text-small button__repeat">Продолжить в гостевом режиме</a>
            </p>
          </div>
        {:else}
          <div class="uk-width-1-1 uk-flex auth__form">
            <button on:click="pswRecovery()" class="button__repeat uk-button uk-button-link">Восстановить пароль</button>
          </div>
        {/if}
      {/if}

      {#if !isRefresh}
        <div style="display: none">
          <p class="uk-width-1-1 uk-text-center  form__condition"><span>или</span></p>
          <p class="uk-text-center form__condition">используйте аккаунт вашей социальной сети</p>

          <div class="form__thumbs">
            <a href="" class="thumbs__item uk-icon-button uk-margin-small-right"><img class="fondo-logo" src="/svg/vk.svg" alt=""></a>
            <a href="" class="thumbs__item uk-icon-button uk-margin-small-right"><img class="fondo-logo" src="/svg/facebook.svg" alt=""></a>
            <a href="" class="thumbs__item uk-icon-button uk-margin-small-right"><img class="fondo-logo" src="/svg/facebook.svg" alt=""></a>
            <a href="" class="thumbs__item thumbs__item--google uk-icon-button uk-margin-small-right">
              <span><img class="fondo-logo" src="/svg/search.svg" alt=""></span>
              <span><img style="margin-left: 5px" class="fondo-logo" src="/svg/google.svg" alt=""></span>
            </a>
          </div>
        </div>
      {/if}
      {/if}
    </div>
<!--    {#if !isRefresh}-->
<!--      {#if !createConfirm}-->
<!--        {#if createAccount}-->
<!--&lt;!&ndash;          <Button on:click="switchLog()" class="uk-Button uk-Button-secondary uk-width-1-1 uk-Button-large main__button" type="Button">Уже есть аккаунт</Button>&ndash;&gt;-->
<!--        {:else}-->
<!--&lt;!&ndash;          <Button on:click="switchLog()" class="uk-Button uk-Button-secondary uk-width-1-1 uk-Button-large main__button" type="Button">Создать аккаунт</Button>&ndash;&gt;-->
<!--        {/if}-->
<!--      {/if}-->
<!--    {:else}-->
<!--&lt;!&ndash;      <Button on:click="switchLog()" class="uk-Button uk-Button-secondary uk-width-1-1 uk-Button-large main__button uk-position-bottom" type="Button">Создать аккаунт</Button>&ndash;&gt;-->
<!--    {/if}-->
  </div>
</div>

<!--<link rel="stylesheet" href="Auth.css">-->
<script>
  import Phalcon from 'modules/phalcon.js';
  import Validator from 'modules/validator.js';
  
  export default {
    ondestroy() {
      history.pushState({}, "", `/`);
      window.dispatchEvent(new Event('popstate'));
    },
    oncreate() {
      let self = this;
      UIkit.modal(`#modal-example`,
        {
          escClose: true,
          bgClose: false
        });
      UIkit.modal(`#modal-example`).show();
      $(`#modal-example`).on('hidden', function () {
        self.destroy();
      });
    },
    data() {
      return {
        createConfirm: false,
        isRefresh: false,
        refreshAccessField: "",
        isRegister: false,
        isLogged: false,
        createAccount: false,
        mail:"testField",
        signIn: {
          login: "",
          phone: "",
          mail: "",
          password: "",
        },
        signUp: {
          domain: null,
          nameField: "",
          loginField: "",
          first_name: "",
          last_name: "",
          surname: "",
          middle_name: "",
          email: "",
          phone: "",
          password1: "",
          password2: "",
        },
      }
    },
    methods: {
      domainListener(data){
        const {signUp} = this.get();
        let prevState = signUp.domain;
        let transformation;

        if(Validator.isValid.subdomain(data.target.value) === 0){
          transformation = Validator.translate.rus_to_latin(data.target.value);
          signUp.domain = transformation;
          this.set({signUp});
        }else {
          if(data.target.value.length > 0){
            signUp.domain = prevState.slice(0,-1);
            this.set({signUp});
            UIkit.notification("/[A-Za-zа-яА-Я0-9\\-]/gm", { status: "danger", pos: "bottom-right" });
          }else{
            return;
          }
        }
      },
      pswRecovery() {
        const { refreshAccessField } = this.get();
        if (refreshAccessField.length > 0) {
          let isMail,
            isPhone;
          isMail = Validator.isValid.mail(refreshAccessField);
          isPhone = Validator.isValid.phone(refreshAccessField);

          if (isMail === 0) {
            UIkit.notification(`Вы ввели почту`, { status: "success", pos: "bottom-right" });
            Phalcon.users.passwordReset({ email: refreshAccessField.toLowerCase() }).then(answer => {
              if (answer.status === 200) {
                UIkit.notification(answer.messages[0], { status: "success", pos: "bottom-right" });
              } else {
                UIkit.notification(answer.messages[0], { status: "danger", pos: "bottom-right" });
              }
            });

          } else if (isPhone === 0) {
            ////console.log("Вы ввели номер телефона");
            UIkit.notification(`Вы ввели номер телефона`, { status: "danger", pos: "bottom-right" });
          }else {
            UIkit.notification(`Введите номер телефона или почту`, { status: "danger", pos: "bottom-right" });
          }
        } else {
          UIkit.notification(`Введите номер телефона или почту`, { status: "danger", pos: "bottom-right" });
        };
      },
      pswRefresh() {
        const { isRefresh } = this.get();
        this.set({ isRefresh: true });
      },
      toMainPage() {
        const self = this;
        this.set({ createConfirm: false });
        self.destroy();
      },
      loginParse(string) {
        const { signIn } = this.get();
        let isMail;
        let isPhone;
        isMail = string.search(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/gm);
        isPhone = string.search(/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/);
        let cfgContainer = {};
        cfgContainer.password = signIn.password;

        if (isMail === 0) {
          signIn.mail = string;
          cfgContainer.email = signIn.mail.toLowerCase();
        } else if (isPhone === 0) {
          signIn.phone = string.replace(/\s/g, ``);
          cfgContainer.phone = signIn.phone;
        }
        return cfgContainer;
      },
      loginConfirm() {
        const { signIn } = this.get();
        let result = this.loginParse(signIn.login);
        ////console.log('res', result);
        Phalcon.session.start(result)
          .then(answer => {
            if (answer.status !== 200) {
              UIkit.notification(`${answer.errors[0]}`, { status: "danger", pos: "bottom-right" });
            } else {
              UIkit.notification(`Вы успешно авторизованы`, { status: "success", pos: "bottom-right" });
              setTimeout(function () {
                window.location.replace('/');
              }, 1500)
            }
          })
          .catch(e => {});
      },
      divideName(user) {
        let names;
        let users = {};
        if (user.length) {
          names = user.split(" ");
          users.first_name = names[0];
          users.last_name = names[1];
          return names;
        }
      },
      registerSeparator(string) {
        const { signIn } = this.get();
        let isMail;
        let isPhone;
        isMail = string.search(/^([a-zA-Z0-9_\-\.]+)@{1}([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/gm);
        isPhone = string.search(/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/);
        let cfgContainer = {};

        if (isMail === 0) {
          signIn.mail = string;
          cfgContainer.email = signIn.mail.toLowerCase();
          return cfgContainer;
        } else if (isPhone === 0) {
          signIn.phone = string.replace(/\s/g, ``);
          cfgContainer.phone = signIn.phone;
          return cfgContainer;
        } else {
          return null;
        }
      },
      registerParse() {
        const { signUp, refreshAccessField, isRegister } = this.get();
        const cfgContainer = {};
        if (!signUp.first_name) {
          UIkit.notification(`Введите имя`, { status: "danger", pos: "bottom-right" });
          return;
        }else if (signUp.first_name === 0){
          UIkit.notification(`Неверно заполнено имя`, { status: "danger", pos: "bottom-right" });
        }
        if (signUp.password1 === signUp.password2) {
          cfgContainer.password = signUp.password1;
          cfgContainer.domain = signUp.domain;
          //
          cfgContainer.phone = signUp.phone;
          cfgContainer.email = signUp.email;
          //
          cfgContainer.first_name = signUp.first_name;
          cfgContainer.last_name =  signUp.last_name;
          Phalcon.users.register(cfgContainer)
            .then(answer => {
              if (answer.status !== 200) {
                UIkit.notification(`${answer.errors[0]}`, { status: "danger", pos: "bottom-right" });
              } else {
                UIkit.notification(`Вы успешно зарегистрированы`, { status: "success", pos: "bottom-right" });
                this.set({ isRegister: true, createConfirm: true });
              }
            })
            .catch(e => {});

        } else {
          UIkit.notification(`Введеные пароли не совпадают`, { status: "danger", pos: "bottom-right" });
        }
      },
      registerConfirm() {

        let inputs = document.querySelectorAll('.auth__form input');
        inputs = Array.from(inputs);

        if (Validator.validForm(inputs)) {
          this.registerParse();
        }
      },
      switchLog() {
        let { createAccount, isRefresh } = this.get();
        createAccount = !createAccount;
        this.set({ createAccount, isRefresh: false });
      },
    }
  }
</script>

<style>.fondo-logo {
  color: #E30613; }

.g__btn--yes {
  border-radius: 0;
  width: 50%;
  border: 1px solid #e30613; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }

h4 {
  font-size: 14px;
  line-height: 16px;
  margin: 0px;
  /*font-weight: bold;*/ }

.auth {
  width: 410px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 25px rgba(0, 0, 0, 0.5); }
  .auth__head {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    margin-top: 25px;
    text-align: center; }
    .auth__head span {
      color: #E30613; }
  .auth__container {
    padding: 20px 20px 0px 20px; }
  .auth__form {
    color: #c4c4c4;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    padding: 0px 55px 20px 55px; }
    .auth__form li {
      padding: 0px 20px;
      margin-top: 30px; }
    .auth__form hr {
      margin: 0 5px;
      border: 1px solid #c4c4c4; }

.form__rights {
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  .form__rights p {
    font-size: 11px;
    line-height: 13px;
    margin: 0; }
    .form__rights p a {
      font-size: 11px;
      line-height: 13px;
      text-decoration: underline; }

.form__button {
  padding: 0 65px 10px 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.button {
  color: #cecece;
  font-size: 24px; }
  .button__repeat {
    text-transform: unset;
    color: #c4c4c4;
    font-size: 13px;
    line-height: 15px; }
  .button__confirm {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #E30613; }

.form__condition {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0;
  color: #000000; }
  .form__condition span {
    font-weight: bold;
    font-size: 12px;
    line-height: 21px; }

.form__thumbs {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .form__thumbs .thumbs__item {
    margin-bottom: 25px;
    width: 29px;
    height: 29px;
    /*background-color: $redText;*/
    color: #ffffff;
    font-size: 24px; }
    .form__thumbs .thumbs__item--google {
      background-color: #ffffff;
      color: #000000;
      height: 26px;
      width: 88px; }

.sub-button {
  text-decoration: none;
  color: #E30613; }
  .sub-button:hover {
    text-decoration: underline; }

.main__button {
  height: 55px;
  text-transform: unset;
  font-size: 21px; }

button {
  text-transform: unset; }
</style>