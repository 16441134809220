<p class="uk-text">Данные о компании</p>

<div uk-grid>
  <div class="uk-width-2-5">
    <div ref:avatar on:click="inputEvent()" class="uk-margin-auto-vertical circle-image " style="background-image: url(img/avatar.png);"></div>
    <input type="file" id="inputAvatarCompany" accept=".png, .jpg, .jpeg" style="display: none">
  </div>

  <div class="uk-width-3-5 info">

    <div class="uk-margin info__item">
      <div>
        <label class="uk-form-label uk-text-small" >Поддомен</label>
        <span uk-icon="question" class=" uk-icon uk-margin-small-left" uk-tooltip="title: название Вашей компании в адресной строке; pos: top-right"></span>
      </div>
      <div class="uk-form-controls ">
        <input on:input="saveChange(this,`domain`)" bind:value="something.domain" class="uk-input g__input--simple" type="text" placeholder="Some text...">
      </div>
    </div>

    {#if isDataChange}
      <div class="uk-margin info__item uk-text-small">
        <div class="uk-form-controls item__confirm uk-text-small uk-width-3-5">
          <button on:click="companyChange()" class="g__btn--yes uk-button  uk-button-small">Сохранить изменения</button>
        </div>
      </div>
    {/if}


  </div>

</div>

<script>
  export default {
    methods:{
      inputEvent(){
        let self = this;
        let fileInput = $('#inputAvatarCompany');
        fileInput.trigger('click');
        fileInput.on('change', event => {
          if(document.getElementById('inputAvatar').files && document.getElementById('inputAvatarCompany').files[0]){
            let fileReaderC = new FileReader();

            fileReaderC.onload = function () {
              $(self.refs.avatar).css('background-image', 'url(' + fileReaderC.result + ')');
            };
            fileReaderC.readAsDataURL(document.getElementById('inputAvatarCompany').files[0]);
          }
        });

      },
      companyChange(){
        const {isDataChange} = this.get();
        UIkit.notification(`Данные о компании изменены`, {status: "primary", pos: "bottom-right"});
        this.set({isDataChange: false});

      },
      saveChange(el, target){
        const {isDataChange, something} = this.get();
        if (something.company !== something.defCompany || something.defDomain !== something.domain){
          this.set({isDataChange: true });
        } else {
          this.set({isDataChange: false });
        }
      },
    },
    data(){
      return {
        isDataChange: false,
        something: {
          company:"Home",
          defCompany:"Home",
          domain:"admin",
          defDomain:"admin",
        }
      }
    }
  }
</script>

<style>.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

.item__confirm {
  color: #000;
  display: flex;
  justify-content: flex-end;
  flex-direction: row; }

.info {
  padding-left: 10px; }
  .info__item {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center; }
    .info__item label {
      vertical-align: baseline;
      width: 40%; }
    .info__item div {
      width: 60%; }

.circle-image {
  width: 123px;
  height: 123px;
  transition: 0.3s; }
  .circle-image:hover {
    opacity: 0.8; }

input {
  border-radius: 5px;
  height: 30px; }

.g__btn--yes {
  width: 100%;
  text-transform: unset;
  border-radius: 0;
  background: #FFFFFF;
  color: #e30613;
  border: 1px solid #e30613; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }
</style>

