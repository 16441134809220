<div class="wrapper uk-flex uk-flex-center uk-flex-middle uk-grid-small" uk-grid>
  <div class="uk-width-auto">
    <span uk-icon="icon: info"></span>
  </div>
  <div class="uk-width-expand">
    <div class="uk-text">
      {@html textData[step - 1]}
    </div>
  </div>
</div>

<style>.wrapper {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 999;
  color: #ffffff;
  width: 300px;
  min-height: 60px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: 15px 20px; }
</style>