<div class="dropdown uk-text-left">
    <ul class="uk-nav uk-dropdown-nav">
        <li class="uk-nav-header main-header">{title}</li>
        {#if items}
            {#each items as item}
                {#if !item.isAdmin || item.isAdmin && isAdmin}
                <li>
                    <a on:click="options.root.navigate(item.pathname)">{item.name}</a>
                </li>
                {/if}
            {/each}
        {/if}
    </ul>
</div>

<style>.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

.dropdown {
  display: none;
  position: absolute;
  background-color: #080808;
  left: 60px;
  height: auto;
  padding: 14px 25px 15px 18px; }

ul {
  padding: 0; }

.uk-nav-header {
  color: #ffffff; }

.main-header {
  margin-left: -15px;
  text-align: left; }
</style>