<div ref:main class="uk-section uk-section-default uk-height-1-1 uk-padding-small">
  <div ref:header>
    <ul class="uk-child-width-expand" uk-tab="connect: #tabs; animation: uk-animation-slide-left-medium">
      <li id="points-tab" class:uk-active="hash == '#points'" on:click="changeTab('points')" data-intro='Здесь отображаются заказы созданные через раздел "Новый заказ"' data-step='1'><a href="#">Заказы <span
            class="uk-text-bold uk-margin-small-left">{ points ? points.length : "0" }</span></a></li>
      <li id="routes-tab" class:uk-active="hash == '#routes'" on:click="changeTab('routes')" data-intro='Здесь маршруты созданные в системе' data-step='2'><a href="#">Маршруты <span
            class="uk-text-bold uk-margin-small-left">{ routes ? routes.length : "0" }</span></a></li>
    </ul>
  </div>

  <div ref:body>
    <ul id="tabs" class="uk-switcher uk-margin">
      <li class="uk-position-relative">
        {#if loadingPoints}
          <div class="uk-height-1-1 uk-width uk-flex uk-flex-middle uk-flex-center">
            <div uk-spinner="ratio: 2"></div>
          </div>
        {:else}
        <div ref:points>
          <!-- <div class="uk-margin-small-top uk-margin-small-bottom uk-flex uk-flex-middle">
            <label class="uk-form-label" for="form-horizontal-text">Группировка</label>
            <div class="uk-form-controls uk-margin-small-left">
              <select class="uk-select uk-form-width-medium uk-form-small" bind:value="groupBy">
                <option value="points">По точкам</option>
                <option value="legals">По заказчикам</option>
                <option value="orders">По заказам</option>
                <option value="dlboys">По курьерам</option>
              </select>
            </div>
          </div> -->
          {#if points && points.length > 0}
            
            {#if groupBy == 'points'}
              <PointList bind:points { showOnMap } isActive={tab.points} isTypeSelect=true />
            {:elseif groupBy == 'orders'}
              <PointList bind:points {groupBy} group={orders} {showOnMap} isActive={tab.points} isTypeSelect=true />
            {:elseif groupBy == 'legals'}
              <PointList bind:points {groupBy} group={legals} {showOnMap} isActive={tab.points} isTypeSelect=true />
            {:elseif groupBy == 'dlboys'}
              <PointList bind:points {groupBy} group={dlboys} {showOnMap} isActive={tab.points} isTypeSelect=true />
            {/if}
            <div class="uk-padding uk-text-justify">
                <span class="uk-text-muted">Чтобы составить маршрут из точек или удалить существующие, нажмите на "{addBtnText}".</span>
  
                <div class="uk-text-center">
                    <svg class="arrows">
                      <path class="a1" d="M0 0 L30 32 L60 0"></path>
                      <path class="a2" d="M0 20 L30 52 L60 20"></path>
                      <path class="a3" d="M0 40 L30 72 L60 40"></path>
                    </svg>
                </div>
            </div>
          {:else}
            <div class="uk-padding uk-text-justify">
              <span class="uk-text-muted">Нет точек. Чтобы добавить новые точки, нажмите на "{addBtnText}".</span>

              <div class="uk-text-center">
                  <svg class="arrows">
                    <path class="a1" d="M0 0 L30 32 L60 0"></path>
                    <path class="a2" d="M0 20 L30 52 L60 20"></path>
                    <path class="a3" d="M0 40 L30 72 L60 40"></path>
                  </svg>
              </div>
            </div>
          {/if}
        </div>
        {/if}
      </li>
      <li class="uk-position-relative">
        {#if loadingRoutes}
          <div class="uk-height-1-1 uk-width uk-flex uk-flex-middle uk-flex-center">
            <div uk-spinner="ratio: 2"></div>
          </div>
        {:else}
          {#if routes && routes.length > 0}
            <RoutList { routes } isActive={tab.routes} on:remove="removeRoute()" />
          {:else}
            <div class="uk-padding uk-text-justify">
              <span class="uk-text-muted">Нет точек. Чтобы добавить новые точки, нажмите на "{addBtnText}".</span>

              <div class="uk-text-center">
                  <svg class="arrows">
                    <path class="a1" d="M0 0 L30 32 L60 0"></path>
                    <path class="a2" d="M0 20 L30 52 L60 20"></path>
                    <path class="a3" d="M0 40 L30 72 L60 40"></path>
                  </svg>
              </div>
            </div>
          {/if}
        {/if}
      </li>
    </ul>
  </div>
  
  <div ref:footer>
      {#if applications_ids.length > 0}
      <button class="uk-button n__btn--primary uk-width" on:click="options.root.navigate('/map/routes?applications_ids='+applications_ids.join(',')+'&step=4&clear_order=0')">
          Составить маршрут по заказам
      </button>
      {:else}
      <button class="uk-button n__btn--primary uk-width" on:click="newRouteBtnOnClick()" data-intro='Давайте создадим свой маршрут и создадим на него исполнителя' data-step='3'>
        {addBtnText}
      </button>
      {/if}
  </div>
</div>


<style>@charset "UTF-8";
.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

ref:main {
  position: absolute;
  width: 500px;
  top: 0;
  left: 0;
  overflow-y: auto; }

ref:points {
  max-height: 50%;
  overflow-y: auto; }

ref:header {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 45px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  z-index: 9; }

ref:header p {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #666666;
  margin: 0; }

ref:header ul {
  margin-top: 20px;
  margin-bottom: 0; }

ref:header ul a {
  padding-bottom: 15px; }

ref:footer {
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  height: 35px;
  background-color: white;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.08);
  box-sizing: content-box; }

ref:body {
  position: absolute;
  top: 60px;
  bottom: 60px;
  padding: 10px;
  left: 0;
  right: 0;
  overflow-y: auto;
  background-color: #f5f5f5; }

ref:body.lg-top {
  top: 80px; }

.g__btn--grey {
  text-transform: unset;
  border-radius: 0;
  color: #000000;
  text-transform: unset;
  border-radius: 4px;
  border: 1px solid #c4c4c4; }
  .g__btn--grey:hover {
    background: #c4c4c4;
    color: #FFFFFF; }
  .g__btn--grey :hover {
    color: #ffffff;
    background: #c4c4c4; }

.arrows {
  width: 60px;
  height: 72px;
  margin-top: 25px; }

.arrows path {
  stroke: #c4c4c4;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite; }

@keyframes arrow {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */ }

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */ }

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */ }
</style>

<script>
  import ordersService from 'modules/ordersService.js';
  import Phalcon from 'modules/phalcon.js';

  import RoutList from 'components/routes/RoutesList.html';
  import PointList from 'components/points/PointsList.html';

  export default {
    components: {
      RoutList,
      PointList
    },
    methods: {
      newRouteBtnOnClick() {
        let { date_start, intro } = this.get();

        if(intro) intro.exit();

        this.options.root.navigate('/map/routes?date='+date_start);
      },
      showIntro() {
        let { hash, intro } = this.get();
        const self = this;

        if(!intro){
          intro = introJs();
          intro.setOption("skipLabel", "Пропустить обучение");
          intro.setOption("nextLabel", "Дальше");
          intro.setOption("prevLabel", "Назад");
          intro.setOption("doneLabel", "Завершить");
          //console.log($('[data-step=1]'));
          intro.setOptions({
            steps: [{
              element: $(this.refs.main).find('[data-step=1]')[0],
              intro: $(this.refs.main).find('[data-step=1]').attr('data-intro')
            }, {
              element: $(this.refs.main).find('[data-step=2]')[0],
              intro: $(this.refs.main).find('[data-step=2]').attr('data-intro')
            }, {
              element: $(this.refs.main).find('[data-step=3]')[0],
              intro: $(this.refs.main).find('[data-step=3]').attr('data-intro')
            }]
          });

          intro.oncomplete(function(){
            self.newRouteBtnOnClick();
          });

          intro.start();

          this.set({intro});

          localStorage.setItem('map_intro', 'true');
        }
      },
      showDlboyIntro() {
        let { intro } = this.get();
        const self = this;

        if(!intro){
          intro = introJs();
          intro.setOption("skipLabel", "Пропустить обучение");
          intro.setOption("nextLabel", "Дальше");
          intro.setOption("prevLabel", "Назад");
          intro.setOption("doneLabel", "Завершить");
          
          intro.setOptions({
            steps: [{
              element: $(this.refs.modal).find('#personalInfo-component')[0],
              intro: 'Заполните информацию о сотруднике через форму'
            }, {
              element: $(this.refs.modal).find('#groups-component')[0],
              intro: 'Выберите роль вашего сотрудника "Курьер"'
            }, {
              element: $(this.refs.modal).find('#confirm-btn')[0],
              intro: 'Когда все будет готово, нажмите "Сохранить"'
            }]
          });

          intro.start();

          this.set({intro});

          localStorage.setItem('users_new', 'true');
        }
      },
      someTalk() {
        let { isEdit } = this.get();
        isEdit = !isEdit;
        this.set({ isEdit });
      },
      initRouteEditor() {
        this.set({ isEdit: true });

      },
      setRoute(idString) {
        let point_id = idString.split('#')[0];
        let model = idString.split('#')[1];
        let model_id = idString.split('#')[2];
        let application_id = idString.split('#')[3];
        let date = idString.split('#')[4];

        if (model && model_id) {
          if (model == 'users') {
            Phalcon.routes.join({
              users_id: model_id,
              date: date,
              points_ids: [{ point_id, application_id }]
            }).then(answer => {
              if (answer.status == 200) {
                UIkit.notification(`${name} назначен`, { status: "success", pos: "bottom-right" });
                window.mapComponent.refreshDlboysAndRoutes();
              }
            });
          }

          if (model == 'routes') {
            Phalcon.routes.join({
              routes_id: model_id,
              date: date,
              points_ids: [{ point_id, application_id }]
            }).then(answer => {
              if (answer.status == 200) {
                UIkit.notification(`${name} назначен`, { status: "success", pos: "bottom-right" });
                window.mapComponent.refreshDlboysAndRoutes();
              }
            });
          }
        }
      },
      changeTab(type) {
        let { tab } = this.get();

        if (type == 'points') {
          this.set({
            addBtnText: 'Создать новый маршрут'
          });
        } else if (type == 'routes') {
          this.set({
            addBtnText: 'Создать новый маршрут'
          });
        }

        for (var key in tab) {
          tab[key] = false;
        }

        tab[type] = true;
        this.set({ tab });

        window.map.helper.hideAll();
      },
      refresh(date_start, date_end) {
        const self = this;
        const { forceRefresh } = this.get()
        
        if(!forceRefresh) this.set({ loadingPoints: true });

        let { groupBy } = this.get();

        if(!date_start) date_start = Phalcon.getUrlVars()['date_start'];
        if(!date_end) date_end = Phalcon.getUrlVars()['date_end'];

        if(!date_start) date_start = moment().format('YYYY-MM-DD');
        if(!date_end) date_end = moment().format('YYYY-MM-DD');

        Phalcon.points.all({
          date_start,
          date_end,
          group_by: groupBy
        }).then(answer => {
          ////console.log({ answer });
          answer['points'] = answer['points'].sort((a, b) => {
            if (a.index > b.index) return 1;
            if (a.index < b.index) return -1;
            return 0;
          });

          let index = 1;
          answer['points'] = answer['points'].map(p => {
            p.index = index++;
            return p;
          });

          this.set({
            points: answer['points'],
            orders: answer['orders'] || [],
            legals: answer['legals'] || [],
            dlboys: answer['dlboys'] || [],
          });
        }).finally(() => {
          if(!forceRefresh) this.set({ loadingPoints: false });
        });

        this.set({ date_start, date_end });
        this.refreshDlboysAndRoutes();
      },
      refreshRoutes(date_start = moment().format('YYYY-MM-DD'), date_end = moment().format('YYYY-MM-DD')) {
        const self = this;
        const { forceRefresh } = this.get()

        if(!forceRefresh) this.set({ loadingRoutes: true });
        Phalcon.api.routes.all({
          date_start,
          date_end,
          limit: 9999,
        }).then(answer => {
          if (answer.status == 200){
            if (answer.payload && answer.payload.items) {
              routes = answer.payload.items;
              window.routes = routes;
              this.set({ routes });
            } else {
              console.error(answer)
            }
          } else {
            console.error(answer)
          }
        }).finally(() => {
          // setTimeout(() => {
          //   self.set({ loadingRoutes: false });
          // },1000);
          
        })
      },
      refreshDlboysAndRoutes() {
        const self = this;
        const { forceRefresh } = this.get()
        const { date_start, date_end } = window.mapComponent.get();

        if(!forceRefresh) this.set({ loadingRoutes: true });

        Phalcon.users.all({
          byGroupsId: 3,
          date_start,
          date_end,
          without_routes: true
        }).then(answer => {
          if (answer.status == 200) {
            window.mapComponent.set({ dlboys: answer });
          }
        });

        this.refreshRoutes()

        Phalcon.api.routes.all({
          with: 'points,applications.status',
          date_start,
          date_end,
        }).then(answer => {
          if (answer.status == 200){
            if (answer.payload && answer.payload.items) {
              window.mapComponent.set({ routes: answer.payload.items });
            } else {
              console.error(answer)
            }
          } else {
            console.error(answer)
          }
        }).finally(() => {
          setTimeout(() => {
            if(!forceRefresh) self.set({ loadingRoutes: false });
            self.set({ forceRefresh: false });
          },1000);
        });
      },
      clearApplicationsIds() {
        this.set({ applications_ids: [] });
      },
      setApplicationsId($id) {
        let { applications_ids } = this.get();

        if (!applications_ids.includes($id)) {
          applications_ids.push($id);
          this.set({ applications_ids });
        }
      },
      removeRoute() {
        const { date_start, date_end } = this.get()
        this.refresh(date_start, date_end)
      }
    },
    oncreate() {
      const self = this;
      const { hash, routes } = this.get();

      window.mapComponent = this;

      setTimeout(() => {
        window.nav.set({
          breadcrumb: [{
            name: 'Карта',
            link: 'map'
          }],
          actions: ['datepicker'],
          title: ''
        });

        window.nav.listener = window.nav.on('update', ({ changed, current, previous }) => {
          if (changed.date_start || changed.date_end) {
            self.set({ date_start: current.date_start, date_end: current.date_end });
          }
        });

        let intro = localStorage.getItem('map_intro');
        if(!intro && (!routes || routes.length == 0)) setTimeout(() => {
          self.showIntro();
        },1000);
      }, 300);

      setTimeout(() => {
        window.router.orders.on('/orders-is-save', () => {
          self.set({ forceRefresh: true })
          window.map.helper.hideAll()

          let { date_start, date_end } = self.get()
          self.refresh(date_start, date_end)
        }).resolve()

        window.router.orders.updatePageLinks()
      }, 1000)

      if (hash == '#routes') {
        this.set({
          addBtnText: 'Создать новый маршрут'
        });
      } else {
        this.set({
          addBtnText: 'Создать новый маршрут'
        });
      }
    },
    ondestroy() {
      window.nav.clear();
    },
    onupdate({ changed, current }) {
      if (changed.groupBy || changed.date_start || changed.date_end) {
        this.refresh(current.date_start, current.date_end);
      }

      if (changed.points && current.points) {
        this.clearApplicationsIds();
        current.points.map(point => {
          if (point.checked && point.application) {
            //console.log({ point });
            this.setApplicationsId(point.application.id);
          }
        });
      }
    },
    data() {
      return {
        isEdit: false,
        switchMode: {},
        routList: null,
        points: null,
        showOnMap: false,
        hash: window.location.hash == '#' ? '#points' : window.location.hash.split('?')[0],
        addBtnText: 'Создать новый маршрут',
        groupBy: 'legals',
        applications_ids: [],
        type: {
          take: {},
          out: {},
          passive: {}
        },
        tab: {
          points: true,
          routes: false
        },
        loadingPoints: false,
        loadingRoutes: false,
        forceRefresh: false
      }
    }
  }
</script>