<div ref:wrapper class="uk-width uk-grid uk-grid-collapse">
  <div class="uk-width-auto" style="width: 500px">
    {#if session}
      <MapList />
    {/if}
  </div>
  <div class="uk-width-expand">
    <div ref:map></div>
    <MapBaloonTools />
  </div>
</div>


<script>
  import MapHelper from 'modules/mapHelper.js';
  import pathOptimizer from 'modules/pathOptimizer.js';

  import MapList from 'components/map/MapDetail.html';
  import MapBaloonTools from 'components/map/MapBaloonTools.html';

  import { Store } from 'svelte-v2/store.js';

  window.map = {
    store: new Store({
      googleMap: null,
      markers: new Map(),
      polylines: new Map(),
    }),
    helper: MapHelper
  };

  export default {
    components: {
      MapList, MapBaloonTools
    },
    data() {
      return {
        session: null
      }
    },
    ondestroy() {
      window.nav.clear();
      $('#work-zone').addClass('padding');
    },
    oncreate() {
      setTimeout(() => {
        $('#work-zone').removeClass('padding');
        window.map.store.set({
          googleMap: new google.maps.Map(this.refs.map, {
            center: { lat: 55.752107, lng: 37.622345 },
            zoom: 10,
            maxZoom: 18,
            disableDefaultUI: true
          })
        });

        window.map.helper.init(window.map.store.get());
        window.map.helper.pathOptimizer = pathOptimizer;
      },0);
    },
  }
</script>

<style>
  ref:wrapper {
    height: 100%;
  }

  ref:map {
    width: 100%;
    height: 100%;
  }
</style>