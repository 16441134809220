<div id="modal-example" uk-modal>
  <div class="uk-modal-dialog uk-modal-body logo">
    <div class="wrapper">
      <h2 class="uk-modal-title logo__head"><span><img class="fondo-logo" src="/svg/redLogo.svg"></span>
        REX: <span>routes expert</span></h2>

      <h4 class="uk-text-center uk-width-1-1 uk-margin-remove">Введите новый пароль</h4>
      <ul class="uk-list auth__form">
        <li><input on:input="isParity()" bind:value="changes.password1" class="uk-width-1-1 uk-search-input"  placeholder="Пароль" type="password"></li>
        <hr>
        <li>
        {#if changes.password1.length > 0}
          <input on:input="isParity()" bind:value="changes.password2" class="uk-width-1-1 uk-search-input"  placeholder="Повторите пароль" type="password">
        {:else}
          <input disabled class="uk-width-1-1 uk-search-input"  placeholder="Повторите пароль" type="password">
        {/if}
        </li>

        <hr>
      </ul>

        {#if isDisabled}
          <div class="uk-flex uk-flex-right">
            <button disabled class="g__btn g__btn--yes uk-button uk-button-small">Подтвердить</button>
          </div>
        {:else}
          <div class="uk-flex uk-flex-right">
            <button on:click="confirmation()" class="g__btn g__btn--yes uk-button uk-button-small">Подтвердить</button>
          </div>
        {/if}

    </div>
  </div>
</div>

<script>
  import Phalcon from 'modules/phalcon.js';
  
  export default {
    methods:{
      isParity(){
        let {changes,isDisabled} = this.get();
        if (changes.password1 === changes.password2 && changes.password1.length !== 0){
          isDisabled = false;
        } else {
          isDisabled = true;
        }
        this.set({isDisabled});
      },
      confirmation(){
        const self = this;
        const {changes} = this.get();
        const usc = this.getUrlVars()['usc'];
        const uidsc = this.getUrlVars()['uidsc'];
        
        if(usc && uidsc){
          grecaptcha.ready(function () {
            grecaptcha.execute($('#recaptcha_v3_key').val(), {
              action: 'resetPassword'
            }).then(function (recaptcha_token) {
              Phalcon.confirm.passwordChange({
                recaptcha_response: recaptcha_token,
                usc: usc,
                uidsc: uidsc,
                password: changes.password1
              }).then(answer => {
                if(answer && answer.status == 200){
                  UIkit.notification(`Пароль изменен`, { status: "success", pos: "bottom-right" });
                  self.destroy();
                } else {
                  UIkit.notification(`Простите, вы не можете сменить пароль, ссылка недействительна`, { status: "danger", pos: "bottom-right" });
                }
              })
            });
          });
        } else {
          UIkit.notification(`Простите, вы не можете сменить пароль, ссылка недействительна`, { status: "danger", pos: "bottom-right" });
        }
        // this.destroy();
      },
      getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
          vars[key] = value;
        });
        return vars;
      }

    },
    data(){
      return {
        isDisabled: true,
        changes:{
          password1:"",
          password2:"",
        },
        checked: false,
      }
    },
    ondestroy() {
      history.pushState({}, "", `/auth`);
      window.dispatchEvent(new Event('popstate'))
    },
    oncreate() {
      const self = this;

      UIkit.modal(`#modal-example`,
        {
          escClose: false,
          bgClose: false
        });
      UIkit.modal(`#modal-example`).show();
      $(`#modal-example`).on('hidden', function () {
        self.destroy();
      });
    },
    onupdate({ changed, current, previous }){
      let {changes, checked, isDisabled} = this.get();
      if(changed.changes === true) {
        if (changes.password1.length === 0) {
          changes.password2 = "";
          checked = false;
          isDisabled = true;
        }
        this.set({changes, checked, isDisabled});
      }
    },
  }
</script>

<style>.wrapper {
  user-select: none;
  color: #000;
  font-size: 18px;
  font-weight: bold; }

h4 {
  font-size: 18px;
  font-weight: bold; }

.g__btn--yes {
  line-height: 31px;
  font-size: 18px;
  border-radius: 0;
  width: 150px;
  border: 2px solid #e30613;
  margin: 0 55px; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }
  .g__btn--yes:disabled {
    pointer-events: none;
    opacity: 0.5; }

.logo {
  width: 410px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 25px rgba(0, 0, 0, 0.5); }
  .logo__head {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    margin-top: 25px;
    text-align: center; }
    .logo__head span {
      color: #E30613; }

input:disabled {
  background: #ffffff;
  pointer-events: none;
  opacity: 0.5; }

.auth {
  width: 410px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 25px rgba(0, 0, 0, 0.5); }
  .auth__head {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    margin-top: 25px;
    text-align: center; }
    .auth__head span {
      color: #E30613; }
  .auth__container {
    padding: 20px 20px 0px 20px; }
  .auth__form {
    color: #c4c4c4;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    padding: 0px 55px 20px 55px; }
    .auth__form li {
      padding: 0px 20px;
      margin-top: 30px; }
    .auth__form hr {
      margin: 0 5px;
      border: 1px solid #c4c4c4; }
</style>

