<div id="modal-example" uk-modal>
  <div class="uk-modal-dialog uk-modal-body logo">
    <div class="wrapper">
      <h2 class="uk-modal-title logo__head"><span><img class="fondo-logo" src="/svg/redLogo.svg"></span>
        REX: <span>routes expert</span></h2>

      {#if stage === 1}
        <div class="uk-text-center">Подтвердите регистарцию</div>
        <div class="uk-flex uk-flex-center">
          <div class=""><input bind:checked="checked" class="uk-checkbox" type="checkbox"></div>
          <div class="">Я не робот</div>
        </div>
        <div class="uk-flex uk-flex-center">
          <button disabled class="g__btn g__btn--yes uk-button uk-button-small uk-position-bottom-center">Подтвердить</button>
        </div>
      {/if}
      <!--Loader-->

      {#if checked && stage === 1 }
        <div class="uk-flex uk-flex-center">
          <button on:click="check()" class="g__btn g__btn--yes uk-button uk-button-small uk-position-bottom-center">Подтвердить</button>
        </div>
      {/if}

      {#if stage === 2}
        <div class=" uk-text-center">Выполняется настройка системы.</div>
        <div class=" uk-text-center">Это займет несколько секунд</div>

        <div class="loader">
          <div class="loading">
            <span class="l l1"></span>
            <span class="l l2"></span>
            <span class="l l3"></span>
            <span class="l l4"></span>
            <span class="l l5"></span>
            <span class="l l6"></span>
            <span class="l l7"></span>
            <span class="l l8"></span>
            <span class="l l9"></span>
          </div>
        </div>

      {:elseif stage === 3}
        <div class=" uk-text-center">Ваш email подтвержден и конфигурация системы успешно настроена.</div>
        <div class="uk-flex uk-flex-center">
          <button on:click="systemLogin()" class="g__btn g__btn--yes uk-button uk-button-small uk-position-bottom-center">Войти в систему</button>
        </div>
      {/if}

    </div>
  </div>
</div>

<script>
  import Phalcon from 'modules/phalcon.js';

  export default {
    methods: {
      systemLogin() {
        this.destroy();
      },
      stageUp() {
        const self = this;
        let { stage } = this.get();
        stage = 2;
        this.set({ stage });
      },
      check() {
        const { recaptcha_token } = this.get();
        const secret = this.getUrlVars()['secret'];
        
        if(recaptcha_token && secret){
          this.stageUp();
          Phalcon.confirm.check({
            recaptcha_response: recaptcha_token,
            secret: secret
          }).then(answer => {
            if(answer && answer.status == 200){
              this.set({ stage:3 });
            } else {
              UIkit.notification(`Произошла ошибка`, { status: "danger", pos: "bottom-right" });
            }
          })
        }
        
        if(!recaptcha_token){
          UIkit.notification(`Вы не прошли проверку на робота`, { status: "danger", pos: "bottom-right" });
        }

        if(!secret){
          UIkit.notification(`Простите, вы не можете подтвердить свой аккаунт`, { status: "danger", pos: "bottom-right" });
        }
      },
      getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
          vars[key] = value;
        });
        return vars;
      }
    },
    data() {
      return {
        stage: 1,
        checked: false,
        recaptcha_token: null
      }
    },
    ondestroy() {
      history.pushState({}, "", `/auth`);
      window.dispatchEvent(new Event('popstate'))
    },
    oncreate() {
      const self = this;

      grecaptcha.ready(function () {
        grecaptcha.execute($('#recaptcha_v3_key').val(), {
          action: 'confirm'
        }).then(function (recaptcha_token) {
          self.set({ recaptcha_token });
        });
      });

      UIkit.modal(`#modal-example`,
        {
          escClose: false,
          bgClose: false
        });
      UIkit.modal(`#modal-example`).show();
      $(`#modal-example`).on('hidden', function () {
        self.destroy();
      });
    }
  }
</script>

<style>.wrapper {
  user-select: none;
  color: #000;
  font-size: 18px;
  font-weight: bold; }

input {
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  border: 2px solid #e30613;
  border-radius: 4px;
  text-decoration: none; }

.g__btn--yes {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, -120%);
  line-height: 31px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 0;
  width: 75%;
  border: 2px solid #e30613; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }
  .g__btn--yes:disabled {
    pointer-events: none;
    opacity: 0.5; }

.logo {
  width: 410px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 25px rgba(0, 0, 0, 0.5); }
  .logo__head {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    margin-top: 25px;
    text-align: center; }
    .logo__head span {
      color: #E30613; }

.loader {
  background-color: #E30613; }

.loading {
  position: absolute;
  left: 50%;
  top: 72%;
  transform: translate(-50%, -50%);
  width: 136px;
  height: 136px; }

.loading .l {
  position: absolute;
  width: 33.3%;
  height: 33.3%;
  background-color: #E30613;
  animation: loading-l 1.5s infinite ease-in-out; }

.loading .l.l1 {
  animation-delay: .1s; }

.loading .l.l2 {
  animation-delay: .2s; }

.loading .l.l4 {
  animation-delay: .2s; }

.loading .l.l3 {
  animation-delay: .3s; }

.loading .l.l5 {
  animation-delay: .3s; }

.loading .l.l7 {
  animation-delay: .3s; }

.loading .l.l6 {
  animation-delay: .4s; }

.loading .l.l8 {
  animation-delay: .4s; }

.loading .l.l9 {
  animation-delay: .5s; }

.loading .l1,
.loading .l2,
.loading .l3 {
  top: 0; }

.loading .l4,
.loading .l5,
.loading .l6 {
  top: 33.3%; }

.loading .l7,
.loading .l8,
.loading .l9 {
  top: 66.6%; }

.loading .l1,
.loading .l4,
.loading .l7 {
  left: 0; }

.loading .l2,
.loading .l5,
.loading .l8 {
  left: 33.3%; }

.loading .l3,
.loading .l6,
.loading .l9 {
  left: 66.6%; }

@keyframes loading-l {
  0% {
    transform: scale(0.95, 0.95); }
  50% {
    transform: scale(0.05, 0.05); }
  100% {
    transform: scale(0.95, 0.95); } }
</style>




<!-- <script src="https://www.google.com/recaptcha/api.js?render=6LfjrKQUAAAAALi55qO2PEwUSQ_0pqX-Vpgw1TLJ"></script>
<style>
    .lds-hourglass {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }

    .lds-hourglass:after {
        content: " ";
        display: block;
        border-radius: 50%;
        width: 0;
        height: 0;
        margin: 6px;
        box-sizing: border-box;
        border: 26px solid #c70017;
        border-color: #c70017 transparent #c70017 transparent;
        animation: lds-hourglass 1.2s infinite;
    }

    .hidden {
        display: none;
    }

    @keyframes lds-hourglass {
        0% {
            transform: rotate(0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        50% {
            transform: rotate(900deg);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        100% {
            transform: rotate(1800deg);
        }
    }
</style>

<div class="uk-container uk-width-1-2" id="confirm-page">
    <legend class="uk-legend">Подтвердите что вы не робот</legend>

    <Input type="hidden" id="recaptcha_response">
    <p id="Button-box" uk-margin>
        <Button class="uk-Button uk-Button-default" onclick="check()">Подтвердить</Button>
    </p>
</div>

<div class="uk-flex uk-flex-center uk-margin hidden" id="loader">
    <div class="uk-width-3-4" uk-grid>
        <legend class="uk-legend uk-width-1-2">Идет настройка вашей системы</legend>
        <div class="lds-hourglass"></div>
    </div>
</div>

<div class="uk-flex uk-flex-center uk-margin hidden" id="success">
    <div class="uk-width-1-2" uk-grid>
        <legend class="uk-legend">Готово! Можете перейти по ссылке <a>http://fondo-phalcon.lc</a> и авторизоваться</legend>
    </div>
</div>

<script>
    grecaptcha.ready(function() {
        grecaptcha.execute('6LfjrKQUAAAAALi55qO2PEwUSQ_0pqX-Vpgw1TLJ', {
            action: 'contact'
        }).then(function(token) {
            var recaptchaResponse = document.getElementById('recaptcha_response');
            recaptchaResponse.value = token;
        });
    });

    function check() {
        document.getElementById('confirm-page').classList.add('hidden');
        document.getElementById('loader').classList.remove('hidden');
        fetch('/confirm/check', {
            method: 'POST',
            body: JSON.stringify({
                recaptcha_response: document.getElementById('recaptcha_response').value,
                secret: getUrlVars()['secret']
            })
        }).then(answer => {
            ////console.log("ANSWER", answer);
            if(answer.status == 200){
                document.getElementById('confirm-page').classList.add('hidden');
                document.getElementById('loader').classList.add('hidden');
                document.getElementById('success').classList.remove('hidden');
            }
        })
    }

    function getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
            vars[key] = value;
        });
        return vars;
    }
</script> -->