<div ref:point on:mouseenter="accent()" on:mouseleave="refreshAccent()" on:click="fire('click', point)"
  class="uk-tile uk-tile-default uk-width-expand uk-position-relative {point ? point.type : ''} point"
  class:disabled="point && point.application && point.application.not_in_period"
  data-point-id="{point ? point.id : -1}" on:focusout="fire('focusout')"
  class:active="active">
  {#if loading}
    <div class="uk-position-absolute uk-position-top-left uk-height-1-1 uk-width uk-flex uk-flex-middle uk-flex-center" style="background-color: rgba(255,255,255,0.3); z-index: 999; pointer-events: none !important;">
      <div uk-spinner="ratio: 0.7"></div>
    </div>
  {/if}
  <div class="uk-grid-collapse uk-position-relative" uk-grid>
    {#if point && point.application && point.application.status && !hideOrdersInfo}
    <div class="status-pill" style="background:{point.application.status.color}33; color:{point.application.status.color};">
      {point.application.status.name}
    </div>
    {/if}
    {#if point}
      <div class="uk-width-auto">
        <div class="uk-margin-small-right uk-margin-small-left circle uk-badge uk-badge-notification uk-panel-badge {point.type ? point.type : 'nd'}">{point.index}</div>
      </div>
      <div class="uk-width-expand">
        <input class="uk-input uk-margin-remove text-input uk-width-4-5" bind:value="changeAddress" on:focusout="changePointAddress()" ref:changeAddressInput disabled="{!isChangedAddress}" type="text">
      </div>
      {#if actions && actions.includes('remove')}
      <div ref:close class="uk-width-auto uk-tile-muted uk-flex uk-flex-center uk-text-center" on:click="_remove()">
        <i class="uk-margin-auto-vertical" type="button" uk-icon="icon: close"></i>
      </div>
      {/if}

      <!-- {#if actions && actions.includes('copy') && point.type == 'dl'}
        <button class="uk-text-right fake-btn" type="button" uk-icon="icon: copy" on:click="fakeClone(point.id, event)"></button>
      {/if} -->
      {#if !hideOrdersInfo}
        {#if point.application}
        <div class="uk-width uk-grid-collapse uk-margin-small-left uk-margin-small-right uk-margin-small-top uk-text-small" uk-grid>

          <div class="uk-width-auto">
            <span uk-icon="icon: clock; ratio: 0.8"></span>
          </div>
          <div class="uk-width-auto uk-margin-small-left">
            {point.application.time_start ? point.application.time_start : format(point.application.date_start)} - {point.application.time_end ? point.application.time_end : format(point.application.date_end)}
          </div>
          
          {#if point.application.client}
          <div class="uk-width-auto uk-margin-medium-left">
            <span uk-icon="icon: bookmark; ratio: 0.8"></span>
          </div>
          <div class="uk-width-expand uk-margin-small-left">
            {point.application.client.name}
          </div>
          {/if}
          
          <!-- <div class="uk-width-auto">
            <span uk-icon="icon: folder; ratio: 0.8"></span>
          </div>
          <div class="uk-width-expand uk-margin-small-left">
            Маршрут
          </div> -->
        </div>
        {/if}
        {#if point.tags}
        <div class="uk-width uk-grid-collapse uk-margin-small-left uk-margin-small-right uk-margin-small-top" uk-grid style="font-size: 11px">
          <!-- {#if point.orders}
          <div class="tag active uk-inline">
            <button class="uk-button uk-button-link" on:click|stopPropagation type="button" style="font-size: 11px">{point.orders.length} Заказа</button>
            <div class="uk-padding-remove" uk-dropdown="mode: click">
              <ul class="uk-nav uk-dropdown-nav uk-margin-top uk-margin-bottom">
                {#each point.orders as order}
                <li><a href="#">Заказ {order}</a></li>
                {/each}
              </ul>
            </div>
          </div>
          {/if} -->
          <div class="uk-width-expand uk-flex uk-flex-middle">
            {#each point.tags as tag}
              <div class="tag">{tag}</div>
            {/each}
          </div>

          {#if point.route}
          <div class="uk-width-auto">
            Маршрут: <a class="uk-text-muted uk-text-small">{point.route.name}</a>
          </div>
          {/if}

          {#if point && point.application && point.application.orders_id}
            <a class="uk-link" on:click="showOrder(point.application.orders_id)">Перейти в заказ</a>
          {/if}

        </div>
        {/if}
      {/if}
    {:else}

        <div class="uk-width-auto">
          <div class="uk-margin-small-right uk-margin-small-left circle uk-badge uk-badge-notification uk-panel-badge nd">{index}</div>
        </div>

        <div class="uk-width-expand">
          <input autofocus ref:addressInput on:keypress="enterKey(event)" class="uk-input uk-margin-remove text-input uk-width-4-5" bind:value="address" placeholder="Введите адрес" type="text">
        </div>

        <div class="uk-width-auto uk-tile-muted uk-flex uk-flex-center uk-text-center">
          <i on:click="addNewPoint()" class="uk-margin-auto-vertical" type="button" uk-icon="icon: check"></i>
        </div>
    {/if}
  </div>

  {#if point && routeType === `tk`}
  <div ref:dlContainer class="dl-container" on:click="checkFakePointId(this)" data-point-id="{point.id}">
<!--    on:click="tmpChecker()" -->
  </div>
  {/if}
  
  
</div>
<script>
  import Phalcon from 'modules/phalcon.js'
  import FakeWaypoint from 'components/points/PointsDuplicateCard.html';

  export default {
    ondestroy() {
      $(this.refs.point).parents('.list-item').remove();
    },
    oncreate() {
      const self = this;
      const { editStore, point, routeType, mapHelper, showOnMap, withoutInfo } = this.get();

      if (point && showOnMap) {
        window.map.helper.showOnMap(point, withoutInfo);

        if (editStore) {
          const { fakePoints, pointComponents } = editStore.get();
          pointComponents.set(point.id, this);
          var sortable = $(this.refs.dlContainer);

          if (point && routeType == 'tk') Sortable.create(this.refs.dlContainer, {
            group: 'dl',
            multiDrag: true,
            selectedClass: "selected",
            animation: 150,

            onAdd: function (event) {
              setTimeout(function () {
                self.refreshEdges(event.target);
              }, 50);
            },

            onRemove: function (event) {
              self.refreshEdges(event.target);
            },
          });

          // $(this.refs.dlContainer).on('added', function (event) {
          //   setTimeout(function () { 
          //     self.refreshEdges(event.target); 
          //   }, 50);

          // });

          // $(this.refs.dlContainer).on('removed', function (event) {
          //   self.refreshEdges(event.target);
          // });

        }
      }

      let autocomplete = new google.maps.places.Autocomplete(this.refs.addressInput);
      let changeAddressAutocomplete = new google.maps.places.Autocomplete(this.refs.changeAddressInput);

      autocomplete.addListener('place_changed', function() {
        let place = autocomplete.getPlace();

        if(place && place.formatted_address){
          self.set({ address: place.formatted_address });
        }
      });

      changeAddressAutocomplete.addListener('place_changed', async function() {
        self.set({ loading: true })

        let { point } = self.get()
        let place = changeAddressAutocomplete.getPlace();
        console.log('changeAddressAutocomplete', {place})
        if(place && place.formatted_address && place.geometry && place.geometry.location) {
          point.address = place.formatted_address;
          point.lat = place.geometry.location.lat().toString();
          point.lon = place.geometry.location.lng().toString();

          delete point.created_at
          delete point.updated_at
          delete point.archive_at
          delete point.deleted_at

          let answer = await Phalcon.go.points.change(point)

          if(answer.status == 200){
            self.set({ point, changeAddress: point.address })
            window.map.helper.hideOnMap(point)
            window.map.helper.showOnMap(point, false, false)
          }
        } else {
          self.set({ changeAddress: point.address })
        }

        self.set({ loading: false })
      });

      if(point && point.address){
        this.set({ changeAddress: point.address })
      }
    },
    helpers: {
      format(date) {
        return moment(date).format('HH:mm');
      }
    },

    methods: {
      enterKey(event) {
        const self = this;
        if (event.key === "Enter") {
          this.addNewPoint();
        }
      },
      showOrder(orders_id) {
        window.router.orders.navigate('/orders/' + orders_id)
      },
      addNewPoint() {
        const self = this;
        self.set({ loading: true });
        
        setTimeout(() => {
          let { address } = this.get();

        
          self.fire('addNewPoint', address);
          setTimeout(() => {
            self.set({ loading: false });
          },600)
          
        },300);
      },
      changePointAddress() {
        console.log('gsagas')
        const { point, loading } = this.get()
        
        if(!loading) this.set({changeAddress: point.address})
      },
      fakeClone(id, e) {
        const self = this,
          { editStore, point } = this.get(),
          { fakePoints } = editStore.get();
        let { points } = editStore.get(),
          parent = $(this.refs.point).parents('.dl-container'),
          tkIds = $(parent).attr('data-point-id') ? [$(parent).attr('data-point-id')] : $(parent).attr('data-tk-points').toString().split(','),
          parentFake = $(parent).hasClass('fake');

        if (!parentFake) {
          var fakeArr = fakePoints.get(id);
          if (fakeArr) {
            fakeArr.push(this._addFakeDl(id, parent, point.index));
          } else {
            fakeArr = [this._addFakeDl(id, parent, point.index)];
          }
          fakePoints.set(id, fakeArr);
          this.refreshEdges(parent);
        }

        editStore.set({
          buffer: {
            el: this.refs.point,
            data: point,
            tkIds: tkIds
          }
        });

        $(this.refs.point).parent().addClass('fake-point-on-cursor');
        $('.fake-point-on-cursor').css('left', (e.pageX + 20) + 'px');
        $('.fake-point-on-cursor').css('top', e.pageY + 'px');
        this.fire('fake', { id, parent, tkIds });
      },

      addFakePoint(id) {
        const self = this,
          { editStore } = this.get(),
          { fakePoints, buffer } = editStore.get();
        let { point } = this.get();

        var fakeArr = fakePoints.get(id);
        if (fakeArr) {
          fakeArr.push(this._addFakeTk());
          fakeArr.push(this._addFakeDl(id, this.refs.dlContainer, point.index));
        } else {
          fakeArr = [this._addFakeTk(), this._addFakeDl(id, this.refs.dlContainer, point.index)];
        }
        fakeArr.map(fakeComponent => {
          if (fakeComponent) {
            let { type } = fakeComponent.get();
            if (type == 'tk') fakeComponent.checkDl();
          }
        });
        fakePoints.set(id, fakeArr);

        this.refreshEdges(this.refs.dlContainer);
      },

      _addFakeDl(id, el, index) {
        const self = this,
          { editStore } = this.get();

        let fake = document.createElement("li");
        fake.setAttribute('data-point-id', id);
        fake.setAttribute('is-fake', 1);

        $(el).append(fake);
        const fakeComponent = new FakeWaypoint({
          target: fake,
          data: {
            type: 'dl',
            pointIds: [id],
            index: index,
            address: 'Несколько заборов',
            editStore
          }
        });
        fakeComponent.on('destroy', () => {
          self.refreshEdges(el);
        });

        return fakeComponent;
      },

      _addFakeTk() {
        const self = this,
          { editStore } = this.get(),
          { buffer } = editStore.get();
        let { point } = this.get(),
          pointIds = [point.id].concat(buffer.tkIds).sort((a, b) => a - b);
        pointIds = pointIds.map(item => { return parseInt(item) });

        var fake, fakeComponent;
        $('.fake-point').each(function (index, el) {
          if ($(el).attr('data-tk-points') == pointIds.join(',')) fake = el;
        });

        ////console.log(fake);
        if (!fake) {
          fake = document.createElement("li");
          fake.setAttribute('is-fake', 1);
          $(buffer.el).parents('.dl-container').parent().parent().parent().append(fake);
          fakeComponent = new FakeWaypoint({
            target: fake,
            data: {
              type: 'tk',
              pointIds: pointIds,
              address: 'Несколько заборов',
              editStore
            }
          })
        }

        $(fake).find('.dl-container').append(
          $(buffer.el).parent()
        );

        return fakeComponent;
      },

      _remove() {
        const { point, editStore } = this.get();
        const { Phalcon } = this.options.root.get();
        let { points } = editStore.get();

        window.map.helper.hideOnMap(point);

        Phalcon.points.remove(point.id).then(answer => {
          if (answer.status == 200) {
            points = points.filter(p => p.id != point.id);
            points = points.map((p, i) => {
              p.index = i + 1;
              return p;
            })
            editStore.set({ points });
          }
        })
      },

      checkFakePointId(target) {
        const { editStore } = this.get();
        let { tempFakePointId, tempFakeParent, tempTkIds, fakePoints } = editStore.get();
        let parent = $(this.refs.point).parents('.dl-container');
        let tkIds = $(target).attr('data-point-id') ? [$(target).attr('data-point-id')] : $(paretargetnt).attr('data-tk-points').toString().split(',');

        let haveThisTk = false;
        if (tkIds && tempTkIds) {
          tkIds.map(id => {
            if (tempTkIds.indexOf(id) != -1) haveThisTk = true;
          })
        }

        if (tempFakePointId && !$(target).is(tempFakeParent) && !haveThisTk) {
          this.addFakePoint(tempFakePointId);
          $('.fake-point-on-cursor').removeClass('fake-point-on-cursor');

          tempFakePointId = null; tempFakeParent = null; tempTkIds = null;
          editStore.set({ tempFakePointId, tempFakeParent, tempTkIds })
        } else if ($(target).is(tempFakeParent)) {
          $('.fake-point-on-cursor').removeClass('fake-point-on-cursor');

          fakePoints.get(tempFakePointId).map(fakeComponent => {
            if (fakeComponent) {
              fakeComponent.beforeDestroy();
            }
          });
          fakePoints.delete(tempFakePointId);

          tempFakePointId = null; tempFakeParent = null; tempTkIds = null;
          editStore.set({ tempFakePointId, tempFakeParent, tempTkIds })
        } else if (haveThisTk) {
          $('.fake-point-on-cursor').removeClass('fake-point-on-cursor');

          tempFakePointId = null; tempFakeParent = null; tempTkIds = null;
          editStore.set({ tempFakePointId, tempFakeParent, tempTkIds })
        }
      },

      refreshEdges(el, removeId) {
        const id = $(el).attr('data-point-id'),
          { editStore } = this.get(),
          { points } = editStore.get();

        let point = points.filter(point => point.id == id)[0],
          elms = $(el).find("li");

          //console.log({elms});
        if (point) {
          if (point.dl) {
            point.dl.map(p => {
              window.map.helper.hidePolyline(point.id + '-' + p.id);
            })
          }

          point.dl = [];
          var pointsIds = [];
          elms.each((index, item) => {
            let dlId = parseInt($(item).attr('data-point-id'));
            let dl = points.filter(point => point.id == dlId)[0];
            pointsIds.push(dlId);

            if(!point.dl.includes(dl)) {
              point.dl.push(dl);
            } else {
              $(item).remove();
            }
          });

          points.map(tkPoint => {
            if (tkPoint.type == 'tk' && tkPoint.id == point.id) {
              points.map(dlPoint => {
                if (dlPoint.type == 'dl') {
                  if (pointsIds.indexOf(dlPoint.id) != -1) {
                    window.map.helper.setPolyline(tkPoint.id + '-' + dlPoint.id, [{
                      lat: parseFloat(tkPoint.lat),
                      lng: parseFloat(tkPoint.lon)
                    }, {
                      lat: parseFloat(dlPoint.lat),
                      lng: parseFloat(dlPoint.lon)
                    }]);
                  }
                }
              });
            }
          });
        }
      },

      apiPoint() {
        const apiKey = "AIzaSyAvmvgCSleLj0_RBUVyOcqMglX7qs19yyk";
        const { address } = this.get();
        if(address != ''){
          fetch(`https://maps.google.com/maps/api/geocode/json?address=${address}&key=${apiKey}`).then(responce => {
            if (responce.ok) {
              return responce.json();
            } else {
              throw new Error("answer isn't ok!")
            }
          }).then(answer => {
            this.sendPoint(answer.results);
          }).catch((error) => {
              ////console.log("fetch went wrong!", error);
          })
        }
      },

      sendPoint(data) {
        ////console.log('data', data);
        if (!data.length) {
          UIkit.notification({
            message: 'Адрес не найден!',
            status: 'danger',
            pos: 'bottom-right'
          });
          this.fire("incorrect", {});
          this.destroy();
          return;
        }

        let { pointCreate } = this.get();
        pointCreate.address = data[0].formatted_address;
        pointCreate.lat = data[0].geometry.location.lat;
        pointCreate.lon = data[0].geometry.location.lng;

        this.fire("new", pointCreate);
      },

      accent() {
        const { point } = this.get();
        if (point) window.map.helper.accent(point);
      },

      refreshAccent() {
        const { point } = this.get();
        if (point) window.map.helper.refreshAccent(point);
      }
    },
    data() {
      return {
        pointCreate: {
          address: "",
          lat: null,
          lon: null,
        },
        isDraggable: false,
        showOnMap: true,
        withoutInfo: false,
        address: null,
        buttonCondition: "check",
        index: 1,
        active: false,
        loading: false,
        changeAddress: '',
        isChangedAddress: false
      }
    }
  }
</script>
<style>
  .tag {
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    color: #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px!important;
    padding: 0 3px;
  }
  button {
    text-transform: none !important;
  }

  /* .tag.active {
    border: 1px solid rgb(134, 134, 134);
    color: rgb(134, 134, 134);
  } */

  .tag.active button {
    color: #C4C4C4;
  } 

  .uk-button-link {
    
  }

  .fake-btn {
    display: none;
  }

  .delete-btn {
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .circle {
    font-size: 14px;
    text-align: center;
    color: white;
  }
  .circle.nd {
    background-color: grey;
  }

  .circle.dl {
    background-color: red;
  }

  .circle.tk {
    background-color: green;
  }

  .active {
    border: 3px solid rgba(0,0,255,0.1) !important;
  }
  
  .active .circle.tk {
    background-color: blue;
  }

  i[uk-icon="icon: check"]{
    margin: auto 0;
    position: absolute;
    top: -5px;
    right: -5px;
    width: 35px;
    height: calc(100% + 10px);
    cursor: pointer;
    background: #F2F2F2;
    border-left: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 0px 5px 5px 0px;
    padding: 8px 5px 5px 5px;
  }

  ref:close {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 35px;
    height: calc(100% + 10px);
    cursor: pointer;

    background: #F2F2F2;
    border-left: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 0px 5px 5px 0px;
  }

  ref:point {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
  }

  ref:point:hover {
    background-color: rgba(255, 217, 0, 0.1)
  }

  ref:dlContainer {
    position: relative;
    bottom: -5px; left: -5px;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    padding-bottom: 40px;
    min-height: 0px !important;
    background-color: #EEEEEE;
  }

  .text-input {
    background: transparent !important;
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    border-color: white !important;
    height: auto !important;
    padding: 0;
    margin: 0;
  }

  .status-pill {
    position: absolute;
    top: 0; right: 0;
    border-radius: 6px;
    font-size: 10px;
    padding: 1px 5px;
  }

  input:disabled {
    pointer-events: none !important
  }
</style>