<div class="backdrop uk-flex uk-flex-center uk-flex-middle">
  <slot>
    <p>Скоро...</p>
  </slot>
</div>

<style>
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(2px);
    z-index: 999;
    pointer-events: unset !important;
    background-color: rgba(0,0,0,0.05);
    font-size: 2rem;
  }
</style>