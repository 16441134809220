<div ref:point data-tk-points="{pointIds.join(',')}"
class="uk-tile uk-tile-default uk-width fake-point uk-animation-shake {type} {isVisible || type == 'dl' ? 'is-visible' : ''} {type == 'dl' ? 'uk-sortable-nodrag' : ''}">
    <div class="uk-flex uk-flex-middle">
      {#if type == 'tk'}
        {address}
      {/if}
      {#each pointIds as id}
        <div class="circle {type}">{showIndex(editStore.get(), id)}</div>
      {/each}
      {#if type == 'dl'}
        {address}
      {/if}
    </div>
  
    {#if type == 'tk'}
    <div ref:dlContainer class="dl-container fake" data-tk-points="{pointIds.join(',')}">

    </div>
    {/if}
</div>

  <script>
    export default {
      oncreate(){
        const self = this;
        const { editStore, type } = this.get();
        if(editStore) {
          var { fakePoints } = editStore.get();
          const listener = editStore.on('state', function({changed, current}){
            if(changed.tempFakePointId){
              if(!current.tempFakePointId){
                self.set({isVisible: true});
                listener.cancel();
              }
            }
          });
        }

        if(type == 'tk') Sortable.create(this.refs.dlContainer, {
          group: 'dl',
          animation: 100,
            
          onAdd: function (event) {
            ////console.log(event);
            setTimeout(function () { 
              let addedId = event.item.getAttribute('data-point-id');
              addedId = parseInt(addedId);

              if(addedId && fakePoints){
                let fakeArr = fakePoints.get(addedId);
                if(fakeArr) {
                  fakeArr.concat(self.addFakePoints(addedId));
                } else {
                  fakeArr = self.addFakePoints(addedId);
                }
                fakePoints.set(addedId, fakeArr);
              }
            }, 50);
          },

          onRemove: function (event) {
            ////console.log(event);
            let removeId = event.item.getAttribute('data-point-id');
            removeId = parseInt(removeId);

            if(removeId && fakePoints){
              fakePoints.get(removeId).map(fakeComponent => {
                if(fakeComponent){
                  let { type } = fakeComponent.get();
                  if(type == 'dl') fakeComponent.beforeDestroy();
                }
              })
              fakePoints.delete(removeId);
            }

            self.checkDl();
          },
        });

        // $(this.refs.dlContainer).on('added', function(event) {
        //   setTimeout(function () { 
        //     let addedId = event.originalEvent.detail[1].getAttribute('data-point-id');
        //     addedId = parseInt(addedId);

        //     if(addedId && fakePoints){
        //       let fakeArr = fakePoints.get(addedId);
        //       if(fakeArr) {
        //         fakeArr.concat(self.addFakePoints(addedId));
        //       } else {
        //         fakeArr = self.addFakePoints(addedId);
        //       }
        //       fakePoints.set(addedId, fakeArr);
        //     }
        //   }, 50);
        // });

        // $(this.refs.dlContainer).on('removed', function(event) {
        //   let removeId = event.originalEvent.detail[1].getAttribute('data-point-id');
        //   removeId = parseInt(removeId);

        //   if(removeId && fakePoints){
        //     fakePoints.get(removeId).map(fakeComponent => {
        //       if(fakeComponent){
        //         let { type } = fakeComponent.get();
        //         if(type == 'dl') fakeComponent.beforeDestroy();
        //       }
        //     })
        //     fakePoints.delete(removeId);
        //   }

        //   self.checkDl();
        // });
      },
      ondestroy(){
        $(this.refs.point).parent().remove();
      },
      helpers: {
        showIndex({ points }, pointId){
          let index = 0;
          points.map(point => {
            if(point.id == pointId) index = point.index;
          })

          return index;
        }
      },
      methods:{
        beforeDestroy(){
          var self = this;
          $(this.refs.point).removeClass('uk-animation-shake');
          $(this.refs.point).addClass('uk-animation-slide-left uk-animation-reverse');
          setTimeout(()=> {
            self.destroy();
          }, 300)
        },
        addFakePoints(addedId) {
          const { pointIds, editStore } = this.get(),
                { pointComponents } = editStore.get();
          let fakeComponents = [];

          $('.point').each(function(index, el){
            var currentId = parseInt($(el).attr('data-point-id'));
            if(pointIds.indexOf(currentId) != -1){
              fakeComponents.push(pointComponents.get(currentId)._addFakeDl(addedId, $(el).find('.dl-container')));
              pointComponents.get(currentId).refreshEdges($(el).find('.dl-container'));
            }
          })

          return fakeComponents;
        },
        takeTemp(index) {
          const { temp } = this.get();
          temp.id = index;
          ////console.log(temp);
        },
        checkDl(){
          if($(this.refs.dlContainer).find('li').length == 0){
            this.beforeDestroy();
          }
        }
      },
      data(){
        return {
          temp: {

          }
        }
      }
    }
  </script>

<style>

    .fake-btn {
      opacity: 0;
      position: absolute;
      right: 5px;
      top: 10px;
    }
  
    .delete-btn {
      position: absolute;
      right: 20px;
      top: 10px;
    }
    .circle {
      width: 20px;
      height: 20px;
      font-size: 14px;
      text-align: center;
      color: white;
      border-radius: 100%;
    }
    .circle.nd {
      background-color: grey;
    }
  
    .circle.dl {
      margin-right: 5px;
      background-color: red;
    }
  
    .circle.tk {
      margin-left: 5px;
      background-color: green;
    }
  
    ref:point:hover .fake-btn {
      opacity: 1;
    }
  
    ref:point {
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #C4C4C4;
    }

    ref:point.is-visible {
      display:block;
    }

    ref:point.dl {
      opacity: 0.5;
    }
  
    ref:dlContainer {
      position: relative;
      bottom: -5px; left: -5px;
      width: 100%;
      border-radius: 5px;
      padding: 5px;
      min-height: 100px;
      background-color: #EEEEEE;
    }
  
    .text-input {
      background: transparent !important;
      background-color: transparent !important;
      color: black !important;
      border-color: white !important;
      height: auto !important;
      width: auto !important;
      padding: 0;
      margin: 0;
    }
  </style>