<svelte:window on:mousemove="fakePosition(event)"/>
<div class="{classList} uk-height-1-1">
  {#if isTypeSelect}
  <label class="uk-text-small">
    <input
        on:click="checkAll()"
        type="checkbox"
        class="uk-checkbox"
        checked="{points.every(point => point.checked)}">
    <u class="uk-margin-small-left">Выделить все</u>
  </label>
  {/if}
  {#if !groupBy || groupBy == 'points' || routeType}
    <ul ref:list  class="uk-list uk-margin-remove uk-height-1-1" id="{routeType}-list">
      {#each points as point}
        <li ref:listItem data-point-id="{point.id}" class="list-item {routeType}-list-item {isTypeSelect || isDraggable ? 'uk-grid' : ''} {isDraggable ? 'drag-icon' : ''}" on:click="fire('pointClick', point)" >
          {#if isDraggable}
          <div class="{routeType}-drag-icon drag-icon uk-width-auto uk-flex uk-flex-center uk-text-center uk-margin-small-right">
            <img src="/svg/drag.svg">
          </div>
          {/if}

          {#if isTypeSelect}
          <div class="uk-width-auto uk-flex uk-flex-center uk-text-center uk-margin-small-right">
            <input on:click="checkPoint(point)"
                  type="checkbox"
                  class="uk-checkbox uk-margin-auto-vertical"
                  checked="{point.checked ? 'checked' : ''}">
          </div>
          {/if}

          <WayPoint
              on:click="checkPoint(event)"
              {isTypeSelect}
              {actions}
              {point}
              {routeType}
              {editStore}
              {showOnMap}
              {hideOrdersInfo}
              {isChangedAddress}
              active={activeTk && activeTk.id == point.id}
              on:action="slicePoint(event.point)"
              on:fake="addFake(event)"
          />
        </li>
      {/each}
    </ul>
  {:else}
    {#each group as groupValue}
      <div class="uk-margin group-item">
        <div class="uk-grid-collapse" uk-grid>
          {#if isTypeSelect}
          <div class="uk-width-auto uk-flex uk-flex-center uk-text-center uk-margin-small-right">
            <input on:click="checkPoints(groupValue)"
                  type="checkbox" 
                  class="uk-checkbox uk-margin-auto-vertical"
                  checked="{groupPoints[groupValue] ? groupPoints[groupValue].every(point => point.checked) : false}">
          </div>
          {/if}
          <div class="uk-width-expand uk-position-relative border" on:click="setVisible(groupValue)">
            <span class="uk-margin-small-left">{groupValue}</span>
            <span class="uk-text-primary uk-text-bold uk-margin-small-left">{groupPoints[groupValue] ? groupPoints[groupValue].length : 0}</span>
            {#if isVisible[groupValue]}
            <span class="uk-position-right uk-flex uk-flex-middle uk-margin-small-right" uk-icon="icon: chevron-up"></span>
            {:else}
            <span class="uk-position-right uk-flex uk-flex-middle uk-margin-small-right" uk-icon="icon: chevron-down"></span>
            {/if}
          </div>
        </div>
        <div class="uk-margin-small-top" style="{!isVisible[groupValue] ? 'display:none' : ''}">
          <ul ref:list  class="uk-list uk-margin-remove uk-height-1-1" id="{routeType}-list">
              {#each points.filter(point => point[groupBy] && point[groupBy].includes(groupValue)) as point, i}
                <li ref:listItem data-point-id="{point.id}" class="list-item uk-position-relative {routeType}-list-item {isTypeSelect || isDraggable ? 'uk-grid' : ''} {isDraggable ? 'drag-icon' : ''}" on:click="fire('pointClick', point)" >
                  {#if isDraggable}
                  <div class="{routeType}-drag-icon drag-icon uk-width-auto uk-flex uk-flex-center uk-text-center uk-margin-small-right">
                    <img src="/svg/drag.svg">
                  </div>
                  {/if}
        
                  {#if isTypeSelect}
                  <div class="uk-width-auto uk-flex uk-flex-center uk-text-center uk-margin-small-right">
                    <div class="line" style="{i == groupPoints[groupValue].length - 1 ? 'height: calc(50% + 20px) !important;' : ''}"></div>
                    <input on:click="checkPoint(point)"
                          type="checkbox"
                          class="uk-checkbox uk-margin-auto-vertical"
                          checked="{point.checked ? 'checked' : ''}">
                  </div>
                  {/if}
        
                  <WayPoint
                      on:click="checkPoint(event)"
                      {isTypeSelect}
                      {actions}
                      {point}
                      {routeType}
                      {editStore}
                      {showOnMap}
                      {hideOrdersInfo}
                      {isChangedAddress}
                      active={activeTk && activeTk.id == point.id}
                      on:action="slicePoint(event.point)"
                      on:fake="addFake(event)"
                  />
                </li>
              {/each}
            </ul>
          </div>
        </div>
    {/each}
    {#if points.filter(point => !point[groupBy] || !point[groupBy].length).length != 0}
      <div>
        <div>Без группы</div>
        <ul ref:list  class="uk-list uk-margin-remove uk-height-1-1" id="{routeType}-list">
            {#each points.filter(point => !point[groupBy] || !point[groupBy].length) as point}
              <li ref:listItem data-point-id="{point.id}" class="list-item {routeType}-list-item {isTypeSelect || isDraggable ? 'uk-grid' : ''} {isDraggable ? 'drag-icon' : ''}" on:click="fire('pointClick', point)" >
                {#if isDraggable}
                <div class="{routeType}-drag-icon drag-icon uk-width-auto uk-flex uk-flex-center uk-text-center uk-margin-small-right">
                  <img src="/svg/drag.svg">
                </div>
                {/if}
      
                {#if isTypeSelect}
                <div class="uk-width-auto uk-flex uk-flex-center uk-text-center uk-margin-small-right">
                  <input on:click="checkPoint(point)"
                        type="checkbox"
                        class="uk-checkbox uk-margin-auto-vertical"
                        checked="{point.checked ? 'checked' : ''}">
                </div>
                {/if}
      
                <WayPoint
                    on:click="checkPoint(event)"
                    {isTypeSelect}
                    {actions}
                    {point}
                    {routeType}
                    {editStore}
                    {showOnMap}
                    {hideOrdersInfo}
                    {isChangedAddress}
                    active={activeTk && activeTk.id == point.id}
                    on:action="slicePoint(event.point)"
                    on:fake="addFake(event)"
                />
              </li>
            {/each}
          </ul>
        </div>
    {/if}
  {/if}
</div>

<script>
  import WayPoint from 'components/points/PointsCard.html';
  
  export default {
    components: {
      WayPoint
    },
    onupdate({ changed, current }) {
      if (changed.points && current.points) {
        let { checked } = this.get();
        checked = [];
        this.set({ checked });
        this.addMarkers();
      }

      if (changed.checked) {
        const { editStore } = this.get();
        if (editStore) editStore.set({ checked: current.checked });
      }

      if(changed.isActive){
        if(!current.isActive){
          this.clear();
        }
      }

      if(changed.checked){
        
      };
    },
    onstate({ changed, current }){
      if(changed.points && current.points && !current.groupPoints.length && current.group){
        let groupPoints = {};
        let groupPointsId = {};
        current.group.map(group => {
          groupPoints[group] = current.points.filter(point => point[current.groupBy] && point[current.groupBy].includes(group));
          groupPointsId[group] = groupPoints[group].map(point => {
            return point.id;
          })
        });
        this.set({groupPoints, groupPointsId});
      }
    },
    ondestroy() {
      if(window.map){
        let { points } = this.get();
        window.map.helper.hideAll(points);
      }
    },
    oncreate() {
      const self = this;
      var { editStore, routeType, points } = this.get();

      if (editStore) {
        editStore.on('state', function ({ changed, current }) {
          if (routeType) {
            points = current.points.filter(point => point.type == routeType);
            self.set({ points });
            self.addMarkers(points);
          } else {
            self.set(current);
          }
        });

      }

      if (routeType == 'dl') {
        this.set({ isDraggable: true });

        Sortable.create(this.refs.list, {
          group: 'dl',
          multiDrag: true,
          selectedClass: "selected",
          animation: 150,

          onAdd: function (event) {
            setTimeout(function () {
              self.checkDuplicate(event.item);
            }, 50);
          },
        });
        // UIkit.sortable(this.refs.list, {
        //   'handle': '.dl-list-item',
        //   'group': 'routeList',
        //   'animation': 0,
        //   'threshold': 0
        // });

        // $(this.refs.list).on('start', function (event) {
        //   $('#dl-list').addClass('disabled');
        // });

        // $(this.refs.list).on('stop', function (event) {
        //   $('#dl-list').removeClass('disabled');
        // });
      } 
    },
    methods: {
      checkDuplicate(el) {
        let id = parseInt($(el).attr('data-point-id'));
        if($(`.dl-list-item[data-point-id=${id}]`).length > 1){
          $(el).remove();
        }
      },
      addFake(temp) {
        var { editStore } = this.get();
        if (editStore) editStore.set({ tempFakePointId: temp.id, tempFakeParent: temp.parent, tempTkIds: temp.tkIds });
      },

      groupChecked() {
        return 34;
      },
      
      addMarkers() {
        
      },

      apiPoint() {
        const apiKey = "AIzaSyAvmvgCSleLj0_RBUVyOcqMglX7qs19yyk";
        const { address } = this.get();
        fetch(`https://maps.google.com/maps/api/geocode/json?address=${address}&key=${apiKey}`)
          .then(responce => {
            if (responce.ok) {
              return responce.json();
            } else {
              throw new Error("answer isn't ok!")
            }
          })
          .then(answer => {
            this.sendPoint(answer.results);
          })
          .catch((error) => {
          }
          )
      },
      sendPoint(data) {
        let { pointCreate } = this.get();
        pointCreate.address = data[0].formatted_address;
        pointCreate.lat = data[0].geometry.location.lat;
        pointCreate.lon = data[0].geometry.location.lng;
        this.fire("event", pointCreate);
      },

      onMarkerClick(point, e, self, marker) {
        if(point && point.application && point.application.orders_id){ 
          window.router.orders.navigate(`/orders/${point.application.orders_id}`)
        } else if(point){
          window.router.orders.navigate(`/orders/byPointsId/${point.id}`)
        }
      },

      checkPoint(point) {
        let { points, showOnMap, isTypeSelect } = this.get();

        if(isTypeSelect){
          if (!point.checked) {
            point.checked = true;
            if(!showOnMap) {
              window.map.helper.showOnMap(point, true);
              window.map.helper.setListenerToPoints([point], this.onMarkerClick, this, 'click');
            }
            
            if(point.type == 'tk') {
              points.map(p => {
                if(point.dl_ids && point.dl_ids.includes(p.id)) {
                  if(p.tk_ids && p.tk_ids.includes(point.id)) {
                    p.checked = true
                    if(!showOnMap) {
                      window.map.helper.showOnMap(p, true);
                      window.map.helper.setListenerToPoints([p], this.onMarkerClick, this, 'click');
                    }
                  }
                }
              })
            } else if(point.type == 'dl') {
              points.map(p => {
                if(point.tk_ids && point.tk_ids.includes(p.id)) {
                  if(p.dl_ids && p.dl_ids.includes(point.id)) {
                    p.checked = true
                    if(!showOnMap) {
                      window.map.helper.showOnMap(p, true);
                      window.map.helper.setListenerToPoints([p], this.onMarkerClick, this, 'click');
                    }
                  }
                }
              })
            }
          } else {
            point.checked = false;
            if(!showOnMap) window.map.helper.hideOnMap(point);

            if(point.type == 'tk') {
              points.map(p => {
                if(point.dl_ids && point.dl_ids.includes(p.id)) {
                  if(p.tk_ids && p.tk_ids.includes(point.id)) {
                    p.checked = false
                    if(!showOnMap) window.map.helper.hideOnMap(p);
                  }
                }
              })
            } else if(point.type == 'dl') {
              points.map(p => {
                if(point.tk_ids && point.tk_ids.includes(p.id)) {
                  if(p.dl_ids && p.dl_ids.includes(point.id)) {
                    p.checked = false
                    if(!showOnMap) window.map.helper.hideOnMap(p);
                  }
                }
              })
            }
          }
          this.set({ points });
        }
      },
      checkPoints(groupValue) {
        let { points, showOnMap, groupPointsId } = this.get();
          if(points.filter(point => groupPointsId[groupValue].includes(point.id)).every(point => point.checked)){
            points = points.map(point => {
              if(groupPointsId[groupValue].includes(point.id)) {
                point.checked = false;
                if(!showOnMap) window.map.helper.hideOnMap(point);
              }
              return point;
            })
          } else {
            points = points.map(point => {
              if(!point.checked){
                if(groupPointsId[groupValue].includes(point.id)) {
                  point.checked = true;
                  if(!showOnMap) {
                    window.map.helper.showOnMap(point, true);
                    window.map.helper.setListenerToPoints([point], this.onMarkerClick, this, 'click');
                  }
                }
              }
              return point;
            })
          }

          this.set({ points });
      },
      checkAll() {
        let { points, checked, showOnMap } = this.get();

        if (points.every(point => point.checked)) {
          points = points.map(function (item) {
            item.checked = false;
            return item;
          });

          if(!showOnMap) window.map.helper.hideAll(points);
        } else {
          points = points.map(function (item) {
            item.checked = true;
            return item;
          });

          if(!showOnMap) window.map.helper.showAll(points);
        }

        
        this.set({ points });
      },
      slicePoint(data) {
        const { markers } = window.map.store.get();
        let { points, editStore } = this.get();

        points = points.filter(function (point) {
          if (point.index === data.index) {
            markers.get(point).setMap(null);
            return false;
          }
          return true;
        });
        editStore.set({ points });
      },
      clear() {
        let { points } = this.get();
        window.map.helper.hideAll(points);

        this.set({ checked: [] });
      },
      fakePosition(event) {
        $('.fake-point-on-cursor').css('left', (event.pageX + 20) + 'px');
        $('.fake-point-on-cursor').css('top', event.pageY + 'px');
      },
      setVisible(group){
        let { isVisible } = this.get();
        isVisible[group] = isVisible[group] ? !isVisible[group] : true;

        this.set({ isVisible });
      }
    },
    data() {
      return {
        checked: [],
        points: [],
        groupPoints: [],
        groupPointsId: [],
        isVisible: {},
        isCheckedGroup: {},
        isDraggable: false,
        showOnMap: true,
        isTypeSelect: "",
        activeTk: {},
        classList: null,
        isChangedAddress: false
      }
    },
  }
</script>
<style>
  ref:wrapper {
    max-height: 80%;
    overflow-y: auto;
  }

  ref:list {
    min-height: 100%;
  }

  li {
    margin-top: 0 !important;
    padding: 5px 0;
    user-select: none !important;
  }

  li * {
    user-select: none !important;
  }
  
  .dl-list-item {
    height: 40px !important;
  }

  .nd {
    color: bisque;
  }

  .tk {
    color: red;
  }

  .dl {
    color: green;
  }

  .drag-icon {
    cursor: grab;
  }

  .line {
    height: 100%;
    width: 1px;
    position: absolute;
    top:-20px;
    z-index: 1;
    background: #C4C4C4;;
  }

  input[type='checkbox'] {
    position: relative;
    z-index: 2;
  }

  .border {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
    background: white;
  }
</style>