<div ref:wrapper>
  <!-- {#if !routeEditor}
    <label style="float:right">Отображать связи <input class="uk-checkbox" type="checkbox" bind:checked=showPolylines></label><br>
  {/if} -->

  {#each routes as route}
    <div class="uk-grid-collapse uk-margin-small-top" uk-grid>
      {#if !isTypeSelect}
        <div class="uk-width-auto uk-margin-small-right">
          <input on:click="checkRoute(route)"
                type="checkbox"
                class="uk-checkbox"
                checked="{checked.indexOf(route.id) != -1 ? 'checked' : ''}">
        </div>
      {/if}
      <div class="uk-width-expand">
        {#if routeEditor}
          <Card small="{true}" {isTypeSelect} {route} on:remove="fire('remove', event)" on:check="checkRoute(route)" bind:checked showIntro={false}/>
        {:else}
          <Card dlboy="{route.users_id ? route.users_id : 0}" {dlboys} {isTypeSelect} {route} on:remove="fire('remove', event)" on:check="checkRoute(route)" bind:checked/>
        {/if}
      </div>
    </div>
    
  {/each}
</div>

<script>
  import Phalcon from "modules/phalcon.js";

  import Card from "components/routes/RoutesCard.html";

  export default {
    components: {
      Card
    },
    methods: {
      checkRoute(route) {
        const { Phalcon } = this.options.root.get();
        let { checked, pointsMap } = this.get();

        let index = checked.indexOf(route.id);
        if (index === -1) {
          checked.push(route.id);

          let points = route.points;
          if (!points) {
            points = pointsMap.get(route);
          } else {
            pointsMap.set(route, points);
          }
          if (!points) {
            Phalcon.points.all({
              routes_id: route.id,
              with: 'dl'
            }).then(points => {
              delete points.answer;

              pointsMap.set(route, points);
              this.showOnMap(route, points);
            });
          } else {
            this.showOnMap(route, points);
            window.map.helper.setListenerToPoints(points, this.onMarkerClick, this, 'click');
          }

        } else {
          checked.splice(index, 1);
          let points = pointsMap.get(route);
          if (points) {
            this.hideOnMap(route, points);
            pointsMap.delete(route);
          }
        }
        this.set({ checked });
      },

      onMarkerClick(point, e, self, marker) {
        if(point.type == 'dl'){
          if(point && point.application && point.application.orders_id){ 
            window.router.orders.navigate(`/orders/${point.application.orders_id}`)
          } else if(point){
            window.router.orders.navigate(`/orders/byPointsId/${point.id}`)
          }
        }
      },

      showOnMap(route, points){
        const { showPolylines } = this.get();
        if(showPolylines){
          
          let pointsIds = points.map(point => {
            return point.id;
          });

          points.map(point => {
            if(point.type == 'tk'){
              if(point.dl) {
                point.dl.map(dl => {
                  if(pointsIds.includes(dl.id)){
                    window.map.helper.setPolyline(point.id + '-' + dl.id, [{
                      lat: parseFloat(point.lat),
                      lng: parseFloat(point.lon)
                    }, {
                      lat: parseFloat(dl.lat),
                      lng: parseFloat(dl.lon)
                    }]);
                  }
                });
              } else if(point.dl_ids) {
                point.dl_ids.map(dl_id => {
                  if(pointsIds.includes(dl_id)){
                    window.map.helper.setPolyline(point.id + '-' + dl_id);
                  }
                });
              }
            }
          });
        }

        window.map.helper.refreshLabels(points);

        window.map.helper.showAll(points);
        if(typeof route.legs == 'string') route.legs = JSON.parse(route.legs);

        if(route.legs && Array.isArray(route.legs)) {
          route.legs.map((leg, i) => {
            if(Array.isArray(leg)){
              window.map.helper.showLeg(route.id + "-" + i, leg, null);
            } else {
              leg = google.maps.geometry.encoding.decodePath(leg);
              window.map.helper.showLeg(route.id + "-" + i, leg, null);
            }
          });
        }
      },

      hideOnMap(route, points){
        const { showPolylines } = this.get();
        
        if(showPolylines){
          let pointsIds = points.map(point => {
            return point.id;
          });
          
          points.map(point => {
            if(point.dl) {
              point.dl.map(dl => {
                if(pointsIds.includes(dl.id)) window.map.helper.hidePolyline(point.id + '-' + dl.id);
              });
            } else if(point.dl_ids) {
              point.dl_ids.map(dl_id => {
                if(pointsIds.includes(dl_id)) window.map.helper.hidePolyline(point.id + '-' + dl_id);
              });
            }
          });
        }

        if(route.legs && Array.isArray(route.legs) && !route.legs[0].lat) { 
          route.legs.map((leg, i) => {
            window.map.helper.hideLeg(route.id + "-" + i, leg);
          });
        } else {
          window.map.helper.hideLeg(route.id + "-0", route.legs);
        }

        window.map.helper.hideAll(points);
      },

      clear() {
        let { pointsMap } = this.get();
        pointsMap.forEach(points => {
          window.map.helper.hideAll(points);
        });

        this.set({
          checked: [],
          pointsMap: new Map()
        });
      }
    },
    oncreate() {
      let { routes } = this.get();

      // if(routes){
      //   routes = routes.map(route => {
      //     if(route.legs  && Array.isArray(route.legs)) route.legs = Array.from(route.legs);
      //     return route;
      //   });
      //   ////console.log({routes});
      //   this.set({ routes });
      // }

      Phalcon.users.all({
        byGroupsId: 3
      }).then(answer =>{
        if(answer.status == 200){
          this.set({dlboys: answer});
        }
      })
    },
    onupdate({ changed, current }) {
      if (changed.isActive) {
        if (!current.isActive) {
          this.clear();
        }
      }

      if (changed.showPolylines) {
        if (current.showPolylines){
          current.pointsMap.forEach(points => {
            let pointsIds = points.map(point => {
              return point.id;
            });

            points.map(point => {
              if(point.type == 'tk' && point.dl){
                point.dl.map(dl => {
                  if(pointsIds.includes(dl.id)){
                    window.map.helper.setPolyline(point.id + '-' + dl.id, [{
                      lat: parseFloat(point.lat),
                      lng: parseFloat(point.lon)
                    }, {
                      lat: parseFloat(dl.lat),
                      lng: parseFloat(dl.lon)
                    }]);
                  }
                });
              }
            });
          });
        } else {
          current.pointsMap.forEach(points => {
            points.map(point => {
              if(point.dl) point.dl.map(dl => {
                window.map.helper.hidePolyline(point.id + '-' + dl.id);
              });
            });
          });
        }
      }
    },
    data() {
      return {
        checked: [],
        pointsMap: new Map(),
        legMap: new Map(),
        isTypeSelect: true,
        showPolylines: true,
        isActive: true,
        dlboys: []
      }
    }
  }
</script>

<style>
  input {
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 2px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 4px;
  }
</style>