<div class="uk-card uk-card-default uk-card-body uk-margin-remove uk-padding-remove">

  {#await groups}
	<div uk-spinner></div>
{:then answer}
  <ul uk-accordion class="accordionEvent">

  {#each answer as group, i}

    <li>
      <a on:click="test(this, group, i, group.unique_rights_count)" class="uk-accordion-title uk-text-bold uk-padding-small" href="#">
        <span class="text__header--short uk-width-auto uk-margin-right">{group.name}</span>
        {#if notVisible !== i}
            <span class="uk-text-small uk-text-muted uk-margin-right">Пользователи группы: {group.users_count}</span>
            <span class="uk-text-small uk-text-muted">Особые права: {answer[i].unique_rights_count || group.unique_rights_count}</span>
<!--            <span class="uk-text-small uk-text-muted pos-left">Права группы:нечто</span>-->
        {/if}
      </a>
      <div class="uk-accordion-content lrb__padding">
        <div  class="uk-grid-divider uk-child-width-expand@s" uk-grid>
          <div class="uk-width-1-2">
<!--            <Input class="uk-Input" type="text" bind:value="group.name">-->
            <!--            -->
            <div id="users"></div>
            <!--            <GroupTable data="users" />-->
          <div class="uk-flex">
            <!-- {#if group.id > 3}
            <div class="trash">
              <span  uk-icon="trash"></span>
              <a on:click="groupDelete(group)" class="uk-link-muted trash">Удалить группу</a>
            </div>
            {:else}
            <div class="trash">
              <span  uk-icon="trash" class="isDisabled"></span>
              <a class="uk-link-muted isDisabled">Удалить группу</a>
            </div>
            {/if} -->

            <div class="usersAdd uk-margin-small-left" >
              <span uk-icon="users"></span>
              <a on:click="usersAdd(this,group)" class="uk-link-muted usersAdd">Добавить пользователя в группу</a>
            </div>
          </div>
          </div>
          <div class="uk-width-1-2">
            <div>
              <p class="uk-text-small uk-margin-remove">Права группы</p>
              <!--  <Rights data="{ rightsId: group.rights_id }" />    -->
              <div id="rights"></div>
              <!--              -->
            </div>
          </div>
        </div>
      </div>
      <hr class="uk-margin-remove">
    </li>

  {/each}
</ul>
  {:catch error}
    <p>well that's odd</p>
  {/await}
</div>

<!--<div class="uk-modal-body">-->

<!--</div>-->

<div ref:modalSelect></div>
<div ref:newGroup></div>

<script>
  import Phalcon from 'modules/phalcon.js';
  import Validator from 'modules/validator.js';

  import NewGroup from 'components/groups/GroupsEditModal.html';
  import Rights from 'components/rights/RightsEdit.html';
  import Users from 'components/users/UsersByGroupList.html';
  import SelectUsers from 'components/users/UsersSelect.html';

  export default {
    components: {
      NewGroup,
      Rights,
      GroupTable,
      SelectUsers
    },
    methods:{
      usersAdd(el, group){
        const self = this;
        const {usersMap, usersList,} = this.get();
        var selectUsers = new SelectUsers({
          target: this.refs.modalSelect,
          data: {
            users: Phalcon.users.all({
              with: "groups"
            }),
            // users: outsideGroup,
            group_id: group.id,
          },
        });

        selectUsers.on("success", function (usersChanges) {
          let container;
          container = usersChanges.users.join();

          Phalcon.users.setGroups(usersChanges.id_groups, {
            users: container
          }).then(answer => {
            if (answer.status == 200) {
              delete answer.status;
              let something = usersMap.get(usersChanges.id_groups);
              let {users, uniqRights} = something.get();
              users.push(...answer);
              // users = users.concat(answer);
              something.set({users, uniqRights: uniqRights + 1});
              UIkit.notification(`В ${group.name} добавлены новые пользователи,`, {status: "success", pos: "bottom-right"});
              self.refresh(something, true).then(without => {
                usersMap.set(usersChanges.id_groups, without);
              });
            }
          });
          this.destroy();
        });
      },
      refresh(without, usersRefresh = null, rightsRefresh = null){
        const {usersMap, rightsMap} = this.get();
        return new Promise(resolve => {
          Phalcon.groups.all({
            with: 'users_count,rights_count',
          }).then( answer => {
            this.set({
              groups: answer,
              usersRightsCount: answer
            });

            if(usersRefresh) {
              usersMap.forEach(component => {
                if (component && (component != without || !without)) component.destroy();
              });
              usersMap.clear();
            }

            if(rightsRefresh) {
              rightsMap.forEach(component => {
                if (component && (component != without || !without)) component.destroy();
              });
              rightsMap.clear();
            }
            // }
            // if (rights) {
            //   rightsMap.forEach(component => {
            //     if(component && (component != without || !without)) component.destroy();
            //   });
            //   usersMap.clear();
            // }
            resolve(without);
          });
        });
      },
      groupDelete(data){
        const self = this;
        const { groups } = this.get();
        if (data.users_count > 0) {
          Swal.fire({
            title: 'Внимание!',
            text: 'Пользователи останутся без группы',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Подтвердить',
            cancelButtonText: 'Отменить',
          }).then(result => {
            if (result.value) {
              Swal.fire(
                'Удалено!',
                `группа '${data.name}' удалена`,
                'success'
              );
              Phalcon.groups.remove(data.id).then(answer => {
                if (answer.status === 200) {
                  self.refresh(null, true, true);
                } else {
                }
              });
            }
          });
        } else {
          Phalcon.groups.remove(data.id).then(answer => {
            if (answer.status === 200) {
              self.set({ groups: Phalcon.groups.all() });
            } else {
            }
          });
        }
      },
      btnEvent(data) {
      },
      test(el,group,index, count){
        const self = this;
        const {notVisible, usersRightsCount} = this.get();
        if (notVisible === index) {
          this.set({ notVisible: null });
        } else {
          this.set({ notVisible: index });
        }

        let crumbs = [];
        if (group) {
          const { usersMap } = this.get();
          if (!usersMap.get(group.id)) {
            Phalcon.users.all({
              byGroupsId: group.id
            }).then(answer => {
              answer.forEach(item => {
                crumbs.push(item);
              });
                let elm = $(el).parent().find('#users')[0];
                const usersComponent = new Users({
                  target: elm,
                  data: {users: crumbs,
                    groupRights: group.rights_id,
                    uniqRights: count }
                });
              usersComponent.on('success', function () {
                self.refresh(this, true).then(answer => {
                  usersMap.set(group.id, this);
                });
              });

              usersComponent.on('selectItem', event => {
                console.log(event)
                var selectUsers = new SelectUsers({
                  target: this.refs.modalSelect,
                  data: {
                    users: Phalcon.users.all({
                      with: "groups"
                    }),
                    // users: outsideGroup,
                    group_id: group.id,
                  },
                });
              });
              usersMap.set(group.id, usersComponent);
            });
          }

          const { rightsMap, defaultRights } = this.get();
          if (!rightsMap.get(group.rights_id)) {
            const rightsComponent = new Rights({
              target: $(el).parent().find('#rights')[0],
              data: { rightsId: group.rights_id, withButton: true }
            });
            rightsComponent.on("refresh", function (event) {
              rightsComponent._state.rights = Validator.modifier.stringToRights(event.answer, defaultRights);
              rightsMap.set(group.rights_id = event.rightsId, rightsComponent);
              this.set({ rightsMap });
            });
            rightsMap.set(group.rights_id, rightsComponent);
          }
        }
      },
      getContent(id, i) {
        let { groups, defaultRights } = this.get();
        this.set({ rightsId: id });
        let someOne;
        Phalcon.rights.get(id)
          .then(answer => {
            someOne = Validator.modifier.stringToRights(answer, defaultRights);
            groups[i].rights = someOne;
            this.set({ groups });
          })
          .catch(
            e => {
              ////console.log("Something went wrong, Error type: ", e)
            }
          );
      }
    },
    ondestroy() {
      window.nav.set({
        actions: []
      });
      if(window.nav.listener) window.nav.listener.cancel();
    },
    oncreate() {
      const self = this;
      let {newGroup} = this.get();
      //console.log("newGroup", newGroup);

      setTimeout(() => {
        window.nav.set({
          breadcrumb: [{
            name: 'Настройки'
          }, {
            name: 'Группы и права',
            link: '/settings/groups'
          }],
          actions: []
        });

        if(window.nav.listener) window.nav.listener.cancel();
        window.nav.listener = window.nav.on('add', () => {
          let {newGroup} = self.get();
          //console.log('inside',newGroup);
          if (!newGroup || !newGroup._fragment) {
            newGroup = new NewGroup({
              target: self.refs.newGroup,
            });

            newGroup.on('success', group => {
              self.refresh();
            });

            self.set({newGroup});
          }
        });
      }, 300);

      const {groups, usersList, usersRightsCount} = this.get();
      groups.then( answer => {
        this.set({ usersRightsCount: answer });
      });
      usersList.then( answer => {
        this.set({ usersList: answer });
      });
      groups.then( answer => {
        this.set({ groups: answer });
      })
    },
    data() {
      return {
        usersList: Phalcon.users.all(),
        usersRightsCount: {},
        groups: Phalcon.groups.all({
          with: 'users_count,rights_count',
        }),
        rightsId: null,
        usersMap: new Map(),
        rightsMap: new Map(),
        defaultRights: {
          users: {
            index: false,
            create: false,
            edit: false,
            delete: false
          },
          routes: {
            index: false,
            create: false,
            edit: false,
            delete: false
          }
        }
      }
    }
  }
</script>

<style>.text__header--short {
  word-wrap: break-word; }

.pos-right {
  position: absolute;
  right: 5%; }

.pos-left {
  position: absolute;
  left: 65%; }

.lrb__padding {
  padding: 0 15px 15px 15px;
  margin: 0; }

a span {
  pointer-events: none;
  font-weight: normal; }

ul {
  border: 1px solid #e2e2e2; }

li {
  margin: 10px 0px 10px 0px; }

.uk-accordion > :nth-child(n+1) {
  margin: 0; }

.trash {
  color: #E30613;
  border-radius: 5px;
  text-transform: unset; }
  .trash:hover {
    color: #E30613; }

.usersAdd {
  color: green; }
  .usersAdd:hover {
    color: green; }

.isDisabled {
  color: #c4c4c4;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none; }
  .isDisabled:hover {
    color: #c4c4c4; }
</style>