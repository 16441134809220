{#each points as tkpoint}
    {#if tkpoint.type == 'tk'}
        <li data-point-id="{tkpoint.id}" class="list-item tk-item" on:click="fire('pointClick', tkpoint)">
            <WayPoint point={tkpoint} withoutInfo={true} />
        </li>
        
        <div id="{tkpoint.id}-related-dls">
        {#each points as dlpoint}
            {#if dlpoint.type == 'dl' && tkpoint.dl_ids && tkpoint.dl_ids.includes(dlpoint.id)}
            <li data-point-id="{dlpoint.id}" class="list-item dl-item" on:click="fire('pointClick', dlpoint)">
                <WayPoint point={dlpoint} withoutInfo={true} />
            </li>
            {/if}
        {/each}
        </div>
    {/if}
{/each}

<script>
    import WayPoint from "components/points/PointsCard.html";
    
    export default {
        data() {
            return {
                points: []
            }
        },
        components: {
            WayPoint
        },
        oncreate() {
            const self = this;
            var { editStore, routeType, points } = this.get();
            var { points } = editStore.get();
            ////console.log(points);
            this.set({ points });

            if (editStore) {
                editStore.on('state', function ({ changed, current }) {
                    if (routeType) {
                        points = current.points.filter(point => point.type == routeType);
                        self.set({ points });
                    } else {
                        ////console.log(current);
                        self.set(current);
                    }
                });

            }
        }
    }
</script>

<style>
    li {
        list-style: none;
        
    }

    .tk-item {
        margin-top: 15px;
    }

    .dl-item {
        margin-top: 10px;
        padding-left: 20px;
    }
</style>