<ul class="uk-list" id="sidenav-uk-list" data-intro='Слева находится меню системы' data-step='2'>
    <li onclick="window.app.navigate('/')" class="brand-logo uk-flex uk-flex-center uk-text-center">
        <img class="uk-margin-auto-vertical" src="/img/logo_inverse.png" />
    </li>
    
    {#if session}
    <li class="uk-flex uk-flex-center uk-text-center sidenav-menu-item uk-position-relative" style="border-bottom: 1px solid rgba(255,255,255,0.1);">
        <div class="uk-margin-auto-vertical circle-image small" style="background-image: url(/img/avatar.png);" data-intro='В меню "Мой профиль" вы сможете посмотреть информацию о себе и пополнить баланс системы' data-step='3'></div>
        <Dropdown isAdmin={session.is_admin} title="{session.first_name} {session.last_name}" items={profile.items}/>
        <div class="hover-region" style="left: 0; top: 0; bottom: 0; right: 10px; position: absolute; z-index: 999;"></div>
    </li>
    {:else}
    <li class="uk-flex uk-flex-center uk-text-center sidenav-menu-item uk-position-relative" on:click="options.root.navigate('/auth')" style="border-bottom: 1px solid rgba(255,255,255,0.1);">
        <span class="uk-flex uk-flex-center" uk-icon="icon: unlock; ratio: 1.5"></span>
        <Dropdown title="Войти"/>
        <div class="hover-region" style="left: 0; top: 0; bottom: 0; right: 10px; position: absolute; z-index: 999;"></div>
    </li>
    {/if}

    {#each sections as section}
    <li class="uk-flex uk-flex-center uk-text-center sidenav-menu-item uk-position-relative {!session ? 'disabled' : ''}" on:click="options.root.navigate(section.pathname)">
        {#if section.icon}
            <span class="uk-flex uk-flex-center" uk-icon="icon: {section.icon}; ratio: 1.5" data-intro='{section.intro}' data-step='{section.introStep + 4}' id="section-{section.key}"></span>
        {:elseif section.iconImage}
            <div class="img-icon">
                <img src="{section.iconImage}" data-intro='{section.intro}' data-step='{section.introStep + 4}' id="section-{section.key}" />
                <div class="img-icon-text uk-width uk-position">
                    <span>{section.iconImageTitle || section.title}</span>
                </div>
            </div>
        {/if}
        {#if session}
            <Dropdown isAdmin={session.is_admin} title={section.title} items={section.items}/>
        {/if}
        <div class="hover-region" style="left: 0; top: 0; bottom: 0; right: 10px; position: absolute; z-index: 999;"></div>
    </li>
    {/each}

    <div class="bottom">
        <li class="uk-flex uk-flex-center uk-text-center sidenav-menu-item uk-position-relative" onclick="UIkit.modal($('#modal-help')).show();">
            <span class="uk-flex uk-flex-center" uk-icon="icon: question; ratio: 1.5"></span>
            <Dropdown title="Обратная связь"/>
            <div class="hover-region" style="left: 0; top: 0; bottom: 0; right: 10px; position: absolute; z-index: 999;"></div>
        </li>
        <li class="uk-flex uk-flex-center uk-text-center sidenav-menu-item uk-position-relative" on:click="resetIntro()">
            <span class="uk-flex uk-flex-center" uk-icon="icon: history; ratio: 1.5"></span>
            <Dropdown title="Показать обучение снова"/>
            <div class="hover-region" style="left: 0; top: 0; bottom: 0; right: 10px; position: absolute; z-index: 999;"></div>
        </li>
    </div>
</ul>


<div id="modal-help" uk-modal>
    <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
            <h2 class="uk-modal-title">Обратная связь</h2>
        </div>
        <div class="uk-modal-body">
            <label>Наши контакты</label><br>
            <span class="uk-text-meta">+7 (495) 201-22-22</span><br>
            <span class="uk-text-meta">am@fondo.ru</span><br><br>
            <textarea class="uk-textarea" rows="5" placeholder="Ваш вопрос..." bind:value="message"></textarea>
        </div>
        <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button">Отмена</button>
            <button class="uk-button uk-button-primary" type="button" on:click="sendRequest()">Отправить</button>
        </div>
    </div>
</div>

<script>
    import Dropdown from 'UI/SidenavDropdown.html';
    import Phalcon from 'modules/phalcon.js';

    export default {
        components: { Dropdown },

        data() {
            return {
                message: '',
                sections: [],
                profile: {
                    title: 'Андрей Сидоров',
                    pathname: '',
                    items: [
                    // {
                    //     name: 'Мой профиль',
                    //     pathname: '/profile'
                    // },{
                    //     name: "Мой баланс",
                    //     pathname: '/my-balance',
                    //     isAdmin: true
                    // },
                    // {
                    //     name: "Мой тариф",
                    //     pathname: '/my-tariff',
                    //     isAdmin: true
                    // },
                    {
                        name: "Выйти",
                        pathname: '/session/end'
                    }]
                }
            }
        },

        onupdate({changed, current}){
            if(changed.session){
                let { sections } = this.get()

                // console.log('current.session', current.session)

                if (current.session && current.session.available_functional) {
                    let af = JSON.parse(current.session.available_functional.value)
                    // console.log({af})

                    if (af) {
                        if (af.map && af.map.main) {
                            let section = {
                                title: 'Карта',
                                iconImage: '/svg/icons/map_v2.svg',
                                key: 'map',
                                items: [],
                                intro: 'В разделе "Карта" вы можете организовать ваши заказы и строить маршруты. Давайте попробуем создать свой первый маршрут',
                                introStep: 4
                            }

                            if (af.map.routes_and_points) {
                                section.items.push({
                                    name: "Маршруты и заказы",
                                    pathname: "/map",
                                })
                            }

                            if (af.map.fast_route) {
                                section.items.push({
                                    name: "Быстрый маршрут",
                                    pathname: "/map/fast/route",
                                })
                            }

                            sections.push(section)
                        }

                        if (af.orders && af.orders.main) {
                            let section = {
                                title: 'Заказы',
                                iconImage: '/svg/icons/order_v2.svg',
                                key: 'orders',
                                pathname: '',
                                items: [],
                                intro: 'В разделе "Заказы" происходит создание, редактирование и другая организация заказов',
                                introStep: 1
                            }

                            if (af.orders.new_order) {
                                section.items.push({
                                    name: "Новый заказ",
                                    pathname: "/orders/new"
                                })
                            }

                            if (af.orders.orders_list) {
                                section.items.push({
                                    name: "Все Заказы",
                                    pathname: "/orders-list"
                                })
                            }

                            sections.push(section)
                        }

                        if (af.data && af.data.main) {
                            let section = {
                                title: 'Справочники',
                                iconImage: '/svg/icons/data.svg',
                                iconImageTitle: 'Данные',
                                key: 'data',
                                pathname: '',
                                items: [],
                                intro: 'Раздел "Справочники" является вспомогательным, здесь хранятся ваши товары, клиенты, юр лица и многое другое',
                                introStep: 2
                            }

                            if (af.data.users) {
                                section.items.push({
                                    name: "Сотрудники",
                                    pathname: "/users"  
                                })
                            }

                            if (af.data.products) {
                                section.items.push({
                                    name: "Товары",
                                    pathname: "/directory/products"
                                })
                            }

                            if (af.data.clients) {
                                section.items.push({
                                    name: "Клиенты",
                                    pathname: "/directory/clients"
                                })
                            }

                            if (af.data.legals) {
                                section.items.push({
                                    name: "Контрагенты",
                                    pathname: "/directory/legals"
                                })
                            }

                            if (af.data.adresses) {
                                section.items.push({
                                    name: "Адреса",
                                    pathname: "/directory/addresses"
                                })
                            }

                            section.items.push({
                                name: "Автомобили",
                                pathname: "/vehicles"
                            })

                            sections.push(section)
                        }

                        if (af.delivery_express && af.delivery_express.main) {
                            let section = {
                                title: 'Диспетчерская',
                                iconImage: '/svg/icons/dispatch.svg',
                                iconImageTitle: 'Диспетчер',
                                pathname: '',
                                items: []
                            }

                            if (af.delivery_express.dispatch) {
                                section.items.push({
                                    name: 'Планирование',
                                    pathname: '/delivery/planner',
                                })

                                section.items.push({
                                    name: 'Окно диспетчера',
                                    pathname: '/delivery/express',
                                })
                            }

                            if (af.delivery_express.regions) {
                                section.items.push({
                                    name: 'Регионы',
                                    pathname: '/regions',
                                })
                            }

                            if (af.delivery_express.analytic) {
                                section.items.push({
                                    name: 'Аналитика',
                                    pathname: '/delivery-services',
                                })
                            }

                            sections.push(section)
                        }

                        if (af.settings && af.settings.main) {
                            let section = {
                                title: 'Финансы',
                                iconImage: '/svg/icons/finances.svg',
                                iconImageTitle: 'Финансы',
                                pathname: '',
                                items: []
                            }

                            if (af.settings.groups_and_rights) {
                                section.items.push({
                                    name: "Касса",
                                    pathname: "/finance/cashflow"
                                })

                                section.items.push({
                                    name: "Инкассация",
                                    pathname: "/finance-collections"
                                })

                                section.items.push({
                                    name: "Аналитика авто",
                                    pathname: "/finance-vehicles"
                                })
                            }

                            sections.push(section)
                        }

                        if (af.settings && af.settings.main) {
                            let section = {
                                title: 'Настройки',
                                iconImage: '/svg/icons/settings.svg',
                                iconImageTitle: 'Настройки',
                                pathname: '',
                                items: [],
                                intro: 'Раздел "Системные настройки" позволяет настроить группы пользователей системы и их права',
                                introStep: 3
                            }

                            if (af.settings.groups_and_rights) {
                                section.items.push({
                                    name: "Группы и права",
                                    pathname: "/settings/groups",
                                    isAdmin: true
                                })
                            }

                            if (af.settings.system_settings) {
                                section.items.push({
                                    name: "Системные настройки",
                                    pathname: "/settings/system",
                                    isAdmin: true
                                })
                            }

                            sections.push(section)
                        }
                    }
                }

                this.set({ sections })
                
                $('.sidenav-menu-item').off();
                $('.sidenav-menu-item').find('.hover-region').on('mouseenter', function(){
                    $(this).parent().find('.dropdown').show();
                });

                $('.sidenav-menu-item').on('mouseleave', function(){
                    $(this).find('.dropdown').hide();
                });
            }
        },

        methods: {
            sendRequest() {
                Phalcon.mail.helpRequest({ message }).then(answer => {
                    if(answer.status == 200){
                        UIkit.notification({
                            message: 'Спасибо, скоро мы вам отправим письмо с ответом на ваш вопрос!',
                            status: 'success',
                            pos: 'bottom-right'
                        });
                        UIkit.modal($('#modal-help')).hide();
                    }
                })
            },

            resetIntro() {
                if(confirm('Обучение по системе начнется заново, вы уверены?')){
                    this.options.root.navigate('/');
                    localStorage.removeItem('dashboard_intro');
                    localStorage.removeItem('map_intro');
                    localStorage.removeItem('new_order_intro');
                    localStorage.removeItem('route_dlboy_intro');
                    localStorage.removeItem('route_edit_intro_step_1');
                    localStorage.removeItem('route_edit_intro_step_1_points');
                    localStorage.removeItem('route_edit_intro_step_2');
                    localStorage.removeItem('route_edit_intro_step_3');
                    localStorage.removeItem('route_edit_intro_step_4');
                    localStorage.removeItem('route_intro');
                    localStorage.removeItem('users_new');
                    localStorage.removeItem('users_table');
                    window.location.reload();
                }
            }
        }
    }
</script>

<style>.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

ul {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999 !important;
  height: 100vh;
  width: 60px;
  background-color: #000000;
  margin: 0; }

ul li {
  cursor: pointer;
  height: 60px;
  color: #ffffff;
  margin: 0; }

.sidenav-menu-item:hover {
  background-color: #080808; }

.brand-logo {
  background-color: #000000; }

.brand-logo img {
  height: 70%; }

.bottom {
  position: absolute;
  bottom: 0;
  width: 100%; }

.img-icon {
  padding: 5px 10px 5px 10px; }

.img-icon img {
  width: 36px; }

.img-icon-text {
  margin-top: 2px;
  font-size: 10px; }
</style>