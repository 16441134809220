<div ref:modal uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <div class="uk-flex uk-flex-between">
      <h2 class="uk-modal-title">Особые права</h2>
      <div uk-icon="question" class=" uk-icon uk-margin-small-left uk-flex uk-flex-middle" uk-tooltip="title: Права, отличные от прав группы; pos: top-right"></div>
    </div>
<!--    <Input class="uk-Input" type="text" bind:value="group.name" placeholder="Введите имя новой группы">-->
    <Rights
        on:rightsEdit="initRights(event)"
        rightsId="{user.groups_id}"
        uniqueId="{user.rights_id}"
        {withButton} />

    <div class="uk-width-1-1 uk-flex uk-flex-between">
        <div class="uk-text-left uk-width-1-3">
          {#if user.rights_id !== null}
            <button on:click="unsetUniq()" class="g__btn--simple uk-button uk-button-primary uk-button-small" type="button">Отвязать права</button>
          {/if}
        </div>

      <div class="uk-text-right">
        <button class="g__btn--no uk-button uk-button-default uk-modal-close uk-button-small" type="button">Отмена</button>
        <button on:click="setUniq()" class="g__btn--yes uk-button uk-button-primary uk-button-small" type="button">Сохранить</button>
      </div>
    </div>
  </div>
</div>

<style>.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

button {
  text-transform: unset; }

.g__btn--yes {
  text-transform: unset;
  border-radius: 0;
  background: #FFFFFF;
  color: #e30613;
  border: 1px solid #e30613; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }

.g__btn--no {
  text-transform: unset;
  border-radius: 0; }
  .g__btn--no:hover {
    background: #c4c4c4;
    color: #ffffff; }

.g__btn--simple {
  text-transform: unset;
  border-radius: 0; }
  .g__btn--simple:hover {
    background: #faa05a;
    color: #ffffff; }
</style>

<script>
  import Validator from 'modules/validator.js';
  import Phalcon from 'modules/phalcon.js';

  import Rights from  'components/rights/RightsEdit.html';
  
  export default {
    oncreate(){
      const selfState = this.get();
      const {rightsId, user} = this.get();
      const self = this;
      UIkit.modal(this.refs.modal, {
        escClose: true,
        bgClose: false
      });
      UIkit.modal(this.refs.modal).show();

      $(this.refs.modal).on('hidden', function(event) {
        self.destroy();
      });
    },
    components:{
      Rights,
    },
    methods:{
      initRights(fireRights){
        const {rights} = this.get();
        if (fireRights.isChanged){
          this.set({ rights: fireRights.rights, isChanged: fireRights.isChanged });
        }else {
          this.set({ isChanged: false });
        }
      },
      unsetUniq(){
        const self = this;
        const {user} = this.get();
        user.rights_id = null;
        Phalcon.users.edit(user).then( answer => {
          if (answer.status === 200) {
            UIkit.notification(`${user.first_name}, присвоены обычные права группы!,`, {status: "success", pos: "bottom-right"});
            self.fire("success", answer);
            this.destroy();
          }
        });
      },
      setUniq(){
        const self = this;
        const selfstate = this.get();
        const {rights, rightsId, user, isChanged} = this.get();
        if (isChanged){
          let data = Validator.modifier.rightsToString(rights);
          Phalcon.rights.create({ value: data })
            .then( answer => {
              if (answer.status === 200){
                user.rights_id = answer.id;
                Phalcon.users.edit(user)
                  .then( answer => {
                    if (answer.status === 200) {
                      this.fire("success", answer);
                      UIkit.notification(`${user.first_name}, присвоены особые права!`, {status: "success", pos: "bottom-right"});
                      self.destroy();
                    }
                  })
              }
            });
        }else{
          UIkit.notification(`Уникальные права должны отличаться от прав группы!`, {status: "danger", pos: "bottom-right"});
        }
      }
    },
    data(){
      return {
        isChanged: false,
      }
    }
  }
</script>