
<div class="wrapper uk-padding-remove" data-intro='Добро пожаловать в систему REX! Система поможет оптимизировать вашу логистику' data-step='1'>

  <div class="uk-flex uk-flex-between">
<!--Main-->
    <div class="uk-width-2-3 uk-card uk-card-default uk-card-body">
      <h2 style="color: #515151" class="uk-card-title uk-text-center">Обучение. Построение маршрута</h2>

      <div ref:slider uk-slider>
        <div class="uk-position-relative">
          <div class="uk-slider-container">
            <ul class="uk-slider-items">
              {#each slider as slide, index}
                <li data-key="{index}" class="uk-flex-center uk-flex uk-width-1-1">
                  <img src={slide.img} alt="picture">
                </li>
              {/each}
            </ul>
          </div>
          <a class="uk-position-center-left-out" href="#" uk-slider-item="previous"><img src="svg/sliderRight.svg" alt="icon-left"></a>
          <a class="uk-position-center-right-out" href="#" uk-slider-item="next"><img src="svg/sliderLeft.svg" alt="icon-right"></a>
          <ul class="uk-margin-small-top uk-flex-center uk-slider-nav uk-dotnav"></ul>
        </div>
      </div>

    {#if marker}
      <div>
        <div class="uk-margin-top uk-text-small">{slider[marker].content}</div>
      </div>
    {:else}
      <div class="uk-text-small">нету данных</div>
    {/if}

    </div>

<!--Side-->
{#if session}
      <div class="uk-width-1-3 uk-card uk-padding-small uk-margin-left uk-card-default uk-card-body nav__text">
        <h2 class="uk-card-title uk-text-center">Навигация</h2>

        <div class="uk-grid-small uk-child-width-1-2 uk-flex-right" uk-grid>
<!--first column-->
          {#each navField as items, i}
            {#if i === 0}
      <!-- iteration 1 -->
              <div class="uk-flex-first uk-width-2-5">
                <div class="uk-flex uk-flex-left uk-margin-small-bottom">
                  <img src="{items.icon}" alt="icon">
                  <h3>{items.title}</h3>
                </div>
                <div class="uk-flex uk-flex-middle uk-flex-column uk-flex-between">
                  {#each items.items as item }
                    <div on:click="options.root.navigate(item.link)" class="routes-link rel-case uk-margin-bottom">
                      <img src="{item.icon}" alt="employees">
                      <div class="uk-text-center">{item.name}</div>
                      {#if item.dev}
                        <div class="in-dev">В разработке</div>
                      {/if}
                    </div>
                  {/each}
                </div>
              </div>
      <!--  iteration 1 -->
            {/if}
          {/each}
<!--first column-->

<!--second column-->
          <div class="uk-width-3-5">
          {#each navField as items, i}
            {#if i !== 0}
      <!-- iteration 2-->
              <div class="uk-margin-bottom">
                <div class="uk-flex uk-flex-left uk-margin-small-bottom">
                  <img src="{items.icon}" alt="icon">
                  <h3>{items.title}</h3>
                </div>

                <div class="uk-flex uk-flex-around">
                  {#each items.items as item}
                  <div on:click="options.root.navigate(item.link)" class="routes-link uk-flex uk-flex-column uk-flex-between">
                    <img src="{item.icon}" alt="points">
                    <div class="uk-text-center">{item.name}</div>
                  </div>
                  {/each}
                </div>
              </div>
      <!-- iteration 2-->
            {/if}
          {/each}
          </div>
<!--second column-->

        </div>
      </div>
{/if}
<!--Side-->


  </div>
</div>


<style>.wrapper {
  font-size: 14px; }

.nav__text {
  font-size: 12px; }

h2 {
  font-size: 18px;
  font-weight: bold; }

h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 0 5px; }

.in-dev {
  text-align: center;
  position: absolute;
  left: -50%;
  top: 25%;
  width: 140px;
  background-color: #e44d56;
  color: #ffffff;
  transform: rotate(30deg);
  font-size: 12px;
  overflow: hidden;
  line-height: 1.6; }

.rel-case {
  position: relative;
  overflow: hidden; }

.routes-link img {
  transform: scale(1);
  transition: 0.2s ease-in; }

.routes-link:hover {
  cursor: pointer; }
  .routes-link:hover img {
    transform: scale(1.1);
    transition: 0.2s ease-in; }
</style>

<script>
    import router from 'modules/router.js';

export default {
  oncreate(){
    const self = this;

    window.nav.set({
        breadcrumb: [{
          name: 'Главная'
        }]
      });

      $(this.refs.slider).on('itemshown', (event) => this.set({marker: event.target.dataset.key}))

      let intro = localStorage.getItem('dashboard_intro');
      if(!intro) setTimeout(() => {
        self.showIntro();
      },1000);
  },
  methods: {
    showIntro() {
      const { session } = this.options.root.get();
      if(session){
        let intro = introJs();
        intro.setOption("skipLabel", "Пропустить обучение");
        intro.setOption("nextLabel", "Дальше");
        intro.setOption("prevLabel", "Назад");
        intro.setOption("doneLabel", "Завершить");

        intro.oncomplete(function(){
          router.goTo('map');
        });

        intro.start();

        localStorage.setItem('dashboard_intro', 'true');
      }
    }
  },
  data() {
    return {
      marker: 0,
      slider:[{
        img:'svg/dashboard/carousel/pic1.svg',
        content:'1 Для добавления точек маршрута воспользуйтесь ручным вводом адреса или загрузкой файла с адресами.Маркеры адресов с номерами будут показаны на карте.Передвигайте маркер на карте, чтобы изменить адрес.На данном шаге вы можете заполнить ограничение по времени поездки,а также пробегу.'
      },{
        img:'svg/dashboard/carousel/pic2.svg',
        content:'2 Для добавления точек маршрута воспользуйтесь ручным вводом адреса или загрузкой файла с адресами.Маркеры адресов с номерами будут показаны на карте.Передвигайте маркер на карте, чтобы изменить адрес.На данном шаге вы можете заполнить ограничение по времени поездки,а также пробегу.'
      },{
        img:'svg/dashboard/carousel/pic3.svg',
        content:'3 Для добавления точек маршрута воспользуйтесь ручным вводом адреса или загрузкой файла с адресами.Маркеры адресов с номерами будут показаны на карте.Передвигайте маркер на карте, чтобы изменить адрес.На данном шаге вы можете заполнить ограничение по времени поездки,а также пробегу.'
      },{
        img:'svg/dashboard/carousel/pic4.svg',
        content:'4 Для добавления точек маршрута воспользуйтесь ручным вводом адреса или загрузкой файла с адресами.Маркеры адресов с номерами будут показаны на карте.Передвигайте маркер на карте, чтобы изменить адрес.На данном шаге вы можете заполнить ограничение по времени поездки,а также пробегу.'
      },{
        img:'svg/dashboard/carousel/pic5.svg',
        content:'5 Для добавления точек маршрута воспользуйтесь ручным вводом адреса или загрузкой файла с адресами.Маркеры адресов с номерами будут показаны на карте.Передвигайте маркер на карте, чтобы изменить адрес.На данном шаге вы можете заполнить ограничение по времени поездки,а также пробегу.'
      }],
      navField:[{
        title:"Справочники",
        icon:"svg/dashboard/handbook/icon.svg"  ,
        items:[
          {name: "Сотрудники",
            link: "/users",
            icon:"svg/dashboard/handbook/employees.svg",
            dev: false,
          },
          {name: "Продукты",
            link:"./",
            icon:"svg/dashboard/handbook/products.svg",
            dev: true,
          },
          {name: "Машины",
            link:"./",
            icon:"svg/dashboard/handbook/cars.svg",
            dev: true,
          }]
        },
        { title: "Настройки",
          icon:"svg/dashboard/settings/icon.svg",
          items:[
            { name: "Группы и права",
              link:"/settings/groups",
              icon:"svg/dashboard/settings/rights.svg",
              dev: false,
            },
            { name: "Мой профиль",
              link: "/profile",
              icon:"svg/dashboard/settings/profile.svg",
              dev: false,
            },
          ]
        },
        { title:"Карта",
          icon:"svg/dashboard/map/icon.svg",
          items:[
            { name: "Точки",
              link:"/map",
              icon:"svg/dashboard/map/points.svg",
              dev: false,
            },
            { name: "Маршруты",
              link: "/map#routes",
              icon:"svg/dashboard/map/routs.svg",
              dev: false,
            },
          ]
        },
      ]
    }
  }
}
</script>